import React from "react";

import "./PrivacyPolicy.scss";


const PrivacyPolicy = () => {
  return (
    <div className="condition-general politique-conf">
      <h1 className="title"> Politques de Confidentialité</h1>
      <p>
                La présente Politique de confidentialité décrit la façon dont vos
                informations personnelles sont recueillies, utilisées et partagées lorsque
                vous vous rendez sur market.feexpay.me (le « Site ») ou que vous y effectuez un achat.
      </p>
      <div className="block-section">
        <h1> INFORMATIONS PERSONNELLES RECUEILLIES </h1>
        <p>
                    Lorsque vous vous rendez sur le Site, nous recueillons automatiquement certaines informations concernant votre appareil, notamment des informations sur votre navigateur web, votre adresse IP, votre fuseau horaire et certains des cookies qui sont installés sur votre appareil. En outre, lorsque vous parcourez le Site, nous recueillons des informations sur les pages web ou produits individuels que vous consultez, les sites web ou les termes de recherche qui vous ont permis d'arriver sur le Site, ainsi que des informations sur la manière dont vous interagissez avec le Site. Nous désignons ces informations
                    collectées automatiquement sous l'appellation « Informations sur l'appareil ».
        </p>
        <p>
                    Nous recueillons les Informations sur l'appareil à l'aide des technologies suivantes :
          <h6>FICHIERS TÉMOINS (COOKIES)</h6><br />
                    Voici une liste de fichiers témoins que nous utilisons. Nous les avons énumérés ici pour que vous ayez
                    la possibilité de choisir si vous souhaitez les autoriser ou non.<br />
                    - Les « pixels invisibles », les « balises » et les « pixels » sont des fichiers électroniques qui
                    enregistrent des informations sur la façon dont vous parcourez le Site.<br />
                    - Les « pixels invisibles », les « balises » et les « pixels » sont des fichiers électroniques qui enregistrent des
                    informations sur la façon dont vous parcourez le Site.
        </p>
        <p>
                    Par ailleurs, lorsque vous effectuez ou tentez d'effectuer un achat par le biais du Site, nous recueillons certaines informations vous concernant, notamment votre nom, votre adresse de facturation, votre adresse d'expédition, vos informations de paiement (y compris vos numéros de cartes de crédit, votre adresse e-mail et votre numéro de téléphone.  Ces informations collectées
                    automatiquement sont désignées par l’appellation « Informations sur la commande ».
        </p>
        <p>
                    Lorsque nous utilisons l'expression « Informations personnelles » dans la présente Politique de confidentialité, nous faisons allusion à la
                    fois aux Informations sur l'appareil et aux Informations sur la commande.
        </p>
      </div>
      <div className="block-section">
        <h1> COMMENT UTILISONS-NOUS VOS INFORMATIONS PERSONNELLES  </h1>
        <p>
                    En règle générale, nous utilisons les Informations sur la commande que nous recueillons pour traiter toute commande passée par le
                    biais du Site (y compris pour traiter vos informations de paiement, organiser
                    l'expédition de votre commande et vous fournir des factures et/ou des confirmations de
                    commande).  En outre, nous utilisons ces Informations sur la commande pour :<br /><br />
                    •	communiquer avec vous ; <br />
                    •	évaluer les fraudes ou risques potentiels ; et<br />
                    •	lorsque cela correspond aux préférences que vous nous avez communiquées, vous fournir des informations ou des publicités concernant nos produits ou services.
        </p>
        <p>
                    Nous utilisons les Informations sur l'appareil (en particulier votre adresse IP) que nous recueillons pour
                    évaluer les fraudes ou risques potentiels et, de manière plus générale, pour améliorer et
                    optimiser notre Site (par exemple, en générant des analyses sur la façon dont nos clients
                    parcourent et interagissent avec le Site, et pour évaluer la réussite de nos campagnes de publicité et de marketing).
        </p>
      </div>
      <div className="block-section">
        <h1> PARTAGE DE VOS INFORMATIONS PERSONNELLES </h1>
        <p>
                    Il se peut que nous partagions aussi vos Informations personnelles pour respecter les lois et
                    règlementations applicables, répondre à une assignation, à un mandat de perquisition ou à
                    toute autre demande légale de renseignements que nous recevons, ou pour protéger nos droits.<br />
        </p>
      </div>
      <div className="block-section">
        <h1>NE PAS SUIVRE</h1>
        <p>
                    Veuillez noter que nous ne modifions pas la collecte de données de notre Site et nos pratiques d'utilisation lorsque nous
                    détectons un signal « Ne pas suivre » sur votre navigateur.
        </p>
      </div>
      <div className="block-section">
        <h1> SECURITE </h1>
        <p>
                    Nous veillons à ce que tout soit mis en œuvre pour vous assurer
                    de la réception des articles achetés ou du remboursement le cas échéant.
        </p>
      </div>
      <div className="block-section">
        <h1> RÉTENTION DES DONNÉES </h1>
        <p>
                    Lorsque vous passez une commande par l'intermédiaire du Site, nous conservons les Informations sur votre commande
                    dans nos dossiers, sauf si et jusqu'à ce que vous nous demandiez de les supprimer.<br />
                    Le Site n'est pas destiné aux individus de moins de 18 ans.
        </p>
      </div>
      <div className="block-section">
        <h1>CHANGEMENTS</h1>
        <p>
                    Nous pouvons être amenés à modifier la présente politique de confidentialité
                    de temps à autre afin d'y refléter, par exemple, les changements apportés à
                    nos pratiques ou pour d'autres motifs opérationnels, juridiques ou
                    réglementaires.
        </p>
      </div>
      <div className="block-section">
        <h1> NOUS CONTACTER </h1>
        <p>
                    Pour en savoir plus sur nos pratiques de confidentialité, si vous avez des 
                    questions ou si vous souhaitez déposer une réclamation, veuillez nous 
                    contacter par e-mail à <b>market@feexpay.me</b>, ou par courrier à l'adresse 
                    suivante :<br/><br/>
          <b>Cotonou, Littoral, Bénin</b>
        </p>
      </div>
    </div>
  );
};
export default PrivacyPolicy;