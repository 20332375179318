import React, { useContext, useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";

import "./Subscribe.scss";

import mtn from "../../../../assets/images/png/mtnPay.png";
import moov from "../../../../assets/images/png/moovPay.png";
import visa from "../../../../assets/images/png/visaPay.png";
import mastercard from "../../../../assets/images/png/masterPay.png";
import { FormPayCart2 } from "../../../../components/formPayCart/formPayCart2";
import GeneralContext from "../../../../context/GlobalContext";
import { HeadTabs } from "../../../../components/headTabs/HeadTabs";
import { PopupLayout } from "../../../../components/popupLayout/PopupLayout";
import { BoxAlertSucess } from "../../../../components/boxAlertSucess/BoxAlertSucess";

const Subscribe = () => {
  const { saveSubscribe, store } = useContext(GeneralContext);

  const [subscribe, setSubscribe] = useState(0);
  const [indexCard, setIndexCard] = useState(0);
  const [popup, isPopup] = useState(false);
  const [popup2, isPopup2] = useState(false);

  const [typeNetwork, setTypeNetwork] = useState();
  const [tel, setTel] = useState();

  // data carte bancaire send api pay
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [countr, setCountr] = useState("");
  const [department, setDepartments] = useState("");
  const [adresses, setAdresses] = useState("");
  const [telephone, setTelephone] = useState("");
  const [title, setTitle] = useState("");
  const [locality, setLocality] = useState("");
  const [typeCard, setTypeCard] = useState("");

  const [storeId, setStoreId] = useState(null);
  const [nameStore, setNameStore] = useState(null);
  const [descStore, setDescStore] = useState(null);

  const [typeSubscribe, setTypeSubscribe] = useState(null);
  const [subscription, setSubcription] = useState();

  const [priceSubscribe, setPriceSubscribe] = useState();

  const setIndex = (index) => {
    setIndexCard(index);
  };

  /* define type network pay */
  const setIndexNetwork = (index, typeNetwork, typeSub) => {
    setIndexCard(index);
    setTypeNetwork(typeNetwork);
    setTypeCard(null);
  };

  /* define type card pay */
  const setIndexTypeCard = (index, card, typeSub) => {
    setIndexCard(index);
    setTypeCard(card);
    setTypeNetwork(null);
  };

  /* choice subscription */
  const choiceSubscribe = (index, price, type) => {
    console.log(storeId + " " + type);
    saveSubscribe(storeId, type, isPopup2);
    /*     setSubscribe(index);
        setTypeSubscribe(type);
        setPriceSubscribe(price);
        isPopup(true); */
  };

  /* return info store active */
  const returnStoreActive = () => {
    if (store.length > 0) {
      for (var i = 0; i < store.length; i++) {
        if (store[i].is_active == true) {
          setNameStore(store[i].name_store);
          setDescStore(store[i].desc_store);
          setStoreId(store[i].store_id);
          setSubcription(store[i].subscription);
        }
      }
    }
  };

  /* return nb product publish */
  const returnNbProductSubscribe = () => {
    if (subscription) {
      if (subscription == "basic") {
        return 20;
      } else if (subscription == "average") {
        return 40;
      } else if (subscription == "advance") {
        return "+40";
      }
    }
  };

  /* handle pay subscribe */
  const handleSubscribe = () => {
    saveSubscribe(storeId, typeSubscribe, isPopup2);
  };

  const returnCard = () => {
    if (indexCard == 0) {
      return (
        <div className='card-pay-subscribe'>
          <span className='close' onClick={() => isPopup(false)}>&times;</span>
          <h3>Souscrivez-vous maintenant à notre abonnement</h3>
          <div className='method-pay'>
            <p> Choisissez un  moyen de paiement </p>
            <div className='choice-mp-div'>
              <div>
                <input type="radio" name='method-pay' onClick={() => setIndexNetwork(1, "Mtn Mobile Money")} />
                <img src={mtn} />
                <label> Mtn Mobile Money</label>
              </div>
              <div>
                <input type="radio" name='method-pay' onClick={() => setIndexNetwork(1, "Moov Money")} />
                <img src={moov} />
                <label> Moov Money</label>
              </div>
              <div>
                <input type="radio" name='method-pay' onClick={() => setIndexTypeCard(2, "Visa")} />
                <img src={visa} />
                <label> Visa</label>
              </div>
              <div>
                <input type="radio" name='method-pay' onClick={() => setIndexTypeCard(2, "MasterCard")} />
                <img src={mastercard} />
                <label> MasterCard</label>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (indexCard == 1) {
      return (
        <form className="form-pay-cart form-add form-pay-net">
          <span className="close" onClick={() => setIndexCard(0)}>&times;</span>
          <h3> Payer par <b> {typeNetwork} </b>  </h3>
          <div className="div-input">
            <label> Numéro de téléphone </label>
            <div className="phones">
              {/* <p> {setDialCode()} </p> */}
              <input type="text" name=""
                placeholder="+229......"
                value={tel}
                onChange={(e) => setTel(e.target.value)} />
            </div>
            <span id="tel-error"></span>
          </div>
          <button className="btn-send" type="submit"> Envoyé </button>
        </form>
      );
    } else if (indexCard == 2) {
      return <FormPayCart2 firstname={firstname}
        lastname={lastname}
        countr={countr}
        department={department}
        adress={adresses}
        tel={telephone}
        title={title}
        locality={locality}
        setFirstname={setFirstname}
        setLastname={setLastname}
        setCountr={setCountr}
        setDepartments={setDepartments}
        setAdresse={setAdresses}
        setTelephone={setTelephone}
        setLocality={setLocality}
        setTitle={setTitle}
        typeCard={typeCard}
        setClose={setIndexCard}
      />;
    }
  };

  useEffect(() => {
    returnStoreActive();
  }, [store]);

  return (
    <div className="subscribe-page">
      <HeadTabs title={"Nos Abonnements"} />
      <BoxAlertSucess popup={popup2}
        text={`Vous êtes abonner avec succès!! Vous êtes de publier
      jusqu'a 40 produit au choix sur votre site ${returnNbProductSubscribe()}`}
        isDone={isPopup2} handleBtn={isPopup2}
        link={nameStore ? `/Tableau-de-bord/${nameStore.replaceAll(" ", "")}/produits` : "/"} />

      <PopupLayout closes={popup}>
        {returnCard()}
      </PopupLayout>
      <div className="block-subscribe parent">
        <p>
          Nos abonnements vous permettent de publier sur notre marketplace
          vos produits en vue de vous rendre visible.
          <br />
          Pour vous abonner, sélectionnez un niveau d'abonnement et ensuite
          rendez-vous dans la rubrique <b>Produits</b> pour publier les produits de votre choix.
        </p>
        <div className="subscribe">
          <p> Faite votre choix !</p>
          <div className="card-group">
            <div className={`card-subscribe ${subscribe == 0 ? "subscribe-select" : ""}`}
              onClick={() => choiceSubscribe(0, 10000, "basic")}>
              <FaCheckCircle />
              <h4> Basique </h4>
              <h3> 10 Produits <span> à publier</span> </h3>
              <p>
                payer <b> 10000 FCFA </b>
              </p>
            </div>
            <div className={`card-subscribe ${subscribe == 1 ? "subscribe-select" : ""}`}
              onClick={() => choiceSubscribe(1, 10000, "average")}>
              <FaCheckCircle />
              <h4> Moyen </h4>
              <h3> 20 Produits <span>à publier</span> </h3>
              <p>
                payer <b> 20000 FCFA </b>
              </p>
            </div>
            <div className={`card-subscribe ${subscribe == 2 ? "subscribe-select" : ""}`}
              onClick={() => choiceSubscribe(2, 10000, "advance")} >
              <FaCheckCircle />
              <h4> Avancé </h4>
              <h3>+20 Produits <span>à publier</span> </h3>
              <p>
                payer <b> 10000 FCFA </b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
