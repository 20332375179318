import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import "../Header.scss";

export const NavBarShop = () => {
  const categories = useSelector((state) => state.products.categorie);

  const [storeId, setStoreId] = useState();
  const [nameStore, setNameStore] = useState();

  const setInfosStore = () => {
    let path = window.location.pathname.split("/");
    let id = path[2];
    let name = path[1];

    if (id) {
      setStoreId(id);
      setNameStore(name);
    }
  };

  useEffect(() => {
    setInfosStore();
  }, []);
  
  return (
    <>
      <div className="nav-bar-shop">
        <div className="common-div">
          <li>
            <a href={`/${nameStore}/${storeId}/produits`}>Nos Produits</a>
          </li>
          <li>
            <a href={`/${nameStore}/${storeId}/produits/nos-offres`}>Nos offres</a>
          </li>
          <li>
            <a href={`/${nameStore}/${storeId}/contact`}>Contact</a>
          </li>
        </div>
      </div>
    </>
  );
};
