import React, { useContext, useEffect, useState } from "react";
import { FcShop } from "react-icons/fc";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { FiChevronRight, FiStar } from "react-icons/fi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaStar, FaUserCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import ReactImageMagnify from "react-image-magnify";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import "./ProductDetails.scss";
import "flag-icon-css/css/flag-icons.min.css";

import CardProduct2 from "../../../components/cardProduct2/CardProduct2";
import { PopupLayout } from "../../../components/popupLayout/PopupLayout";
import { API_URL, CART_SHOPPING, PRODUCT } from "../../../settings/constant";
import { MoyenPay } from "../../../components/moyenPay/MoyenPay";
import GeneralContext from "../../../context/GlobalContext";
import ChatBot from "../../../components/chatBot/ChatBot";
import ChatBotWhatsapp from "../../../components/chatBotWhatsapp/ChatBotWhatsapp";
import { BoxAlertSucess } from "../../../components/boxAlertSucess/BoxAlertSucess";

const ProductDetails = (props) => {
  const locations = useLocation();

  const { allProducts } = useContext(GeneralContext);

  const data = locations.state;
  const colors = data ? (data.colors.trim()) : [];
  const sizes = data ? (data.sizes.trim()) : [];
  const reviews = data ? data.Reviews : [];
  const [quantity, setQuantity] = useState(1);

  const [done, isDone] = useState(false);
  const [msgText, setMsgText] = useState(null);
  const [popup, isPopup] = useState(false);
  const [popup2, isPopup2] = useState(false);
  const [chat, isChat] = useState(false);
  const [chatWhatsapp, isChatWhatsapp] = useState(false);

  const [siz, setSize] = useState([]);
  const [colr, setColor] = useState([]);
  const [colorChoose, setColorChoose] = useState();
  const [sizeChoose, setSizeChoose] = useState();
  const [sendMsg, isSendMsg] = useState(false);
  const [nbEtoile, setNbEtoile] = useState();

  const sizess = [];
  const colorss = [];

  const { get_code_country, save_comment, getCountSold,
    countSoldProd, createCartUser } = useContext(GeneralContext);

  const [comment, setComment] = useState("");

  const settings3 = {
    dots: false,
    infinite: true,
    autoplaySpeed: 3000,
    slidesToScroll: 1,
    slidesToShow: 5,
    autoplay: true,
    arrows: false,
  };


  const incrementQty = () => {
    setQuantity(quantity + 1);
  };

  const decrementQty = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  /* action pour des produits au panier */
  const addToCart = () => {
    let size_err = document.querySelector(".alert-size");
    let color_err = document.querySelector(".alert-color");
    let amount_prod = data.reduces == 0 ? data.price_prod : data.price_prod - Math.round((data.price_prod * data.reduces) / 100);
    if (colr.length > 0) {
      if (siz.length > 0) {
        if (colorChoose) {
          if (sizeChoose) {
            console.log(quantity);
            createCartUser(data.id_prod, colorChoose, sizeChoose, quantity, isPopup, amount_prod);
          } else {
            size_err.innerText = "Sélectionner une taille";
          }
        } else {
          color_err.innerText = "Sélectionner une couleur";
        }
      } else {
        if (colorChoose) {
          createCartUser(data.id_prod, colorChoose, sizeChoose, quantity, isPopup, amount_prod);
        } else {
          color_err.innerText = "Sélectionner une couleur";
        }
      }
    } else {
      if (siz.length > 0) {
        if (sizeChoose) {
          createCartUser(data.id_prod, colorChoose, sizeChoose, quantity, isPopup, amount_prod);
        } else {
          size_err.innerText = "Sélectionner une taille";
        }
      } else {
        createCartUser(data.id_prod, colorChoose, sizeChoose, quantity, isPopup, amount_prod);
      }
    }

  };

  /* select color */
  const handleSelectColor = (col) => {
    const allColor = document.querySelectorAll(".col .child");
    const alldiv = document.querySelectorAll(".col");

    if (colr.length > 1) {
      const color = colr.filter(
        (item) => item === col
      );
      for (let i = 0; i < alldiv.length; i++) {
        alldiv[i].classList.remove("select-color");
        if (allColor[i].innerText == color) {
          alldiv[i].classList.add("select-color");
        }
      }
      setColorChoose(color[0]);
    }
  };

  /* select size */
  const handleSelectSize = (s) => {
    const allSize = document.querySelectorAll(".size");

    if (siz.length > 1) {
      const size = siz.filter(
        (item) => item === s
      );

      for (let i = 0; i < allSize.length; i++) {
        allSize[i].classList.remove("select-size");
        if (allSize[i].innerText == size) {
          allSize[i].classList.add("select-size");
        }
      }
      setSizeChoose(size[0]);
    }
  };

  /* convert string in array */
  const convertStringToArraySize = (type, array, setHook, typ, setValChoose) => {
    if (data) {
      if (type) {
        const splits = type.split(" ");

        if (splits.length > 1) {
          for (var i = 0; i < splits.length; i++) {
            array.push(splits[i]);
          }
          setHook(array);
        } else if (splits.length == 1 && splits[0] !== null) {
          sessionStorage.setItem(typ, type);
          setValChoose(type.replace(" ", ""));
        }
      }
    }
  };

  /* return link store site */
  const returnLink = () => {
    if (data) {
      let origin = window.location.origin;
      let store = data.Store.name_store;
      return `${origin}/${store.replaceAll(" ", "")}/${data.Store.store_id}/produits`;
    }
  };

  /* return percent rating by review */
  const returnPercentRating = (nbr) => {
    let nb = 0;
    if (reviews.length > 0) {
      for (var i = 0; i < reviews.length; i++) {
        if (reviews[i].ratings == nbr) {
          nb += 1;
        }
      }
      return `${Math.round((nb / reviews.length) * 100)}%`;
    }
  };

  /* caluc rating total */
  const calculRatingProduct = (rat) => {
    let nb = 0;
    if (reviews.length > 0) {
      for (let i = 0; i < reviews.length; i++) {
        if (reviews[i].ratings == rat) {
          nb += 1;
        }
      }
      return (nb * rat) / reviews.length;
    }
  };

  const returnRatingsProductGlobal = () => {
    let rating = calculRatingProduct(1) +
      calculRatingProduct(2) +
      calculRatingProduct(3) +
      calculRatingProduct(4) +
      calculRatingProduct(5);
    return rating ? rating : 0;
  };


  useEffect(() => {
    /* covert string in array size and color */
    convertStringToArraySize(sizes, sizess, setSize, "size", setSizeChoose);
    convertStringToArraySize(colors, colorss, setColor, "color", setColorChoose);

    if (data) {
      localStorage.setItem("id_prod", data.id_prod);
    }
  }, [quantity]);

  return (
    <div className="product-detail-page">
      {data ? (
        <>
          <ChatBot isChat={isChat} chat={chat}
            name_store={data ? data.Store.name_store : ""}
            store_id={data ? data.Store.store_id : ""} msg={null}
            sendMsg={sendMsg} isSendMsg={isSendMsg} />
          <ChatBotWhatsapp chat={chatWhatsapp}
            isChat={isChatWhatsapp} name_store={data ? data.Store.name_store : ""}
            phone={data ? data.Store.user.phone : null}
          />
          <BoxAlertSucess popup={popup2} text=" Avis envoyé avec succès !!"
            handleBtn={isPopup2} isDone={isDone} done={done} link={"#"} />
          <PopupLayout closes={popup}>
            <div className="popup-card">
              <p>Produit ajouté au panier avec succès !!
                Ouvrez le panier ou continuer... </p>
              <div className="btns-action">
                <button onClick={() => {
                  window.location.href = CART_SHOPPING;
                  sessionStorage.removeItem("color");
                  sessionStorage.removeItem("size");
                }}> Ouvrir le panier</button>
                <button onClick={() => {
                  window.location.href = PRODUCT;
                  sessionStorage.removeItem("color");
                  sessionStorage.removeItem("size");
                }}> Continuer </button>
              </div>
            </div>
          </PopupLayout>
          <p className="head-prod-details">
            Produit <FiChevronRight />
            détails <FiChevronRight />
            {data && data.name_prod}
          </p>
          <p className="shop-identity">
            <FcShop />
            <i className={`flag-icon flag-icon-${get_code_country(data.Store.user.country)}`}></i>
            <b> {data.Store.user.country}, {data.Store.name_store} </b>
          </p>
          <div className="div-details-product">
            <div className="products-details">
              <div className="img">
                <ReactImageMagnify {...{
                  smallImage: {
                    alt: "",
                    isFluidWidth: true,
                    src: `${API_URL}${data.image_prod}`
                  },
                  largeImage: {
                    src: `${API_URL}${data.image_prod}`,
                    width: 1200,
                    height: 500
                  }
                }} />
                {/*     <img src={`${API_URL}${data.image_prod}`} /> */}
              </div>
              <div className="product-info">
                <h2>
                  {data.name_prod}
                </h2>
                <p className="p-start">
                  <span className='rating-star'>
                    {new Array(5).fill().map((_, index) => {
                      if (index < returnRatingsProductGlobal()) {
                        return <span key={index}><FaStar />  </span>;
                      } else {
                        return <span key={index}><FiStar />  </span>;
                      }
                    })}
                    <span>{data ? returnRatingsProductGlobal() : 0}</span>
                  </span>
                  {/*                   <span className='sold'>{data ? countSoldProd : 0} Vendu</span>
 */}                </p>
                <p> {reviews.length > 0 ? reviews.length : 0} Avis Clients </p>
                <p className="price">
                  {data.reduces === 0 ? (<b>{data.price_prod} <sup>CFA</sup> </b>) : (
                    <>
                      <b> {Math.round(Math.round(data.price_prod) - (Math.round(data.price_prod) * Math.round(data.reduces) / 100))} <sup>CFA</sup> </b>
                      <span style={{ textDecoration: "line-through" }}> {Math.round(data.price_prod)}XOF </span>
                    </>
                  )}
                </p>
                {
                  colr.length != 0 && colr !== null ? (
                    <div className="color">
                      color:
                      <div className="colors">
                        {
                          colr.map((list, index) => {
                            return <div className="col"
                              style={{ background: `${list}` }} key={index}
                              onClick={() => handleSelectColor(list)}
                              value={list}> <span className="child">{list}</span> </div>;
                          })
                        }
                        <span className='alert-color'></span>
                      </div>
                    </div>
                  ) : null
                }
                {
                  siz.length != 0 && siz !== null ? (
                    <div className="sizes-div">
                      Taille:
                      <div className="sizes">
                        {
                          siz.map((list, index) => {
                            if (list) {
                              return <div key={index}
                                className="size"
                                onClick={() => handleSelectSize(list)}>
                                {list}
                              </div>;
                            }
                          })
                        }
                      </div>
                      <span className='alert-size'></span>
                    </div>
                  ) : null
                }
                <p className="quantity">
                  Quantité:
                  <p>
                    <button onClick={decrementQty}> <AiOutlineMinus /> </button>
                    <input type="text" value={quantity} />
                    <button onClick={incrementQty}> <AiOutlinePlus /> </button>
                  </p>
                </p>
                <p className='desc'>
                  <h6>Description : </h6>
                  <span> {data.desc_prod} </span>
                </p>
                <div className="btn-action">
                  <button onClick={addToCart}> Ajouter au panier </button>
                </div>
              </div>
            </div>
            <div className="div-right-detail">
              <p>
                Pour plus d'information sur le produit,
                ainsi que sur la livraison,
              </p>
              <button onClick={() => isChatWhatsapp(true)}> Contacter le vendeur </button>
              <div className="shop-visit">
                <b>
                  <i className={`flag-icon flag-icon-${get_code_country(data.Store.user.country)}`}></i>
                  {data.Store.user.country},{data.Store.name_store}
                </b>
                <p>
                  {data.Store.desc_store}
                </p>
                <li className='btn-visit-store'>
                  <a href={returnLink()} target='_blank' rel="noreferrer">
                    Visiter la boutique
                  </a>
                </li>
              </div>
              <div className="mode-paie">
                <p> Payez par: </p>
                <MoyenPay />
              </div>
            </div>
          </div>
          <div className="reviews-block">
            <div className="reviews">
              <div className='avis-bloc-details'>
                <h3> ({reviews.length != 0 ? reviews.length : 0}) Avis Clients</h3>
                <div className='rating-nb'>
                  <p>
                    {new Array(5).fill().map((_, index) => {
                      return <span className={"activeStar"} key={index}><FaStar /></span>;
                    })}
                  </p>
                  <h6>
                    {returnPercentRating(5)}
                  </h6>
                </div>
                <div className='rating-nb'>
                  <p>
                    {new Array(5).fill().map((_, index) => {
                      if (index < 4) {
                        return <span className={"activeStar"} key={index}>
                          <FaStar />
                        </span>;
                      } else {
                        return <span key={index}>
                          <FiStar />
                        </span>;
                      }
                    })}
                  </p>
                  <h6>{returnPercentRating(4)}</h6>
                </div>
                <div className='rating-nb'>
                  <p>
                    {new Array(5).fill().map((_, index) => {
                      if (index < 3) {
                        return <span className={"activeStar"} key={index}>
                          <FaStar />
                        </span>;
                      } else {
                        return <span key={index}>
                          <FiStar />
                        </span>;
                      }
                    })}
                  </p>
                  <h6>{returnPercentRating(3)}</h6>
                </div>
                <div className='rating-nb'>
                  <p>
                    {new Array(5).fill().map((_, index) => {
                      if (index < 2) {
                        return <span className={"activeStar"} key={index}>
                          <FaStar />
                        </span>;
                      } else {
                        return <span key={index}>
                          <FiStar />
                        </span>;
                      }
                    })}
                  </p>
                  <h6>{returnPercentRating(2)}</h6>
                </div>
                <div className='rating-nb'>
                  <p>
                    {new Array(5).fill().map((_, index) => {
                      if (index < 1) {
                        return <span className={"activeStar"} key={index}>
                          <FaStar />
                        </span>;
                      } else {
                        return <span key={index}>
                          <FiStar />
                        </span>;
                      }
                    })}
                  </p>
                  <h6>{returnPercentRating(1)}</h6>
                </div>
              </div>
              <div className="groups-reviews">
                {reviews.length != 0 ? (
                  reviews.map((list, index) => {
                    return <div className="box-comment" key={index}>
                      <div className="head" key={index}>
                        <FaUserCircle />
                        <div>
                          <p>
                            {new Array(5).fill().map((_, index) => {
                              if (index < list.ratings) {
                                return <span key={index}><FaStar />  </span>;
                              } else {
                                return <span key={index}><FiStar />  </span>;
                              }
                            })}
                          </p>
                          <h4> {list.user.firstname} {list.user.lastname} </h4>
                          <span> {list.createdAt.split("T")[0] + " " + list.createdAt.split("T")[1].split(".")[0]} </span>
                        </div>
                      </div>
                      <p className="msg"> {list.comments} </p>
                    </div>;
                  })
                ) : null
                }

              </div>
              <form className="form-reviews">
                <h3>Donner votre avis</h3>
                <p id="msg-form" ></p>
                <div className='rating-div-block'>
                  <h3>Noter ce product</h3>
                  <div className='div-rating'>
                    {new Array(5).fill().map((_, index) => {
                      return <span className={`svg ${index < nbEtoile ? "activeStar" : null}`} key={index}>
                        <FaStar />
                      </span>;
                    })}
                    <input type="number" placeholder='' max={5}
                      value={nbEtoile}
                      onChange={e => setNbEtoile(e.target.value)} />
                  </div>
                </div>
                <textarea cols={6} rows={6}
                  placeholder="Votre avis en quelques mots..."
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                ></textarea>
                <li onClick={() => save_comment(comment, nbEtoile, data.id_prod, "msg-form", isPopup2, isDone, setMsgText)}> Envoyer </li>
              </form>
            </div>
          </div>
          {/* <div className="div-other-prod">
            <h2> Autre produits</h2>
            <div className="sliderr">
              <Slider {...settings3} >
                {
                  allProducts && (
                    allProducts.map((list, index) => {
                      return <Link key={index} to={{ pathname: `/produit/details/${list.name_prod}` }} state={list}>
                        <CardProduct2 img={`${API_URL}${list.image_prod}`} names={list.name_prod}
                          reduce={list.reduces} price={list.price_prod} />
                      </Link>;
                    })
                  )
                }
              </Slider>
            </div>
          </div> */}
        </>
      ) : <h1> Vérifier votre connexion</h1>}
    </div>
  );
};

export default ProductDetails;
