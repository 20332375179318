import React from "react";
import "./globals/globals.scss";
import "./globals/variables.scss";
import ROUTES from "./router";
import {AuthProvider} from "./context/AuthContext";
import {GlobalContext} from "./context/GlobalContext";

function App() {
  return (
    <div className="parent-body">
      <AuthProvider>
        <GlobalContext>
          <ROUTES />
        </GlobalContext>
      </AuthProvider>
    </div>
  );
}

export default App;
