import React from "react";
import { Link } from "react-router-dom";

import "./CardProduct2.scss";


const CardProduct2 = ({img, names, price, reduce, list}) => {
  return (
    <div className="prod-cards">
      {reduce !== 0 ? <div className="bande"> {reduce}% </div>:null}
      <div className="imgs">
        <img src={img} />
        <Link className='btn-link-add' to={{ pathname: `/produit/details/${names.replaceAll(" ", "-")}` }} state={list}>
          Ajouter au panier
        </Link> 
      </div>
      <div className="inf">
        <h4> {names} </h4>
        <p>
          <b> XOF {reduce === 0 ? Math.round(price) : (Math.round(price) - ((Math.round(price) * reduce) / 100))} </b>
          {reduce !== 0 ? <span>XOF{price} </span> : null}
        </p>
      </div>
    </div>
  );
};

export default CardProduct2;
