import React from "react";
import { FaPaperPlane } from "react-icons/fa";
import "../Footer.scss";

export const Newsletter = () => {
  return (
    <>
      <div className="newsletter">
        <p>
          Inscrivez-vous à la newsletter
        </p>
        <form>
          <input type="email" placeholder="Votre e-mail" />
          <button> <FaPaperPlane /> </button>
        </form>
      </div>
    </>
  );
};
