import React, { useEffect, useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

import "./Pagination.scss";

const Pagination = ({ nextPage, prevPage, setNextPage,
  setPrevPage, countProd, nbProd }) => {

  const [nbPages, setNbPages] = useState(0);
  const [nb, setNb] = useState(1);

  const nextPages = () => {
    if (nextPage < countProd) {
      setNb(nb + 1);
      setNextPage(nextPage + nbProd);
      setPrevPage(prevPage + nbProd);
    }
  };

  const prevPages = () => {
    if (prevPage > 0) {
      setNb(nb - 1);
      setPrevPage(prevPage - nbProd);
      setNextPage(nextPage - nbProd);
    }
  };

  const deactivate = () => {
    let prev = document.querySelector(".prev-arrow"),
      next = document.querySelector(".next-arrow");

    if (nb == 1) {
      prev.style.background = "var(--whitesmoke-500)";
    } else {
      prev.style.background = "var(--dark)";
    }
    if (nb == nbPages) {
      next.style.background = "var(--whitesmoke-500)";
    } else {
      next.style.background = "var(--dark)";
    }
  };

  const defPagesNb = () => {
    if (nbPages > 1) {
      return `${nb} sur ${nbPages} Pages`;
    }
  };

  const returnBtn = () => {
    if (nbPages > 0 && nbPages == 1) {
      return null;
    } else if (nbPages > 1) {
      return (
        <>
          <button> {nb} </button>
          ...
          <button> {nbPages} </button>
        </>
      );
    }
  };

  useEffect(() => {
    setNbPages(() => {
      let nb = countProd / nbProd;
      let str = nb.toString();
      let splits = str.split(".");

      if (splits[1]) {
        return parseInt(splits[0]) + 1;
      } else {
        return Math.round(nb);
      }
    });
    /* activate or deactivate button next or prev */
    deactivate();
  });

  return (
    <div className="pagination">
      
      <>
        <div className="btn-group">
          <button className='prev-arrow' onClick={prevPages}> <FiChevronLeft /> </button>
          {returnBtn()}
          <button className='next-arrow' onClick={nextPages}> <FiChevronRight /> </button>
        </div>
        <div className="page">
          <span>{defPagesNb()} </span>
        </div>
      </>
      
    </div>
  );
};

export default Pagination;
