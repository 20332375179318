import React from "react";
import { Link } from "react-router-dom";
import { API_URL2 } from "../../settings/constant";

import "./CardProdShop.scss";

export const CardProdShop = ({ listProductPopular }) => {
  return (
    <>
      <div className="cards-store">
        {listProductPopular && (
          listProductPopular.map((list, index) => {
            if (index < 3) {
              return (
                <Link to={{ pathname: `/produit/details/${list.name_prod.replaceAll(" ", "-")}` }} state={list} key={index}>
                  <div className="card-prod-store" key={index}>
                    <div className="div-imgs">
                      <img src={`${API_URL2}${list.image_prod}`} />
                    </div>
                    <h4> {list.name_prod} </h4>
                    <b> {Math.round(list.price_prod)}&nbsp; CFA </b>
                  </div>
                </Link>
              );
            }
          })
        )}
      </div>
    </>
  );
};
