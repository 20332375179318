import React from "react";
import { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { API_URL, HOME, LOGIN } from "../settings/constant";
import axios from "axios";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {

  const [user, setUser] = useState(() =>
    localStorage.getItem("accessToken") ?
      jwt_decode(localStorage.getItem("accessToken")).user :
      null,
  );

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const [loading, setLoading] = useState(true);

  /* login */
  const loginUser = async (email, password, link, isDone) => {
    try {
      await axios.post(`${API_URL}account/signin`, {
        email: email,
        password: password,
      }, config)
        .then((response) => {
          if (response.status === 204) {
            isDone(true);
          } else if (response.status === 200) {
            const data = response.data;
            const token = jwt_decode(data.accessToken);
            setUser(token.user);
            localStorage.setItem("refeshToken", JSON.stringify(data.refresToken));
            localStorage.setItem("accessToken", JSON.stringify(response.data.accessToken));       
            window.location.href = HOME;
          }
        });
    } catch (error) {
      return error;
    }
  };

  /* create store */
  const createStore = async (storeId, descStore, nameStore, user_id) => {
    try {
      const response = await axios.post(`${API_URL}create/store`, {
        store_id: storeId,
        desc_store: descStore,
        name_store: nameStore,
        user: user_id,
      }, config);
      if (response.status === 201) {
        window.location.href = HOME;
      } else {
        alert("Cette boutique existe déjà !!");
      }
    } catch (error) {
      return error;
    }
  };

  /* update store */
  const updateStoreInfo = async (storeId, nameStore, descStore, msg_res, isDone) => {
    try {
      const response = await axios.put(`${API_URL}store/update/${storeId}`, {
        name_store: nameStore,
        desc_store: descStore,
      }, config);
      if (response.status === 200) {
        isDone(true);
        msg_res.innerText = "Profile mise à jour avec succès !";
      }
    } catch (error) {
      return error;
    }
  };

  /* register user */
  const registerUser = async (firstname, lastname, country, phone,
    email, password, link, isLose, role, storeId, 
    descStore, nameStore) => {
    try {
      const response = await axios.post(`${API_URL}account/signup`, {
        firstname: firstname,
        lastname: lastname,
        country: country,
        phone: phone,
        email: email,
        password: password,
        role: role
      }, config);

      if (response.status === 200) {
        isLose(true);
      } else if (response.status === 201) {
        const token = jwt_decode(response.data.accessToken);
        setUser(token.user);
        localStorage.setItem("refreshToken", JSON.stringify(response.data.refreshToken));
        localStorage.setItem("accessToken", JSON.stringify(response.data.accessToken));
        
        if (role === "seller") {
          createStore(storeId, descStore, nameStore, token.user.id);
        } else {
          window.location.href = HOME;
        }
      }
    } catch (error) {
      return error;
    }
  };

  /* verify account user */
  const verifyAccount = async (storeId, nameStore, descStore, email, setStep) => {
    try {
      const response = await axios.get(`${API_URL}account/verify/${email}`, config);
      if (response.status === 200) {
        console.log(response);
        createStore(storeId, descStore, nameStore, response.data.id);
      } else {
        setStep(2);
      }
    } catch (error) {
      return error;
    }
  };

  const logoutUser = () => {
    setUser(null);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    window.location.href = HOME;
  };

  const logoutUserStore = () => {
    setUser(null);
    localStorage.removeItem("userToken2");
    window.location.href = HOME;
  };

  /* reset status store logout user */
  const logoutStore = async () => {
    if (user) {
      try {
        const response = await axios.put(`${API_URL}store/disconnet/${user.userId}`, config);
        if (response.status == 200) {
          console.log(response);
          window.location.href = "/";
          logoutUserStore();
        }
      } catch (error) {
        return error;
      }
    }
  };

  const updateUserAccount = async (formData, storeId, nameStore, descStore, msg_res, isDone) => {
    try {
      const response = await axios.put(`${API_URL}account/update-user/${user.id}`, formData);
      if (response.status === 200) {
        updateStoreInfo(storeId, nameStore, descStore, msg_res, isDone);
      }
    } catch (error) {
      return error;
    }
  };

  const contextData = {
    user,
    setUser,
    registerUser,
    loginUser,
    logoutUser,
    logoutStore,
    updateUserAccount,
    verifyAccount
  };

  useEffect(() => {
    let tok = localStorage.getItem("accessToken");
    if (tok) {
      setUser(jwt_decode(tok).user);
    }
    setLoading(false);
  }, [loading]);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
