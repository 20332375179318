import React, { useEffect, useState } from "react";
import { validInputText } from "../controlFields/ControlField";

import "../formPayCart/formPayCart.scss";

export const FormPayNetwork = ({ mp, countr, tel, isPopup3, typeNetwork,
  setCountr, setTelephone }) => {

  const [phone, setPhone] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    validInputText(phone, "tel-error", "tel");

    if (validInputText(phone, "tel-error", "tel")) {
      setTelephone(phone);
      isPopup3(false);
    }
  };

  useEffect(() => {
    const country = localStorage.getItem("country");
    if (country) {
      setCountr(countr);
    }
  }, [mp]);

  return (
    <form className="form-pay-cart form-add form-pay-net">
      <span className="close" onClick={() => isPopup3(false)}>&times;</span>
      <h3> Payer par <b> {mp} </b>  </h3>
      <div className="div-input">
        <label> Numéro de téléphone </label>
        <div className="phones">
          {/* <p> {setDialCode()} </p> */}
          <input type="text" name=""
            placeholder="229......"
            value={phone}
            onChange={(e) => setPhone(e.target.value)} />
        </div>
        <span id="tel-error"></span>
      </div>
      <button className="btn-send" type="submit" onClick={handleSubmit} > Enrégistré </button>
    </form>
  );
};
