import React, { useContext, useEffect, useState } from "react";

import "flag-icon-css/css/flag-icons.min.css";
import "./WhishList.scss";

import GeneralContext from "../../../context/GlobalContext";
import { API_URL, API_URL2 } from "../../../settings/constant";
import { Link } from "react-router-dom";

const WhishList = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const { whishlist, get_code_country } = useContext(GeneralContext);

  const active = {
    color: "var(--orange)",
    borderBottom: "1px solid var(--orange)",
  };


  useEffect(() => {
  }, []);

  const returnTabs = () => {
    if (tabIndex === 0) {
      if (whishlist.length > 0) {
        return (
          <div className="favoris-product">
            {whishlist.map((list, index) => {
              return <Link to={`/product/details/${list.Product.name_prod.replaceAll(" ","-")}`} state={list.Product} key={index}>
                <div className="card-favoris" key={index}>
                  <div className="img">
                    <img src={`${API_URL}${list.Product.image_prod}`} />
                  </div>
                  <div className="infos-div">
                    <h3> {list.Product.name_prod} </h3>
                    <p className="shop-infos">
                      <i className={`flag-icon flag-icon-${get_code_country(list.user.country)}`}></i>
                      &nbsp;{list.user.country} {list.Product.Store.country}, {list.Product.Store.name_store}
                    </p>
                  </div>
                </div>;
              </Link>;
            })
            }
          </div>
        );
      }
    }
  };

  return (
    <div className="favoris-dashboard-buyer">
      <div className="favoris-blocks parent">
        <div className="favoris-tabs">
          <div className="btn-tabs-switch">
            <button style={tabIndex === 0 ? active : null}
              onClick={() => setTabIndex(0)}> Products ({whishlist.length > 0 ? whishlist.length : 0} )</button>
          </div>
          <p> Vos produits favoris nous aide à vous présenter les types de produits que vous aimez le plus </p>
          {returnTabs()}
        </div>
      </div>
    </div>
  );
};

export default WhishList;
