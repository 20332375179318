import React from "react";
import Header from "../Header";

import "../Header.scss";

const HeaderCartCheckout = () => {
  return (
    <>
      <Header children1={null}
        children2={null}
        children3={null}
        children4={null}
      />
    </>
  );
};

export default HeaderCartCheckout;
