import React, { useContext, useState } from "react";
import AuthContext from "../../context/AuthContext";
import GeneralContext from "../../context/GlobalContext";

import "./ChatBot.scss";


const ChatBot = ({ name_store, store_id, msg, chat, isChat }) => {
  const [message, setMessage] = useState();
  const [done, isDone] = useState(false);

  const { create_messages } = useContext(GeneralContext);

  const handleChange = (e) => {
    setMessage(e.target.value);
    isDone(false);
  };

  const send = async (e) => {
    e.preventDefault();
    const msg = document.getElementById("error-msg");

    if (message) {
      create_messages(message, store_id, "msgs", isDone, setMessage,"message");
    } else {
      msg.innerText = "Entré le message...";
    }
  };


  return (
    <div className="block-field-rep"
      style={chat ? { display: "block" } : { display: "none" }}>
      <span className="close"
        onClick={() => isChat(false)}> &times;</span>
      <h4>
        Envoyé à {name_store}
      </h4>
      <div className="field">
        {done ? <p className="msg" id="msgs"> Message non envoyé. verifier votre connexion </p> : null}
        {msg ? (
          <div className="msg-request">
            <b>Message:</b>
            <p> {msg} </p>
          </div>
        ) : null}
        <form>
          <textarea cols={6} rows={5}
            placeholder="Entré votre réponse"
            value={message}
            onChange={handleChange}></textarea>
          <span id="error-msg"></span>
          <button onClick={send}>Envoyé</button>
        </form>
      </div>
    </div>
  );
};

export default ChatBot;
