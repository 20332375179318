import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { FaTrashRestore } from "react-icons/fa";
import { FiTrash2 } from "react-icons/fi";
import { HeadTabs } from "../../../../components/headTabs/HeadTabs";
import Pagination from "../../../../components/pagination2/Pagination";
import SearchBar from "../../../../components/searchBar2/SearchBar";
import GeneralContext from "../../../../context/GlobalContext";
import { API_URL, API_URL2, config } from "../../../../settings/constant";

import "./Sale.scss";

const Trash = () => {
  const [searchWord, setSearchWord] = useState("");
  const [startNbPage, setStartNbPage] = useState(5);
  const [nextPage, setNextPage] = useState(startNbPage);
  const [previouspage, setPreviousPage] = useState(0);
  const [resultSearch, setResultSearch] = useState([]);
  const [storeActive, setStoreActive] = useState([]);

  const { saleTrash, store, getSaleTrashStore } = useContext(GeneralContext);


  const search = async () => {
    if (searchWord) {
      const tab = saleTrash.filter(
        (item) => searchWord == item.idSale || searchWord == item.buyerName ||
          searchWord == item.statusPay || searchWord == item.statusDelivr,
      );

      if (tab.length != 0) {
        console.log(tab);
        setResultSearch(tab);
      } else {
        setResultSearch([]);
      }
    }
  };

  //restore sale
  const RestoreSale = async (index) => {
    try {
      const response = await axios.put(`${API_URL}store/sale/restore/${index}`, config);
      console.log(response);
      if (response.status == 200) {
        window.location.reload();
      } 
    } catch (error) {
      return error;
    }
  };


  //delete definitively sale
  const deleteSale = async (index) => {
    try {
      const response = await axios.delete(`${API_URL}store/sale/delete-trash/${index}`, config);
      console.log(response);
      if (response.status == 200) {
        window.location.reload();
      } 
    } catch (error) {
      return error;
    }
  };

  const returnStore = () => {
    if (store.length > 0) {
      for (var i = 0; i < store.length; i++) {
        if (store[i].is_active == true) {
          getSaleTrashStore(store[i].store_id);
          setStoreActive(store[i]);
          return 0;
        }
      }
    }
  };

  useEffect(() => {
    returnStore();
    search();
  }, [store, searchWord]);

  return (
    <div className="sale-dashboard-buyer">
      <HeadTabs title={`Cobeille (${saleTrash.length > 0 ? saleTrash.length : 0})`} />
      <div className="sale parent">
        <p className="msg-text">
          <span></span>
        </p>
        <div className="child">
          <SearchBar placeholderText="Rechercher par idVente, acheteur et les status"
            searchWord={searchWord} allList={saleTrash.length > 0 ? saleTrash : null}
            setList={setResultSearch}
            setWord={setSearchWord} />
          <table className="table-data">
            <thead>
              <tr>
                <th> N° </th>
                <th> Nom Acheteur </th>
                <th> Nom Produits </th>
                <th> Photo product </th>
                <th> Quantité </th>
                <th> Prix Total (XOF) </th>
                <th> Status payement</th>
                <th> Status livraison </th>
                <th> Adresse de livraison </th>
                <th> Actions </th>
              </tr>
            </thead>
            <tbody>
              {
                resultSearch.length == 0 ? (
                  saleTrash.length > 0 && (
                    saleTrash.map((list, index) => {
                      if (previouspage <= index && (nextPage - 1) >= index) {
                        return <tr key={index}>
                          <td> {index + 1} </td>
                          <td> {`${list.user.firstname} ${list.user.lastname}`} </td>
                          <td> {list.products.name_prod} </td>
                          <td> <img src={`${API_URL2}${list.products.image_prod}`} /> </td>
                          <td> {list.qty} </td>
                          <td> {list.amount} </td>
                          <td> {list.is_paid ? "Payé" : "Non Payé"} </td>
                          <td> {list.is_delivered ? "Délivré" : "En attente"} </td>
                          <td>
                            {list.shipping_address.firstname}
                            &nbsp;{list.shipping_address.lastname} <br />
                            {list.shipping_address.telephone}<br />
                            {list.shipping_address.country},
                            &nbsp;{list.shipping_address.department},
                            &nbsp;{list.shipping_address.city}
                          </td>
                          <td>
                            <button onClick={() => RestoreSale(list.id)}> <FaTrashRestore /> Restauré </button>
                            <button onClick={() => deleteSale(list.id)}> <FiTrash2 /> Supprimer </button>
                          </td>
                        </tr>;
                      }
                    })
                  )
                ) : (
                  resultSearch.map((list, index) => {
                    return <tr key={index}>
                      <td> {list.idSale} </td>
                      <td> {list.buyerName} </td>
                      <td> {list.products} </td>
                      <td> {list.price} </td>
                      <td> {list.statusPay} </td>
                      <td> {list.statusDelivr} </td>
                      <td>
                        <button> <FiTrash2 /> </button>
                      </td>
                    </tr>;
                  })
                )

              }
            </tbody>
          </table>
          {resultSearch.length == 0 ? (
            <Pagination nb={startNbPage}
              countTab={saleTrash.length > 0 ? saleTrash.length : 0}
              setNextt={setNextPage}
              setPrevious={setPreviousPage}
              nextt={nextPage}
              previous={previouspage} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Trash;
