import React, { useContext, useEffect, useState } from "react";
import { FiArrowRight } from "react-icons/fi";
import jwt_decode from "jwt-decode";

import "./start.scss";
import SelectCountryField from "../../../components/selectCountryField/SelectCountryField";
import { validInputText } from "../../../components/controlFields/ControlField";
import AuthContext from "../../../context/AuthContext";
import { countries } from "../../../components/countrylist/countrylist";
import { ShowPasswdText } from "../../../components/showPasswdText/ShowPasswdText";
import GeneralContext from "../../../context/GlobalContext";

const Start = () => {
  const { registerUser, verifyAccount } = useContext(AuthContext);
  const { location } = useContext(GeneralContext);

  const [user, setUser] = useState([]);
  const [firstname, setFirstname] = useState("");
  const [phone, setPhone] = useState("");
  const [lastname, setLastname] = useState("");
  const [password, setPassword] = useState("");
  const [passwordC, setPasswordC] = useState("");
  const [descStore, setDescStore] = useState("");
  const [countrys, setCountry] = useState(location.country_name);

  const [lose, isLose] = useState(false);

  const [step, setStep] = useState(0);

  const [dialCode, setDialCode] = useState();


  const start = () => {
    setStep(1);
  };

  /* get user token */
  const getUserInfo = async () => {
    const path = window.location.pathname;

    if (path !== null) {
      const tokens = path.split("/")[2];
      if (tokens) {
        setUser(jwt_decode(tokens));
      }
    }
  };

  /* create a account user */
  const createAccount = async (e) => {
    e.preventDefault();

    const err1 = document.getElementById("country-error");
    const err2 = document.getElementById("pwdC-error");

    validInputText(password, "pwd-error", "pwd");
    validInputText(phone, "phone-error", "tel");

    if (
      validInputText(password, "pwd-error", "pwd") 
    ) {
      if (countrys) {
        if (passwordC === password) {
          registerUser(
            firstname,
            lastname,
            countrys,
            dialCode + phone,
            user.email,
            password,
            "/",
            isLose,
            "seller",
            user.shop_id,
            descStore,
            user.shop_name,
          );
        } else {
          err2.innerText = "Les mot de passe ne sont pas identique...";
        }
      } else {
        err1.innerText = "Sélectionner votre pays actuel";
      }
    }
  };

  /* verify account users */
  const verifyUserAccount = () => {
    verifyAccount(user.shop_id, user.shop_name, descStore, user.email, setStep);
  };

  /* return  step create  store */
  const returnSteps = () => {
    if (step === 0) {
      return (
        <div className="btn-div">
          <button onClick={start}>Commencer</button>
        </div>
      );
    } else if (step === 1) {
      return (
        <div className="step1 s1">
          <p>
            <label>Nom de la boutique</label>
            <input type="text" placeholder=""
              value={user.shop_name} />
          </p>
          <p>
            <label>Description</label>
            <textarea cols={8} rows={6}
              placeholder="Décrivez en quelques lignes l'activité de votre boutique..."
              value={descStore}
              onChange={(e) => setDescStore(e.target.value)}>
            </textarea>
          </p>
          <button onClick={verifyUserAccount}> Continuer <FiArrowRight /> </button>
        </div >
      );
    } else if (step === 2) {
      return (
        <div className="step1 s3">
          <p className="first-p">
            Créer un compte vous permettra de constituer votre profil utilisateur pour votre boutique.
          </p>
          <h3>Créer un compte FeexMarket</h3>

          <form>
            {lose ? <p className="msg-text">Cet email est déjà utilisé </p> : null}
            <p>
              <span className="span-err" id="email-error"></span>
              <input type="text"
                placeholder=""
                value={user.email} />
            </p>
            <div className='div-select-country'>
              <span className="span-err" id="country-error"></span>
              <SelectCountryField country_name={countrys} setCountry={setCountry} />
            </div>
            <p className="pho">
              <span className="span-err" id="phone-error"></span>
              <span className='dial-code'> {dialCode} </span>
              <input type="text"
                placeholder="Numéro de téléphone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)} />
            </p>
            <p>
              <span className="span-err" id="pwd-error"></span>
              <input type="password"
                placeholder="Mot de passe"
                id='pass'
                value={password}
                onChange={(e) => setPassword(e.target.value)} />
              <ShowPasswdText id={"pass"} />
            </p>
            <p>
              <span className="span-err" id="pwdC-error"></span>
              <input type="password"
                placeholder="Retaper le mot de passe"
                value={passwordC}
                onChange={(e) => setPasswordC(e.target.value)} />
            </p>
            <button onClick={createAccount}> Continuer <FiArrowRight /> </button>
          </form>
        </div>
      );
    }
  };

  useEffect(() => {
    getUserInfo();
    if (countrys) {
      const find = countries.find(
        (item) => item.name === countrys
      );
      if (find) {
        setDialCode(find.dial_code);
      }
    }
  }, [dialCode, countrys, location,]);

  return (
    <div className="start-home-store">
      {returnSteps()}
    </div >
  );
};

export default Start;
