import React, { useContext, useEffect, useState } from "react";
import { BsEnvelope } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";
import { FiPhone } from "react-icons/fi";

import ChatBot from "../../../components/chatBot/ChatBot";
import GeneralContext from "../../../context/GlobalContext";
import { API_URL } from "../../../settings/constant";

import "./ContactStore.scss";


const ContactStore = () => {
  const [chat, isChat] = useState(false);
  const { get_code_country, getStoreProfile, storeProfile,
    sendMailToStore } = useContext(GeneralContext);

  const [email, setEmail] = useState(null);
  const [firstname, setFirstname] = useState(null);
  const [message, setMessage] = useState(null);

  const handleSubmit = e => {
    e.preventDefault();
    sendMailToStore(email, firstname, message);
  };

  useEffect(() => {
    let path = window.location.pathname.split("/");
    let id = path[2];

    if (id) {
      getStoreProfile(id);
    }
  }, []);

  return (
    <div className="contact-store-page">
      <ChatBot chat={chat} isChat={isChat}
        store_id={storeProfile ? storeProfile.store_id : null}
        name_store={storeProfile ? storeProfile.name_store : null} />

      <h1 className="title-page"> Profile </h1>
      <div className="div-profile">
        {
          storeProfile ? (storeProfile.user.image_profil ? (
            <img className="user-img" src={`${API_URL}${storeProfile ? storeProfile.user.image_profil : null}`} />
          ) : <FaUserCircle className="user-svg" />) : null
        }

        <div className="infos">
          <h1> {storeProfile ? storeProfile.name_store.toUpperCase() : null} </h1>
          <p> <FiPhone /> {storeProfile ? storeProfile.user.phone : null}  </p>
          <p> <BsEnvelope />{storeProfile ? storeProfile.user.email : null}  </p>
          <p>
            <i className={`flag-icon flag-icon-${get_code_country(storeProfile ? storeProfile.user.country : null)}`}></i>
            {storeProfile ? storeProfile.user.country : null}, {storeProfile ? storeProfile.user.city : null}
          </p>
          <p>
            <button>
              <a href={`https://api.whatsapp.com/send/?phone=${storeProfile?storeProfile.user.phone.replace("+",""):null}&text=&type=phone_number&app_absent=0`} target="_blank" rel="noreferrer">
                  Nous Contacter par whatsapp
              </a>
            </button>
          </p>
        </div>
      </div>
      {/* <div className="form-send-msg" id="form">
                <h1>Nous Contacter</h1>
                <form>
                    <p>
                        <input type="text" name="email" 
                        placeholder="Votre email"
                        value={email}
                        onChange={e => setEmail(e.target.value)} />
                        <span id="email-error"></span>
                    </p>
                    <p>
                        <input type="text" name="name" 
                        placeholder="Votre Nom et Prénom"
                        value={firstname}
                        onChange={e => setFirstname(e.target.value)} />
                        <span id="name-error"></span>
                    </p>
                    <p>
                        <textarea cols={8} rows={10} 
                        placeholder="Entrez votre message"
                        value={message}
                        onChange={e => setMessage(e.target.value)} />
                        <span id="msg-error"></span>
                    </p>
                    <button onClick={handleSubmit}> Envoyer </button>
                </form>
            </div> */}
    </div>
  );
};

export default ContactStore;