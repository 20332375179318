import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import CardProduct2 from "../../../components/cardProduct2/CardProduct2";
import Pagination from "../../../components/pagination/Pagination";
import GeneralContext from "../../../context/GlobalContext";
import { API_URL } from "../../../settings/constant";

import "./ShopPromo.scss";

const ShopPromo = () => {
  const { getProductPromoByStore, productPromoByStore } = useContext(GeneralContext);

  const setInfosStore = () => {
    let path = window.location.pathname.split("/");
    let id = path[2];

    if (id) {
      getProductPromoByStore(id);
    }
  };


  useEffect(() => {
    setInfosStore();
  }, []);

  return (
    <div className="shop-promo">
      {
        productPromoByStore .length > 0 ? (
          <>
            <h2 className="title-section">
              Product en Promotion
            </h2>
            <div className="groups-prod-promo">
              {
                productPromoByStore  && (
                  productPromoByStore .map((list, index) => {
                    return <Link key={index} to={{ pathname: `/produit/details/${list.name_prod}` }} state={list}>
                      <CardProduct2 img={`${API_URL}${list.image_prod}`} names={list.name_prod}
                        reduce={list.reduces} price={list.price_prod} />
                    </Link>;
                  })
                )
              }
            </div>
            <Pagination />
          </>
        ) : (
          <h2> Aucun offre disponible </h2>
        )
      }
    </div>
  );
};

export default ShopPromo;
