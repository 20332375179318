import React, { useContext, useEffect, useState } from "react";

import "./CartPageLink.scss";
import GeneralContext from "../../../../context/GlobalContext";
import { API_URL, API_URL2 } from "../../../../settings/constant";

const CartPageLink = () => {

  const { store, getCartStoreItem, cartItemStore } = useContext(GeneralContext);

  const [qty, setQty] = useState(1);
  const [amount, setAmout] = useState(0);

  const getCartItem = () => {
    let token = window.location.search.substring(1);
    if (token) {
      getCartStoreItem(token);
    }
  };

  const returnAmount = () => {
    let amount = 0;
    if (cartItemStore.length > 0) {
      cartItemStore.forEach(element => {
        amount += element.amount;
      });
    }
    return amount;
  };

  useEffect(() => {
    getCartItem();
    console.log(cartItemStore);
  }, []);

  return (
    <div className="cart-page-link">
      <div className="head-divs">
        <h1> Panier </h1>
        <div className="price">
                    Prix total :
          <b> {returnAmount()} Fcfa</b>
        </div>
      </div>
      <div className="cart-table">
        {
          cartItemStore.length != 0 ? (
            <>
              <table className="table-data2">
                <thead>
                  <tr>
                    <th> Nom produit </th>
                    <th> Image </th>
                    <th> Prix Unité </th>
                    <th> Quantité </th>
                    <th> Réduction </th>
                    <th> Prix Total </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    cartItemStore.map((list, index) => {
                      return <tr key={index}>
                        <td> {list.Product.name_prod} </td>
                        <td> <img src={`${API_URL}${list.Product.image_prod}`} /> </td>
                        <td> {list.Product.price_prod} </td>
                        <td> {list.qty} </td>
                        <td> {list.Product.reduces} </td>
                        <td id="amount"> {list.amount} </td>
                      </tr>;
                    })
                  }
                </tbody>
              </table>
            </>
          ) : null
        }
      </div>
      <button className="btn-pay"> Payer maintenant </button>
    </div>
  );
};

export default CartPageLink;