import React, { useContext, useEffect } from "react";
import GeneralContext from "../../../context/GlobalContext";
import { FaWarehouse, FaWhatsapp } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";


const BarInfosStore = () => {
  const { get_code_country, getStoreProfile,
    storeProfile, saveFollowers, statsStore,
    getStatsStore } = useContext(GeneralContext);

  // get store profile
  const getProfile = () => {
    let path = window.location.pathname.split("/");
    let id = path[2];
    getStoreProfile(id);
  };

  useEffect(() => {
    getProfile();
    getStatsStore();
    console.log(storeProfile);
  }, []);

  return (
    <div className="infos-store-div">
      <div className="infos-stores">
        <p>
          <FaWarehouse />
          {storeProfile ? <h3> {storeProfile.name_store} </h3> : null}
        </p>
        <div className="stats">
          <span>
            <FaWhatsapp />
            {storeProfile ? storeProfile.user.phone : null}
          </span>
          {/* <span>
            <span>{statsStore ? (statsStore.followers ? statsStore.followers.length:0 ):0}</span> Abonnés
          </span>
          <button onClick={() => saveFollowers(storeProfile ? storeProfile.store_id : 0)} >
            <FiPlus /> Suivre</button> */}
          {/*           <button> Contacter le fournisseur </button>
 */}        </div>
      </div>
    </div>
  );
};

export default BarInfosStore;