import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/authForms/login/Login2";
import ResetForm from "./components/authForms/resetPwd/ResetForm";
import LayoutAuth from "./layouts/layoutSite/LayoutAuth";
import LayoutCartCheckout from "./layouts/layoutSite/LayoutCartCheckout";
import LayoutHome from "./layouts/layoutSite/LayoutHome";
import LayoutShop from "./layouts/layoutSite/LayoutShop";
import HomeSeller from "./pages/dashboard/dashboardSeller/home/Home";
import LayoutSeller from "./pages/dashboard/dashboardSeller/LayoutSeller";
import PayementSettings from "./pages/dashboard/dashboardSeller/payement/PayementSettings";
import AddProduct from "./pages/dashboard/dashboardSeller/products/AddProduct";
import Cobeille from "./pages/dashboard/dashboardSeller/products/Cobeille";
import ListProduct from "./pages/dashboard/dashboardSeller/products/ListProducts";
import ProfilSeller from "./pages/dashboard/dashboardSeller/profil/ProfilSeller";
import Sale from "./pages/dashboard/dashboardSeller/sale/Sale";
import Trash from "./pages/dashboard/dashboardSeller/sale/Trash";
import Subscribe from "./pages/dashboard/dashboardSeller/subcript/Subscribe";
import CartShopping from "./pages/site/cart/CartShopping";
import Checkout from "./pages/site/checkout/Checkout";
import ConditionGeneral from "./pages/site/conditionGeneral/ConditionGeneral";
import ContactStore from "./pages/site/contactStore/ContactStore";
import Home from "./pages/site/home";
import Order from "./pages/site/order/Order";
import PoliticyRemb from "./pages/site/politicyRemb/PoliticyRemb";
import PrivacyPolicy from "./pages/site/privacyPolicy/PrivacyPolicy";
import Product from "./pages/site/product/Product";
import ProductDetails from "./pages/site/productDetails/ProductDetails";
import PromotionPage from "./pages/site/promotionPage/PromotionPage";
import SearchPage from "./pages/site/searchPage/SearchPage";
import Shop from "./pages/site/shop/Shop";
import ShopProduct from "./pages/site/shopCategorie/ShopCategorie";
import ShopPromo from "./pages/site/shopPromo/ShopPromo";
import ShopSearchPage from "./pages/site/shopSearchPage/ShopSearchPage";
import Start from "./pages/site/startHomeStore/start";
import WhishList from "./pages/site/whishlist/WhishList";
import PrivateRoute from "./PrivateRoute";

import {
  CART_LINK_PAGE,
  CART_SHOPPING,
  CHECKOUT,
  CONDITION_GENERAL,
  CONTACTS,
  DASHBOARD_SELLER_ADD_PRODUCTS,
  DASHBOARD_SELLER_COBEILLE_PRODUCT,
  DASHBOARD_SELLER_COBEILLE_VENTE,
  DASHBOARD_SELLER_HOME,
  DASHBOARD_SELLER_ORDER,
  DASHBOARD_SELLER_PAYEMENT,
  DASHBOARD_SELLER_PRODUCTS,
  DASHBOARD_SELLER_PROFIL,
  DASHBOARD_SELLER_SALLE,
  DASHBOARD_SELLER_SUBSCRIBE,
  HOME,
  LOGIN,
  ORDER,
  POLITIC_CONFIDENTIALITe,
  POLITIC_REMBOURSEMENT,
  PRODUCT,
  PRODUCT_DETAILS,
  PROMO_PAGE,
  RESETFORM,
  RESETPWD,
  SEARCH_PRODUCT_PAGE,
  SEARCH_PRODUCT_PAGE_STORE,
  SHOP_HOME,
  SHOP_PRODUCT,
  SHOP_PROMO,
  START,
  WHISHLIST,
} from "./settings/constant";
import CartPageLink from "./pages/dashboard/dashboardSeller/cartPageLink/CartPageLink";
import ResetPwd from "./components/authForms/resetPwd/ResetPwd";
import OrderSeller from "./pages/dashboard/dashboardSeller/order/Order";


const ROUTES = () => {
  return (
    <Router>
      <Routes>
        {/* start page create store */}
        <Route path={START} element={
          <LayoutAuth>
            <Suspense fallback="...">
              <Start />
            </Suspense>
          </LayoutAuth>
        } />

        {/* reset pwd page */}
        <Route path={RESETPWD} element={
          <LayoutAuth>
            <Suspense fallback="...">
              <ResetForm />
            </Suspense>
          </LayoutAuth>
        } />

        <Route path={RESETFORM} element={
          <LayoutAuth>
            <Suspense fallback="...">
              <ResetPwd />
            </Suspense>
          </LayoutAuth>
        } />

        <Route path={LOGIN} element={
          <LayoutAuth>
            <Suspense fallback="...">
              <Login />
            </Suspense>
          </LayoutAuth>
        } />

        {/* home page */}
        <Route path={HOME} element={
          <LayoutHome>
            <Suspense fallback="...">
              <Home />
            </Suspense>
          </LayoutHome>
        } />

        {/* product page */}
        <Route path={PRODUCT} element={
          <LayoutHome>
            <Suspense fallback="...">
              <Product />
            </Suspense>
          </LayoutHome>
        } />

        {/* product details */}
        <Route path={PRODUCT_DETAILS} element={
          <LayoutHome>
            <Suspense fallback="...">
              <ProductDetails />
            </Suspense>
          </LayoutHome>
        } />

        {/* search page */}
        <Route path={SEARCH_PRODUCT_PAGE} element={
          <LayoutHome>
            <Suspense fallback="...">
              <SearchPage />
            </Suspense>
          </LayoutHome>
        } />

        {/* cart shopping */}
        <Route path={CART_SHOPPING} element={
          <PrivateRoute>
            <LayoutCartCheckout>
              <Suspense fallback="...">
                <CartShopping />
              </Suspense>
            </LayoutCartCheckout>
          </PrivateRoute>
        } />

        {/* checkout */}
        <Route path={CHECKOUT} element={
          <PrivateRoute>
            <LayoutCartCheckout>
              <Suspense fallback="...">
                <Checkout />
              </Suspense>
            </LayoutCartCheckout>
          </PrivateRoute>
        } />

        {/* checkout */}
        <Route path={PROMO_PAGE} element={
          <PrivateRoute>
            <LayoutHome>
              <Suspense fallback="...">
                <PromotionPage />
              </Suspense>
            </LayoutHome>
          </PrivateRoute>
        } />

        {/* shop */}
        <Route path={SHOP_HOME} element={
          <LayoutShop>
            <Suspense fallback="...">
              <Shop />
            </Suspense>
          </LayoutShop>
        } />

        {/* shop product */}
        <Route path={SHOP_PRODUCT} element={
          <LayoutShop>
            <Suspense fallback="...">
              <ShopProduct />
            </Suspense>
          </LayoutShop>
        } />

        {/* shop product */}
        <Route path={WHISHLIST} element={
          <PrivateRoute>
            <LayoutHome>
              <Suspense fallback="...">
                <WhishList />
              </Suspense>
            </LayoutHome>
          </PrivateRoute>
        } />

        {/* shop product promo */}
        <Route path={SHOP_PROMO} element={
          <LayoutShop>
            <Suspense fallback="...">
              <ShopPromo />
            </Suspense>
          </LayoutShop>
        } />

        {/* shop product searcg page */}
        <Route path={SEARCH_PRODUCT_PAGE_STORE} element={
          <LayoutShop>
            <Suspense fallback="...">
              <ShopSearchPage />
            </Suspense>
          </LayoutShop>
        } />

        {/* shop contact */}
        <Route path={CONTACTS} element={
          <LayoutShop>
            <Suspense fallback="...">
              <ContactStore />
            </Suspense>
          </LayoutShop>
        } />

        {/* shop contact */}
        <Route path={CART_LINK_PAGE} element={
          <LayoutShop>
            <Suspense fallback="...">
              <CartPageLink />
            </Suspense>
          </LayoutShop>
        } />

        {/* condiction générale */}
        <Route path={CONDITION_GENERAL} element={
          <LayoutHome>
            <Suspense fallback="...">
              <ConditionGeneral />
            </Suspense>
          </LayoutHome>
        } />

        {/* condiction générale */}
        <Route path={CONDITION_GENERAL} element={
          <LayoutHome>
            <Suspense fallback="...">
              <ConditionGeneral />
            </Suspense>
          </LayoutHome>
        } />


        {/* privacy policy */}
        <Route path={POLITIC_CONFIDENTIALITe} element={
          <LayoutHome>
            <Suspense fallback="...">
              <PrivacyPolicy />
            </Suspense>
          </LayoutHome>
        } />


        {/* condiction générale */}
        <Route path={POLITIC_REMBOURSEMENT} element={
          <LayoutHome>
            <Suspense fallback="...">
              <PoliticyRemb />
            </Suspense>
          </LayoutHome>
        } />

        {/* Message */}
        {/*  <Route path={MESSAGE} element={
          <PrivateRoute>
            <ProtectedRoutes>
              <LayoutHome>
                <Suspense fallback="...">
                  <Message />
                </Suspense>
              </LayoutHome>
            </ProtectedRoutes>
          </PrivateRoute>
        } /> */}

        {/* Order */}
        <Route path={ORDER} element={
          <PrivateRoute>
            <LayoutHome>
              <Suspense fallback="...">
                <Order />
              </Suspense>
            </LayoutHome>
          </PrivateRoute>
        } />

        {/* Dashboard seller */}
        <Route path={DASHBOARD_SELLER_HOME} element={
          <PrivateRoute>
            <LayoutSeller>
              <Suspense fallback="...">
                <HomeSeller />
              </Suspense>
            </LayoutSeller>
          </PrivateRoute>
        } />

        <Route path={DASHBOARD_SELLER_PROFIL} element={
          <PrivateRoute>
            <LayoutSeller>
              <Suspense fallback="...">
                <ProfilSeller />
              </Suspense>
            </LayoutSeller>
          </PrivateRoute>
        } />

        {/*     <Route path={DASHBOARD_SELLER_MESSAGE} element={
          <PrivateRoute>
            <LayoutSeller>
              <Suspense fallback="...">
                <MessageSeller />
              </Suspense>
            </LayoutSeller>
          </PrivateRoute>
        } /> */}

        <Route path={DASHBOARD_SELLER_SALLE} element={
          <PrivateRoute>
            <LayoutSeller>
              <Suspense fallback="...">
                <Sale />
              </Suspense>
            </LayoutSeller>
          </PrivateRoute>
        } />

        {/* page commande */}
        <Route path={DASHBOARD_SELLER_ORDER} element={
          <PrivateRoute>
            <LayoutSeller>
              <Suspense fallback="...">
                <OrderSeller />
              </Suspense>
            </LayoutSeller>
          </PrivateRoute>
        } />

        <Route path={DASHBOARD_SELLER_PRODUCTS} element={
          <PrivateRoute>
            <LayoutSeller>
              <Suspense fallback="...">
                <ListProduct />
              </Suspense>
            </LayoutSeller>
          </PrivateRoute>
        } />

        <Route path={DASHBOARD_SELLER_ADD_PRODUCTS} element={
          <PrivateRoute>
            <LayoutSeller>
              <Suspense fallback="...">
                <AddProduct />
              </Suspense>
            </LayoutSeller>
          </PrivateRoute>
        } />

        <Route path={DASHBOARD_SELLER_COBEILLE_PRODUCT} element={
          <PrivateRoute>
            <LayoutSeller>
              <Suspense fallback="...">
                <Cobeille />
              </Suspense>
            </LayoutSeller>
          </PrivateRoute>
        } />

        <Route path={DASHBOARD_SELLER_COBEILLE_VENTE} element={
          <PrivateRoute>
            <LayoutSeller>
              <Suspense fallback="...">
                <Trash />
              </Suspense>
            </LayoutSeller>
          </PrivateRoute>
        } />

        <Route path={DASHBOARD_SELLER_PAYEMENT} element={
          <PrivateRoute>
            <LayoutSeller>
              <Suspense fallback="...">
                <PayementSettings />
              </Suspense>
            </LayoutSeller>
          </PrivateRoute>
        } />

        <Route path={DASHBOARD_SELLER_SUBSCRIBE} element={
          <PrivateRoute>
            <LayoutSeller>
              <Suspense fallback="...">
                <Subscribe />
              </Suspense>
            </LayoutSeller>
          </PrivateRoute>
        } />
      </Routes>
    </Router>
  );
};

export default ROUTES;
