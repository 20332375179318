import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./CardPromo.scss";
import { API_URL2 } from "../../settings/constant";

export const CardPromo = ({ listProduct, backColor }) => {
  const settings = {
    dots: false,
    infinite: true,
    autoplaySpeed: 5000,
    slidesToScroll: 1,
    slidesToShow: 1,
    autoplay: true,
    arrows: false,
  };

  const [product, setProduct] = useState([]);

  const filters = () => {
    if (listProduct.length > 0) {
      let find = listProduct.filter(
        (item) => item.reduces === 0
      );

      if (find) {
        console.log(find);
        setProduct(find);
      }
    }
  };

  useEffect(() => {
    filters();
  }, []);

  return (
    <>
      {
        product.length != 0 ? (
          <Slider className="slider" {...settings}>
            {product && (
              product.map((list, index) => {
                return (
                  <div
                    style={{ background: backColor }}
                    className="banners-prod" key={index}>
                    <h5 className="band-promo"> {list.reduces}% </h5>
                    <div className="prod">
                      <div className="head-prod">
                        <h4> {list.name_prod} </h4>
                        <img src={`${API_URL2}${list.image_prod}`} />
                      </div>
                      <button>Acheter maintenant</button>
                    </div>
                  </div>
                );
              })
            )}
          </Slider>
        ) : null
      }
    </>
  );
};
