import React, { useContext, useEffect, useState } from "react";
import { FiDownload } from "react-icons/fi";

import "./Profil.scss";

import SelectCountryField from "../../../../components/selectCountryField/SelectCountryField";
import AuthContext from "../../../../context/AuthContext";
import { validInputText } from "../../../../components/controlFields/ControlField";
import { HeadTabs } from "../../../../components/headTabs/HeadTabs";
import GeneralContext from "../../../../context/GlobalContext";
import { BoxAlertSucess } from "../../../../components/boxAlertSucess/BoxAlertSucess";
import { DASHBOARD_SELLER_PROFIL } from "../../../../settings/constant";

const ProfilSeller = () => {

  const { updateUserAccount } = useContext(AuthContext);
  const { userData, get_code_country, store } = useContext(GeneralContext);

  const [names, setNames] = useState(null);
  const [countrys, setCountrys] = useState(null);
  const [city, setCity] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [image, setImage] = useState(null);

  const [storeId, setStoreId] = useState(null);
  const [nameStore, setNameStore] = useState(null);
  const [descStore, setDescStore] = useState(null);
  const [done, isDone] = useState(false);

  const [img, setImg] = useState(null);

  /* mise à jour du profile utilisateur */
  const updateUserProfile = async (e) => {
    e.preventDefault();

    const img_error = document.getElementById("img-error");
    const msg_res = document.getElementById("msg-res");

    validInputText(names, "names-error", "text");
    validInputText(city, "city-error", "text");
    validInputText(phone, "phone-error", "tel2");
    validInputText(email, "email-error", "email");
    validInputText(nameStore, "store-error", "text");
    validInputText(descStore, "desc-error", "text");

    if (validInputText(names, "names-error", "text") &&
      validInputText(city, "city-error", "text") &&
      validInputText(phone, "phone-error", "tel2") &&
      validInputText(nameStore, "store-error", "text") &&
      validInputText(descStore, "desc-error", "text")) {

      img_error.innerText = "";

      let nam = names.trim();
      const formData = new FormData();
      formData.append("firstname", nam.split(" ")[0]);
      formData.append("lastname", nam.split(" ")[1]);
      formData.append("country", countrys);
      formData.append("city", city);
      formData.append("phone", phone);

      if (img !== null) {
        console.log(img);
        formData.append("image_profil", img);
      } else {
        formData.delete("image_profil");
      }
      updateUserAccount(formData, storeId, nameStore, descStore, msg_res, isDone);
    }
  };


  const returnStoreActive = () => {
    if (store.length > 0) {
      for (var i = 0; i < store.length; i++) {
        if (store[i].is_active == true) {
          setNameStore(store[i].name_store);
          setDescStore(store[i].desc_store);
          setStoreId(store[i].store_id);
        }
      }
    }
  };

  useEffect(() => {
    if (userData) {
      setNames(userData.firstname + " " + userData.lastname);
      setCountrys(userData.country);
      setCity(userData.city);
      setEmail(userData.email);
      setPhone(userData.phone);
      setImage(userData.image_profil);
    }
    returnStoreActive();
    console.log(userData);
  }, [store, userData, img]);

  //const renderer = ()

  return (
    <div className="profil-dashboard-buyer">
      <BoxAlertSucess popup={done}
        text="Profil mise à jour avec succès !!"
        handleBtn={isDone}
        isDone={isDone}
        link={DASHBOARD_SELLER_PROFIL}
      />
      <HeadTabs title={"Profil"} />
      <div className="block-form">
        <div className="block-div1">
          <div className="div1">
            <form>
              <h3 className="title-h3"> Information du Compte </h3>
              <p id="msg-res"></p>
              <div className="div-input">
                <p>
                  <label> Nom et Prénom* </label>
                  <input type="text"
                    name="fullname"
                    placeholder="..."
                    value={names}
                    onChange={(e) => setNames(e.target.value)} />
                </p>
                <span className="span-error" id="names-error"></span>
              </div>
              <div className="div-input">
                <p>
                  <label> Pays actuel*</label>
                  <SelectCountryField country_name={countrys} setCountry={setCountrys} />
                  {
                    countrys ? (
                      <span style={{ marginLeft: "1rem" }}>
                        <i className={`flag-icon flag-icon-${get_code_country(countrys)}`}></i>
                        <b> {countrys} </b>
                      </span>
                    ) : null
                  }
                </p>
                <span className="span-error" id="country-error"></span>
              </div>
              <div className="div-input">
                <p>
                  <label>Ville*</label>
                  <input type="text"
                    name="city"
                    placeholder="..."
                    value={city}
                    onChange={(e) => setCity(e.target.value)} />
                </p>
                <span className="span-error" id="city-error"></span>
              </div>
              <div className="div-input">
                <p>
                  <label> Photo de profile *</label>
                  <div className="file-img">
                    <FiDownload />
                    <span> {img !== null ? img.name : "Sélectionner un fichier"}  </span>
                    <input type="file" name="file"
                      onChange={e => setImg(e.target.files[0])} />
                  </div>
                </p>
                <span className="text-img-accept">
                  Seul les images au format jpg, jpeg, png sont accepté avec une
                  taille d'au moins de 500 x 600
                </span>
                <span className="span-error" id="img-error"></span>
              </div>

              <div className="div-input">
                <p>
                  <label> Téléphone* </label>
                  <input type="tel" name="phone"
                    placeholder="Votre numéro service client whatsapp..."
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)} />
                </p>
                <span className="span-error" id="phone-error"></span>
              </div>
              <div className="div-input">
                <p>
                  <label> email* </label>
                  <input type="text"
                    name="email"
                    placeholder="..."
                    value={email} />
                </p>
                <span className="span-error" id="email-error"></span>
              </div>
              {/* <div className="div-input">
                <p>
                  <label> Changer de Mot de passe </label>
                  <li className="btn-click" onClick={() => isCloses(true)}> Cliquer ici</li>
                  <PopupLayout closes={closes}>
                    <div className="change-pwd-divs">
                      <p>Vérifier votre boite email, puis cliquer sur le lien de réinitialisation de mot de passe</p>
                      <Countdown date={Date.now() + 60000} />
                    </div>
                  </PopupLayout>
                </p>
              </div> */}
              <h3 className="title-h3" style={{ marginTop: "1rem" }}> Information boutique </h3>
              <div className="div-input">
                <p>
                  <label> Nom boutique </label>
                  <input type="text"
                    name="email"
                    placeholder="..."
                    value={nameStore}
                    onChange={e => setNameStore(e.target.value)} />
                </p>
                <span className="span-error" id="store-error"></span>
              </div>
              <div className="div-input">
                <p>
                  <label> Description de la boutique </label>
                  <input type="text"
                    name="email"
                    placeholder="..."
                    value={descStore}
                    onChange={e => setDescStore(e.target.value)} />
                </p>
                <span className="span-error" id="desc-error"></span>
              </div>
              <button className="btn-update" onClick={updateUserProfile}> Mettre à jour </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilSeller;
