import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {API_URL, config} from "../../settings/constant";

export const getProduct = createAsyncThunk(
  "products", async () => {
    try {
      const response = await axios.get(`${API_URL}products/all`, config);

      return response.data;
    } catch (error) {
      return error;
    }
  },
);
