import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/AuthContext";
import GeneralContext from "../../context/GlobalContext";
import { validInputText } from "../controlFields/ControlField";
import { countries } from "../countrylist/countrylist";
import SelectCountryField from "../selectCountryField/SelectCountryField";

import "./FormAdresse.scss";

export const FormAdresse = ({ getData, isPopup, action, setAction, id, n, c, cy, dp, p, co }) => {

  const [fullname, setFullName] = useState("");
  const [countrys, setCountrys] = useState("");
  const [citys, setCity] = useState("");
  const [departments, setDepartment] = useState("");
  const [phone, setPhone] = useState("");

  const { user } = useContext(AuthContext);
  const { createShippingAdress, updateShippingAdress } = useContext(GeneralContext);

  const clear = () => {
    setFullName("");
    setCountrys("");
    setDepartment("");
    setPhone("");
    setCity("");
    setAction("ajout");
    const msg_text = document.querySelector(".msg-text");
    msg_text.innerText="";
  };

  const setDialCode = () => {
    if (countrys) {
      const find = countries.find(
        (item) => item.name == countrys,
      );

      return find.dial_code;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const msg_text = document.querySelector(".msg-text");
    msg_text.innerText="";
    const country_error = document.getElementById("c-error");

    validInputText(fullname,"n-error", "text");
    validInputText(citys,"city-error", "text");
    validInputText(departments,"dp-error", "text");
    validInputText(phone,"phone-error", "tel");

    if (validInputText(fullname, "n-error", "text") &&
      validInputText(citys, "city-error", "text") &&
      validInputText(departments, "dp-error", "text") &&
      validInputText(phone, "phone-error", "tel")) {

      console.log(countrys);
      if (countrys) {
        country_error.innerText = "";
        const code = setDialCode();
        const country = countrys;
        const department = departments;
        const city = citys;
        const telephone = code + phone;

        if (action == "ajout") {
          createShippingAdress(fullname,country, department, city, telephone, clear, msg_text);
        } else {
          updateShippingAdress(id, fullname,country, department, city, telephone, clear, msg_text);
        }
      } else {
        country_error.innerText = "Choisissez une pays...";
      }
    }
  };

  useEffect(() => {
    setFullName(n != null ? n : "");
    setCountrys(c != null ? c : "");
    setCity(cy != null ? cy : "");
    setDepartment(dp != null ? dp : "");
    setPhone(p != null ? p : "");

    if (p) {
      const find = countries.find(
        (item) => item.name == co,
      );

      const dial = find.dial_code;
      const phone = p.split(dial)[1] ? p.split(dial)[1] : p;
      console.log(phone);
      setPhone(phone);
    }
  }, [n, p, dp, c, cy]);

  return (
    <form className="form-checkout form-add">
      <p className="msg-text"></p>
      <span className="close-times"
        onClick={() => isPopup(false)}>&times;</span>
      <h3> Adresse de livraison </h3>
      <div className="div-input">
        <label>Nom complet (Ex: DOSSOU-YOVO Jean)</label>
        <input type="text" name=""
          placeholder="..."
          value={fullname}
          onChange={(e) => setFullName(e.target.value)} />
        <span id="n-error"></span>
      </div>
      <div className="div-inputs">
        <p>
          <label>Pays</label>
          <SelectCountryField codes={co} countrys={c} setCountry={setCountrys} />
          <span id="c-error"></span>
        </p>
        <p>
          <label> Département </label>
          <input type="text" name=""
            placeholder="Département"
            value={departments}
            onChange={(e) => setDepartment(e.target.value)} />
          <span id="dp-error"></span>
        </p>
        <p>
          <label> Ville </label>
          <input type="text" name=""
            placeholder="Ville"
            value={citys}
            onChange={(e) => setCity(e.target.value)} />
          <span id="city-error"></span>
        </p>
      </div>
      <div className="div-input">
        <label> Téléphone </label>
        <p className="phones">
          <p> {setDialCode()} </p>
          <input type="text" name=""
            placeholder="Votre téléphone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)} />
        </p>
        <span id="phone-error"></span>
      </div>
      <button className="btn-send" type="submit" onClick={handleSubmit}> Enrégistré </button>
    </form>
  );
};
