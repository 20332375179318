import React, { useEffect } from "react";
import {validInputText} from "../controlFields/ControlField";
import {countries} from "../countrylist/countrylist";
import SelectCountryField from "../selectCountryField/SelectCountryField";

import "./formPayCart.scss";

export const FormPayCart2 = ({firstname, lastname, countr, department, adress, tel,
  title, locality, setFirstname, setLastname, setCountr, setDepartments, setAdresse,
  setTelephone, setLocality, setTitle, typeCard, setClose}) => {

  const setDialCode = () => {
    if (countr) {
      const find = countries.find(
        (item) => item.name == countr,
      );
      return find.dial_code;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const country_error = document.getElementById("ct-error");

    validInputText(firstname, "firstname-error", "text");
    validInputText(lastname, "lastname-error", "text");
    validInputText(department, "dep-error", "text");
    validInputText(adress, "adresse-error", "adresse");
    validInputText(tel, "tel-error", "tel");
    validInputText(locality, "locality-error", "text");
    validInputText(title, "title-error", "text");

    if (
      validInputText(firstname, "firstname-error", "text") &&
            validInputText(lastname, "lastname-error", "text") &&
            validInputText(department, "dep-error", "text") &&
            validInputText(adress, "adresse-error", "adresse") &&
            validInputText(tel, "tel-error", "tel") &&
            validInputText(locality, "locality-error", "text") &&
            validInputText(title, "title-error", "text")) {
      if (countr) {
        country_error.innerText = "";
      } else {
        country_error.innerText = "Choisissez votre pays actuel";
      }
    }
  };

  useEffect(() => {
  }, []);

  return (
    <form className="form-pay-cart form-add">
      <span className="close" onClick={() => setClose(0)}>&times;</span>
      <h3> Payer par <b> {typeCard} </b>  </h3>
      <div className="div-input inputs">
        <p>
          <label> Nom*</label>
          <input type="text" name=""
            placeholder="..."
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)} />
          <span id="firstname-error"></span>
        </p>
        <p>
          <label> Prénom*</label>
          <input type="text" name=""
            placeholder="..."
            value={lastname}
            onChange={(e) => setLastname(e.target.value)} />
          <span id="lastname-error"></span>
        </p>
      </div>
      <div className="div-input">
        <label>Pays</label>
        <SelectCountryField countrys={countr} setCountry={setCountr} />
        <span id="ct-error"></span>
      </div>
      <div className="div-input inputs">
        <p>
          <label> Département </label>
          <input type="text" name=""
            placeholder="Département"
            value={department}
            onChange={(e) => setDepartments(e.target.value)} />
          <span id="dep-error"></span>
        </p>
        <p>
          <label> Localité </label>
          <input type="text" name=""
            placeholder="Cotonou"
            value={locality}
            onChange={(e) => setLocality(e.target.value)} />
          <span id="locality-error"></span>
        </p>
      </div>
      <div className="div-inputs">
        <label> Adresse </label>
        <input type="text" name=""
          placeholder="ex: Cotonou, 0BP9223.. /Cotonou"
          value={adress}
          onChange={(e) => setAdresse(e.target.value)} />
        <span id="adresse-error"></span>
      </div>
      <div className="div-input">
        <label> Téléphone </label>
        <div className="phones">
          <p> {setDialCode()} </p>
          <input type="text" name=""
            placeholder="Votre téléphone"
            value={tel}
            onChange={(e) => setTelephone(e.target.value)} />
        </div>
        <span id="tel-error"></span>
      </div>
      <div className="div-inputs">
        <label> Titre </label>
        <input type="text" name=""
          placeholder="ex: ..."
          value={title}
          onChange={(e) => setTitle(e.target.value)} />
        <span id="title-error"></span>
      </div>
      <button className="btn-send" type="submit" onClick={handleSubmit} > Enrégistré </button>
    </form>
  );
};
