import React, { useContext, useEffect, useState } from "react";
import { FiChevronDown, FiSearch } from "react-icons/fi";
import GeneralContext from "../../context/GlobalContext";
import { countries } from "../countrylist/countrylist";
import { country } from "../currencyList/countries";

import "./SelectCountryField.scss";

const SelectCountryField = ({ country_name, setCountry }) => {

  const [countrys, setCountrys] = useState(country_name);
  const [code, setCode] = useState();
  const [searchField, setSearchField] = useState("");

  const selectedCountry = (name, code) => {
    setCountry(name);
    setCountrys(name);
    setCode(code.toLowerCase());
    const currency = country.find(
      (item) => item.countryName === name
    );

    if (currency) {
      sessionStorage.setItem("currency", currency.currencyCode);
      sessionStorage.setItem("code_country", currency.countryCode.toLowerCase());
    }
  };

  useEffect(() => {
    if (countrys) {
      let find = country.find(
        (item) => item.countryName == countrys
      );

      if (find) {
        setCountry(find.countryName);
        setCode(find.countryCode.toLowerCase());
      }
    }
  }, [code, countrys]);


  return (
    <div className="select-country">
      <div className="selected">
        <div className="selected-item-options">
          {countrys ? (
            <>
              <i className={`flag-icon flag-icon-${code}`}>
              </i>
              <span> {countrys} </span>
            </>
          ) : <span>Sélectionner un pays</span>}
        </div>
        <FiChevronDown className="arrow" />
      </div>
      <div className="options-select">
        <div className='search-country-div'>
          <FiSearch />
          <input type="text" name="country"
            placeholder='Rechercher...'
            value={searchField}
            onChange={e => setSearchField(e.target.value)} />
        </div>
        {countries.map((list, index) => {
          if (!searchField) {
            return <li className="option"
              onClick={() => selectedCountry(list.name, list.code)}
              key={index}>
              <i className={`flag-icon flag-icon-${list.code.toLowerCase()}`}></i>
              <span>  {list.name} </span>
            </li>;
          } else {
            let a = list.name.toLowerCase();
            let b = searchField.toLowerCase();
            if (a.includes(b)) {
              return <li className="option"
                onClick={() => selectedCountry(list.name, list.code)}
                key={index}>
                <i className={`flag-icon flag-icon-${list.code.toLowerCase()}`}></i>
                <span>  {list.name} </span>
              </li>;
            }
          }
        })}
      </div>
    </div>
  );
};

export default SelectCountryField;
