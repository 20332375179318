import React, { useContext, useEffect, useState } from "react";
import { FaBuffer, FaBuysellads, FaCheck, FaList, FaUserCircle } from "react-icons/fa";
import { FiChevronDown, FiChevronRight, FiList, FiLogOut, FiUser } from "react-icons/fi";
import { FcShop } from "react-icons/fc";
import { AiOutlineDashboard } from "react-icons/ai";
import { MdPayment } from "react-icons/md";

import "./LayoutSeller.scss";

import logo from "../../../assets/images/svg/logo.svg";
import GeneralContext, { GlobalContext } from "../../../context/GlobalContext";
import { PopupLayout } from "../../../components/popupLayout/PopupLayout";
import { API_URL } from "../../../settings/constant";
import AuthContext from "../../../context/AuthContext";

const LayoutSeller = ({ children }) => {
  const [popup, isPopup] = useState(false);

  const { logoutStore } = useContext(AuthContext);
  const { store, update_status_store, userData } = useContext(GeneralContext);

  const [storeActive, setStoreActive] = useState("");
  const [storeActiveIndex, setStoreActiveIndex] = useState();
  const [index, setIndex] = useState(0);
  const [selects, isSelects] = useState(false);
  const [indexStoreActive, setIndexStoreActive] = useState(null);

  const [id, setId] = useState(0);
  const [id2, setId2] = useState(0);

  /* get store active */
  const getStoreActive = () => {
    if (store) {
      let find = store.find(
        (item) => item.is_active == true,
      );

      if (find) {
        setStoreActive(find.name_store.replace(/[-'_\s]/, ""));
        setIndexStoreActive(find.store_id);
      }
    }
  };

  /* menu link */
  const menu = [
    {
      label: "Tableau de bord",
      url: storeActive ? `/Tableau-de-bord/${storeActive.replaceAll(" ", "")}` : "",
      icon: <AiOutlineDashboard />,
      index: 0
    },
    {
      label: "Profile",
      url: storeActive ? `/Tableau-de-bord/${storeActive.replaceAll(" ", "")}/profile` : "",
      icon: <FiUser />,
      index: 1
    },
    {
      label: "Produits",
      url: "",
      icon: <FiList />,
      index: 20,
      submenu: [
        { label2: "Liste des produits", url2: storeActive ? `/Tableau-de-bord/${storeActive.replaceAll(" ", "")}/produits` : "", index: 3 },
        { label2: "Ajouter un produit", url2: storeActive ? `/Tableau-de-bord/${storeActive.replaceAll(" ", "")}/nouveau-produit` : "", index: 4 },
        { label2: "Corbeille", url2: storeActive ? `/Tableau-de-bord/${storeActive.replaceAll(" ", "")}/produits/cobeille` : "", index: 5 },
      ],
    },
    {
      label: "Commandes",
      url: storeActive ? `/Tableau-de-bord/${storeActive.replaceAll(" ", "")}/commandes` : "",
      icon: <FaBuffer />,
      index: 6
    },
    {
      label: "Ventes",
      url: storeActive ? `/Tableau-de-bord/${storeActive.replaceAll(" ", "")}/ventes` : "",
      icon: <FaBuysellads />,
      index: 7
    },
    /* {
      label: "Abonnements",
      url: storeActive ? `/Tableau-de-bord/${storeActive.replaceAll(" ", "")}/abonnement` : "",
      icon: <MdPayment />,
      index: 8
    } */
  ];

  /* functon navigate of tabs */
  const navigateUrl = (url, index) => {
    sessionStorage.setItem("menuId", index);
    const allLink = document.querySelectorAll(".link-p");
    const allSub = document.querySelectorAll(".sub-ul");

    if (url) {
      window.location.href = url;
    }

    let ids = parseInt(sessionStorage.getItem("menuId"));

    if (ids === 20 || ids == 3 || ids == 4 || ids == 5) {
      if (allSub[0].style.height == "0px") {
        allSub[0].style.height = "max-content";
        setId(1);
      } else {
        setId(0);
        allSub[0].style.height = "0px";
      }
    }

    for (let i = 0; i < allLink.length; i++) {
      allLink[i].classList.remove("active-menu");
    }

    for (let i = 0; i < allSub.length; i++) {
      allSub[i].classList.remove("open-sub-menu");
    }
    allLink[ids].classList.add("active-menu");
  };

  // show menu mobile
  const DropDownMenu = () => {
    const allSub = document.querySelectorAll(".sub-ul");
    let ids = parseInt(sessionStorage.getItem("menuId"));

    if (ids == 20 || ids == 3 || ids == 4 || ids == 5) {
      if (allSub[0].style.height === "0px") {
        allSub[0].style.height = "max-content";
        setId(1);
      } else {
        setId(0);
        allSub[0].style.height = "0px";
      }
    }
  };

  // show menu mobile nav
  const showMenu = () => {
    const menu = document.querySelector(".nav-bar");
    if (menu.style.width == "230px") {
      menu.style.width = "0px";
    } else {
      menu.style.width = "230px";
      menu.style.position = "absolute";
    }
  };

  /* select store */
  const selectStore = (id) => {
    if (store.length > 0) {
      let find = store.find(
        (item) => item.is_active === true,
      );

      if (find) {
        if (id === find.store_id) {
          setIndex(id);
        }
      }
    }
  };

  /* handle select store */
  const handleSelectStore = () => {
    update_status_store(storeActiveIndex, isSelects);

    if (selects) {
      sessionStorage.setItem("menuId", 0);
      window.location.href = storeActive ? `/Tableau-de-bord/${storeActive}` : "";
    }
  };

  /* return link store */
  const getLinkStore = () => {
    let link = "";
    let origin = window.location.origin;
    if (store.length > 0) {
      let find = store.find(
        (item) => item.is_active === true,
      );

      if (find) {
        link = `${origin}/${find.name_store.replaceAll(" ", "")}/${find.store_id}/produits`;
      }
    }
    return link;
  };

  const logout = () => {
    logoutStore();
  };


  useEffect(() => {
    getStoreActive();
    selectStore(indexStoreActive);
    DropDownMenu();
  }, [store]);


  return (
    <GlobalContext>
      <div className="dashboard-seller-home">
        <PopupLayout closes={popup}>
          <div className='div-select-store'>
            <span className='close-x' onClick={() => isPopup(false)}>&times;</span>
            <h1> Sélectionner une boutique </h1>
            <div className='store-div'>
              {
                store.length > 1 ? (
                  store.map((list) => {
                    return <p key={list.store_id} onClick={() => setStoreActiveIndex(list.store_id)}>
                      <span> {list.name_store} </span>
                      {storeActiveIndex === list.store_id ? <FaCheck /> : null}
                    </p>;
                  })
                ) : null
              }
            </div>
            <button onClick={handleSelectStore}>ok</button>
          </div>
        </PopupLayout>
        <div className="main">
          <div className="main-center">
            <div className="nav-bar">
              <span className="close-mobile-nav"
                onClick={showMenu}>&times;</span>
              <div className="logo z-index bg-white">
                <img src={logo} />
              </div>
              <div className="shop z-index ">
                <FcShop />
                <h4> {storeActive} </h4>
              </div>
              {store.length > 1 ? (
                <button className="btn-change-shop z-index"
                  onClick={() => isPopup(true)}>
                  Changer de boutique
                </button>
              ) : null
              }
              <div className="nav z-index">
                {
                  menu.map(({ label, url, index, icon, submenu }, key) => {
                    return <div className="nav-ul" key={key}>
                      <p onClick={() => navigateUrl(url, index)}
                        className={`link-p ${sessionStorage.getItem("menuId") == index ? "active-menu" : ""}`}>
                        <span> {icon} {label} </span>
                        {index == 20 ? (id == 1 ? <FiChevronDown /> : <FiChevronRight />) : null}
                        {index == 21 ? (id2 == 1 ? <FiChevronDown /> : <FiChevronRight />) : null}
                      </p>
                      {submenu && (
                        <div className="sub-ul">
                          {
                            submenu.map((list, index2) => {
                              return <li
                                className={`link-p ${sessionStorage.getItem("menuId") == list.index ? "active-menu" : ""}`}
                                onClick={() => navigateUrl(list.url2, list.index)}
                                key={index2}>
                                {list.label2}
                              </li>;
                            })
                          }
                        </div>
                      )}
                    </div>;
                  })
                }
              </div>
              <div className="footer">
                <p> &copy;2023 FeexMarket </p>
              </div>
            </div>
            <div className="body">
              <div className="head">
                <li>
                  <FaList onClick={showMenu} />
                  <h3> Bienvenu dans votre espace personnel </h3>
                </li>
                <p className='link-store'>
                  <a href={getLinkStore()} target='_blank' rel="noreferrer"> Aller sur ma boutique</a>
                </p>
                <div className="block-right">
                  <div className="user-prof">
                    <div>
                      <span> {userData.firstname ? userData.firstname + " " + userData.lastname : "Utilisateur"} </span>
                      <button onClick={logout}> <FiLogOut /> Deconnexion </button>
                    </div>
                    {userData.image_profil ? <img src={`${API_URL}${userData.image_profil}`} /> : <FaUserCircle />}
                  </div>
                </div>
              </div>
              <div className="content-body">
                <div className="block-content">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    </GlobalContext>
  );
};

export default LayoutSeller;
