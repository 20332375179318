import React, { useState } from "react";
import { FiSearch } from "react-icons/fi";

import "../Header.scss";

export const SearchBar = () => {

  const [keyword, setKeyword] = useState("");

  const searchWord = e => {
    e.preventDefault();
    if (keyword) {
      let words = keyword.replaceAll(" ", "+",0);
      window.location.href=`/produits/recherches/produit?${words}`;
    }
  };


  return (
    <>
      <form className="form-search">
        <input type="search" name="keyword"
          placeholder="Rechercher vos produits ici"
          value={keyword}
          onChange={e => setKeyword(e.target.value)} />
        <button type="submit" onClick={searchWord}> <FiSearch /> </button>
      </form>
    </>
  );
};
