import React from "react";
import {InfoShop} from "../fragments/InfoShop";
import {NavBarShop} from "../fragments/NavBarShop";
import {RightBtns} from "../fragments/RightBtns";
import {SearchBarShop} from "../fragments/SearchBarShop";
import {TopBar} from "../fragments/TopBar";
import Header from "../Header";
import BarInfosStore from "./BarInfosStore";


const HeaderShop = () => {
  return (
    <div className="body-shop">
      <Header children1={<TopBar />}
        children2={<SearchBarShop />}
        children3={<RightBtns />}
        children5={null}
        children6={<BarInfosStore />}
        children4={<NavBarShop />}
      />
    </div>
  );
};

export default HeaderShop;
