import React, { Suspense, useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CardSectionProd.scss";

import { FaAngleDoubleRight } from "react-icons/fa";
import { API_URL, PROMO_PAGE } from "../../settings/constant";
import GeneralContext from "../../context/GlobalContext";


const CardSectionProd = ({ text, categorie }) => {
  const { allProducts } = useContext(GeneralContext);

  const settings = {
    dots: false,
    infinite: true,
    autoplaySpeed: 3000,
    slidesToScroll: 1,
    slidesToShow: 1,
    autoplay: true,
    arrows: true,
  };

  const [offre, setOffre] = useState([]);

  useEffect(() => {
    const filter = allProducts.filter(
      (item) => item.reduces != 0,
    );

    if (filter.length != 0) {
      if (filter.length > 8) {
        setOffre(filter);
      } else {
        setOffre(allProducts);
      }
    }
  }, [allProducts, offre]);

  return (
    <section className="section-five section">
      <div className="div-left">
        <h3> {text} </h3>
        <div className="sliders">
          <Slider {...settings} >
            {offre.length != 0 ?
              (offre.map((list, index) => {
                if (list.reduces != 0) {
                  return <div key={index}>
                    <img src={`${API_URL}${list.image_prod}`} />
                  </div>;
                }
              })
              ) : (null)
            }
          </Slider>
        </div>
      </div>
      <div className="div-right">
        <h3>
          {categorie}
          <span> <a href={PROMO_PAGE}> Voir plus <FaAngleDoubleRight /> </a> </span>
        </h3>
        <div className="div-product">

          {offre.length != 0 ?
            offre.map((list, index) => {
              if (list.reduces != 0) {
                if (index < 8) {
                  return <Link to={`produit/details/${list.name_prod.replaceAll(" ", "-")}`} state={list} key={index}>
                    <div className="div-img">
                      <h5> -{list.reduces}% </h5>
                      <Suspense fallback={"....."}>
                        <img src={`${API_URL}${list.image_prod}`} />
                      </Suspense>
                      <h4> {list.name_prod} </h4>
                    </div>
                  </Link>;
                }
              }
            }) : null
          }
        </div>
      </div>
    </section>
  );
};

export default CardSectionProd;
