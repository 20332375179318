import React from "react";
import { CardProdShop } from "../cardProdShop/CardProdShop";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./CardProdShop3.scss";
import { API_URL2 } from "../../settings/constant";
import { Link } from "react-router-dom";

export const CardProdShop3 = ({ listProduct, title }) => {

  const settings2 = {
    dots: false,
    infinite: true,
    autoplaySpeed: 3000,
    slidesToScroll: 1,
    slidesToShow: 1,
    autoplay: true,
    arrows: true,
  };

  return (
    <section className="section-prod section">
      <h2 className="title-section">
        {title}
      </h2>
      <div className="groups-cards-list-product">
        <div className="cards">
          <h3> Meilleur product</h3>
          <div className="slider-prods">
            <Slider {...settings2}>
              {listProduct && (
                listProduct.map((list, index) => {
                  return <Link to={{ pathname: `/produit/details/${list.name_prod.replaceAll(" ", "-")}` }} state={list} key={index}>
                    <div key={index}>
                      <img src={`${API_URL2}${list.image_prod}`} />
                    </div>;
                  </Link>;
                })
              )}
            </Slider>
          </div>
        </div>
        <CardProdShop listProductPopular={listProduct} />
        <CardProdShop listProductPopular={listProduct} />
      </div>
    </section>
  );
};
