import React, { useContext, useEffect, useState } from "react";
import { FaRegAddressCard, FaCheckCircle, FaRegCreditCard } from "react-icons/fa";
import { FiCheckCircle, FiEdit, FiEdit3, FiPlus, FiTrash } from "react-icons/fi";
import { useSelector } from "react-redux";

import "./Checkout.scss";

import { LayoutBloc } from "./LayoutBloc";
import mtn from "../../../assets/images/png/mtnPay.png";
import moov from "../../../assets/images/png/moovPay.png";
import visa from "../../../assets/images/png/visaPay.png";
import mastercard from "../../../assets/images/png/masterPay.png";
import gif_success from "../../../assets/images/icon_success.gif";
import load from "../../../assets/images/loaderwhite.gif";

import Cart from "../../../components/cart/Cart";
import { PopupLayout } from "../../../components/popupLayout/PopupLayout";
import { SummaryOrder } from "../../../components/summaryOrder/SummaryOrder";
import { FormAdresse } from "../../../components/formAdresse/formAdresse";
import { FormPayCart } from "../../../components/formPayCart/formPayCart";
import { FormPayNetwork } from "../../../components/formPayNetwork/formPayNetwork";
import GeneralContext from "../../../context/GlobalContext";
import { clearCart } from "../../../store/cart/cartSlice";
import { Spinner2 } from "../../../components/spinner2/Spinner2";
import { HOME, ORDER } from "../../../settings/constant";


const Checkout = () => {
  const [popup, isPopup] = useState(false);
  const [popup2, isPopup2] = useState(false);
  const [popup3, isPopup3] = useState(false);
  const [popup4, isPopup4] = useState(false);
  const [popup5, isPopup5] = useState(false);
  const [popup6, isPopup6] = useState(false);
  const [popup7, isPopup7] = useState(false);
  const [popup8, isPopup8] = useState(false);
  const [done, isDone] = useState(false);

  const cart = useSelector((state) => state.cart.cartItem);

  const { shippingAdress,
    getShippingAdress,
    delete_adress,
    save_sale,
    payment,
    userData,
    shoppingCartItem, updateShoppingCartItemQty,
    deleteShoppingCartItem } = useContext(GeneralContext);

  const [idAdr, setIdAdr] = useState();
  const [names, setNames] = useState("");
  const [countrys, setCountrys] = useState("");
  const [citys, setCity] = useState("");
  const [departments, setDepartment] = useState("");
  const [phone, setPhone] = useState("");

  const [shippingAdressId, setShippingAdressId] = useState(null);

  const [action, setAction] = useState("ajout");

  // data network send api pay
  const [network, setNetwork] = useState(null);
  const [numberPhone, setNumberPhone] = useState(null);
  const [amount, setAmount] = useState(0);

  // data carte bancaire send api pay
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [countr, setCountr] = useState("");
  const [department, setDepartments] = useState("");
  const [adresses, setAdresses] = useState("");
  const [telephone, setTelephone] = useState("");
  const [title, setTitle] = useState("");
  const [locality, setLocality] = useState("");
  const [typeCard, setTypeCard] = useState(null);
  const [mp, setMp] = useState(null);

  /* select card bank */
  const selectCard = (card) => {
    setMp(card);
    setTypeCard(card);
    setNetwork(null);
    isPopup2(true);
  };

  /* select network pay */
  const selectNetwork = (net) => {
    setMp(net);
    isPopup3(true);
    setTypeCard(null);
    setNetwork(net);
  };

  /* validate shop */
  const validShop = async (e) => {
    e.preventDefault();
    if (typeCard !== null) {
      if (shippingAdressId !== null) {
        save_sale(shippingAdressId, mp, amount, numberPhone, shoppingCartItem, isPopup5, isDone, isPopup6, isPopup7, isPopup8);
      } else {
        alert("Sélectionner une adresse de livraison");
      }

    } else if (network !== null) {
      if (numberPhone !== null) {
        if (shippingAdressId !== null) {
          save_sale(shippingAdressId, mp, amount, numberPhone, shoppingCartItem, isPopup5, isDone, isPopup6, isPopup7, isPopup8);
        } else {
          alert("Sélectionner une adresse de livraison");
        }
      } else {
        alert("Entrer le numéro du retrait");
      }
    } else {
      alert("Choisissez un moyen de paiement pour votre achat");
    }
  };


  /* Operation with adresse user */
  const update_adresse = (id, n, c, dp, ct, p) => {
    setIdAdr(id);
    setNames(n);
    setCountrys(c);
    setDepartment(dp);
    setCity(ct);
    setPhone(p);
    setAction("update");

    isPopup(true);
  };

  useEffect(() => {
    if (action == "ajout") {
      setNames("");
      setCountrys("");
      setDepartment("");
      setCity("");
      setPhone("");
    }
  }, [action, userData, mp]);


  return (
    <div className="checkout-page">
      <PopupLayout closes={popup5}>
        <div className="loading-pay">
          {!done ? <Spinner2 /> : (
            <div className="msg-succes-done">
              <img src={gif_success} />
              <p>
                Paiement éffectuer avec succès. Votre
                commande a été envoyer avec succès. Notre service
                de livraison vous contactera pour la suite.
              </p>
              <button onClick={() => window.location.href = HOME}>
                Retour à l'accueil
              </button>
            </div>
          )}

        </div>
      </PopupLayout>
      <PopupLayout closes={popup3}>
        <FormPayNetwork
          setTelephone={setNumberPhone}
          countr={countr}
          setCountr={setCountr}
          tel={numberPhone}
          isPopup3={isPopup3}
          typeNetwork={network}
          mp={mp} />
      </PopupLayout>
      <PopupLayout closes={popup2}>
        <FormPayCart firstname={firstname}
          lastname={lastname}
          countr={countr}
          department={department}
          adress={adresses}
          tel={telephone}
          title={title}
          locality={locality}
          setFirstname={setFirstname}
          setLastname={setLastname}
          setCountr={setCountr}
          setDepartments={setDepartments}
          setAdresse={setAdresses}
          setTelephone={setTelephone}
          setLocality={setLocality}
          setTitle={setTitle}
          typeCard={typeCard}
          isPopup2={isPopup2}
          mp={mp}
        />
      </PopupLayout>
      <PopupLayout closes={popup6}>
        <div className="loading-pay">
          <div className="msg-succes-done">
            <p>
              Achat échoué
            </p>
            <button onClick={() => window.location.reload()}>
              Réessayer
            </button>
          </div>
        </div>
      </PopupLayout>
      <PopupLayout closes={popup7}>
        <div className="loading-pay">
          <div className="msg-succes-done">
            <p>
              Paiement non éffectuer. Votre commande n'a pas été envoyer.
              Veuillez Réessayer
            </p>
            <button onClick={() => window.location.reload()}>
              Réessayer
            </button>
          </div>
        </div>
      </PopupLayout>
      <PopupLayout closes={popup8}>
        <div className="loading-pay">
          <div className="msg-succes-done">
            <p>
              Paiement éffectuer. Echec de l'envoie de la facture.
              Demander votre facutre dans le tableau de la page commande.
            </p>
            <button onClick={() => window.location.href = ORDER}>
              Demander
            </button>
          </div>
        </div>
      </PopupLayout>
      <PopupLayout closes={popup} >
        <FormAdresse isPopup={isPopup}
          getData={getShippingAdress}
          id={idAdr}
          n={names}
          co={countrys}
          dp={departments}
          cy={citys}
          p={phone}
          action={action}
          setAction={setAction}
          isPopup2={isPopup2} />
      </PopupLayout>
      <div className="div-left-page">
        <LayoutBloc icon={<FaRegAddressCard />} title={"Adresse de livraison"}
          children2={shippingAdress.length != 0 ? <button onClick={() => isPopup(true)}> <FiPlus /> Ajouter une adresse </button> : null}>
          <div className="edit-form-div">
            {
              shippingAdress.length != 0 ? (
                shippingAdress.map((list, index) => {
                  return (
                    <div key={index} className={`infos-addr ${shippingAdressId == list.id_addr ? "select-adr" : ""}`}
                      onClick={() => setShippingAdressId(list.id_addr)}>
                      <div>
                        <p>
                          <b> {list.fullname} </b>
                        </p>
                        <p> {list.telephone} </p>
                        <p> {list.country}, {list.department}, {list.city} </p>
                      </div>
                      <div>
                        <button onClick={() => update_adresse(
                          list.id_addr,
                          list.fullname,
                          list.country,
                          list.department,
                          list.city,
                          list.telephone,
                        )}> <FiEdit3 /> Modifier </button>
                        <button onClick={() => delete_adress(list.id_addr)}> <FiTrash /> Supprimer </button>
                      </div>
                      <span className="check"> <FaCheckCircle /> </span>
                    </div>
                  );
                })
              ) : (
                <button onClick={() => isPopup(true)}>
                  <FiEdit />
                  Votre Adresse
                </button>
              )
            }
          </div>
        </LayoutBloc>
        <LayoutBloc icon={null} title={"Paiement"}>
          <div className="payments">
            <div className="mode-pay pay1">
              <h4>Choisissez votre moyen de paiement</h4>
              <div className="moyen-pay">
                <div>
                  <input type="radio" name="mode-pay" onClick={() => selectNetwork("MTN")} />
                  <img src={mtn} />
                </div>
                <div>
                  <input type="radio" name="mode-pay" onClick={() => selectNetwork("MOOV")} />
                  <img src={moov} />
                </div>
              </div>
            </div>
            <div className="mode-pay">
              <h4>Payer par carte bancaire <FaRegCreditCard /> </h4>
              <div className="moyen-pay">
                <div>
                  <input type="radio" name="mode-pay" onClick={() => selectCard("carte visa")} />
                  <img src={visa} />
                </div>
                <div>
                  <input type="radio" name="mode-pay" onClick={() => selectCard("mastercard")} />
                  <img src={mastercard} />
                </div>
              </div>
            </div>
          </div>
        </LayoutBloc>
        <LayoutBloc icon={null} title={"Panier"}>
          <div className="card-shop-verify">
            <Cart shoppingCartItem={shoppingCartItem}
              updateShoppingCartItemQty={updateShoppingCartItemQty}
              deleteShoppingCartItem={deleteShoppingCartItem} />
          </div>
        </LayoutBloc>
      </div>
      <SummaryOrder setPrice={setAmount} handleValid={validShop} />
    </div>
  );
};

export default Checkout;
