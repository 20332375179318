import React, { useEffect, useState } from "react";
import { PopupLayout } from "../popupLayout/PopupLayout";

import "./Footer.scss";
import { Newsletter } from "./fragments/NewsletterForm";


const Footer = ({ children1, children2 }) => {

  const [popup, isPopup] = useState(false);

  const setYear = () => {
    const date = new Date();
    const year = date.getFullYear();

    const el = document.getElementById("year");
    return year;
  };

  useEffect(() => {
    let scroll_y = window.scrollY;
    setTimeout(() => {

    }, 10);
  });


  return (
    <>
      <footer>
        {/*  {children1} */}
        {children2}
        <div className="row2">
          <p>&copy; {setYear()} Tout droit réservé</p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
