import React from "react";
import logo from "../../../assets/images/svg/logo.svg";
import facebook from "../../../assets/images/svg/facebook.svg";
import youtube from "../../../assets/images/svg/youtube.svg";
import linkedin from "../../../assets/images/svg/linkedin.svg";
import twitter from "../../../assets/images/svg/twitter.svg";

import { MoyenPay } from "../../moyenPay/MoyenPay";

import "../Footer.scss";
import { CONDITION_GENERAL, 
  POLITIC_CONFIDENTIALITe, 
  POLITIC_REMBOURSEMENT, 
  URL_FACEBOOk, 
  URL_LINKEDIN, 
  URL_TWITTER, 
  URL_YOUTUBE } from "../../../settings/constant";


export const CenterRow = () => {

  return (
    <>
      <div className="row1">
        <div className="foot-div1">
          <img src={logo} alt="" />
          <p>
            FeexMarket vous offre la possibilité d'acheter et
            de vendre vos produits en toute sécurité partout dans le monde.
          </p>
        </div>
        <div className="bar"></div>
        <div className="foot-div2">
          <h2> En savoir plus </h2>
          <ul>
            <a href={CONDITION_GENERAL}> Conditions Générales</a>
            <a href={POLITIC_CONFIDENTIALITe}> Politiques de Confidentialité</a>
            <a href={POLITIC_REMBOURSEMENT}> Politiques de Remboursement</a>
          </ul>
        </div>
        <div className="bar"></div>
        <div className="foot-div4">
          <div className="modepay">
            <h2> Nos Moyens de Paiement</h2>
            <MoyenPay />
          </div>
          <div className="socials">
            <h2>Réseaux sociaux:</h2>
            <ul>
              <a href={URL_FACEBOOk} target="_blank" rel="noreferrer" ><img src={facebook} alt="" /></a>
              <a href={URL_LINKEDIN} target="_blank" rel="noreferrer"><img src={linkedin} alt="" /></a>
              <a href={URL_TWITTER} target="_blank" rel="noreferrer"><img src={twitter} alt="" /></a>
              <a href={URL_YOUTUBE} target="_blank" rel="noreferrer"><img src={youtube} alt="" /></a>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
