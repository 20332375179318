import React, { useContext, useEffect } from "react";
import { FaHeart } from "react-icons/fa";
import { Link } from "react-router-dom";

import "./CardProducts.scss";

import GeneralContext from "../../context/GlobalContext";
import { API_URL } from "../../settings/constant";

const CardProducts = ({ listProduct, nextPage, prevPage, category }) => {

  const { add_favoris, whishlist } = useContext(GeneralContext);

  const activebtn = () => {
    let all = document.querySelectorAll(".card-product .svg");
    if (whishlist.length !== 0) {
      for (var i = 0; i < whishlist.length; i++) {
        for (var j = 0; j < listProduct.length; j++) {
          if (whishlist[i].Product.id_prod === listProduct[j].id_prod) {
            all[j].classList.add("active-whishlist-btn");
          }
        }
      }
    }
  };

  useEffect(() => {
    activebtn();
  }, [listProduct]);


  return (
    <>
      {
        listProduct && (
          listProduct.map((list, index) => {
            if (prevPage <= index && (nextPage - 1) >= index) {
              return <div className="card-product" key={index}>
                <div className="img">
                  <Link to={{ pathname: `/produit/details/${list.name_prod.replaceAll(" ", "-")}` }} state={list} >
                    <img src={`${API_URL}${list.image_prod}`} alt={list.name_prod} />
                  </Link>
                  <li className='btn-favori'
                    onClick={() => add_favoris(list.id_prod, ".card-product .svg", index, activebtn)}>
                    <FaHeart className='svg' />
                    <span> Ajouter au favoris </span>
                  </li>
                </div>
                <div className="foot">
                  <h4> {list.name_prod} </h4>
                  <p>
                    <b> XOF {list.reduces == 0 ? Math.round(list.price_prod) : (Math.round(list.price_prod) - (Math.round(list.price_prod) * list.reduces) / 100)} </b>
                    {list.reduces != 0 ? <span>XOF {Math.round(list.price_prod)} </span> : null}
                  </p>
                </div>
              </div>;
            }
          })
        )
      }
    </>
  );
};

export default CardProducts;
