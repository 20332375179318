import React, { useContext, useEffect, useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CardProducts from "../../../components/cardProducts/CardProducts";
import Pagination from "../../../components/pagination/Pagination";
import GeneralContext from "../../../context/GlobalContext";

import "./ShopCategorie.scss";


const ShopProduct = () => {

  const {
    getProductToStore,
    productStore,
    getCategoriesByStore,
    getProductByCategoriesToStore,
    categorieByStore} = useContext(GeneralContext);

  const [categorie, setCategorie] = useState(null);
  const [nb, setNb] = useState(20);
  const [nextPage, setNextPage] = useState(nb);
  const [prevPage, setPrevPage] = useState(0);

  const [indexCat, setIndexCat] = useState(
    sessionStorage.getItem("indexCat") ?
      sessionStorage.getItem("indexCat") : "",
  );

  const settings2 = {
    dots: false,
    infinite: true,
    autoplaySpeed: 5000,
    slidesToScroll: 1,
    slidesToShow: 1,
    autoplay: true,
    arrows: false,
  };

  const active = {
    background: "white",
    color: "var(--orange)",
    fontWeight: "600",
  };

  const no_active = {
    background: "none",
    color: "white",
  };

  const setInfosStore = () => {
    let path = window.location.pathname.split("/");
    let id = path[2];

    if (id) {
      getProductToStore(id);
      getCategoriesByStore(id);
    }
  };

  // action event scroll
  const scroll = (aside) => {
    let div = document.querySelector(".list-cat");
    if (aside == "right") {
      div.scrollLeft += 150;
    } else {
      div.scrollLeft += -150;
    }
  };

  const handleScroll = () => {
    let div = document.querySelector(".list-categorie");

    let y = window.scrollY;

    if (y > 100) {
      div.style.position = "fixed";
      div.style.top = "0%";
      div.style.width = "100%";
      div.style.zIndex = "1";
      div.style.left = "0%";
      div.style.background = "white";
    } else {
      div.style.position = "static";
    }
  };

  // active categorie 
  const activeBtnTabs = (index, idCat) => {
    setIndexCat(index);
    setCategorie(idCat);
  };

  useEffect(() => {

    if (categorie) {
      let path = window.location.pathname.split("/");
      let id = path[2];
      console.log(categorie);
      getProductByCategoriesToStore(id, categorie);
    } else {
      setInfosStore();
    }

    // scroll event
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [categorie]);


  return (
    <div className="shop-product">
      <section className='baners-slide'>
        <Slider {...settings2} >
          <div className='img1'></div>
          <div className='img2'></div>
          <div className='img3'></div>
        </Slider>
      </section>

      <section className="section2-shop section">
        <div className="list-categorie">
          <div className="list-cat">
            <button className='btn-scroll left' onClick={() => scroll("left")}> <span><FiChevronLeft /></span> </button>
            <button className='btn-scroll right' onClick={() => scroll("right")}> <span><FiChevronRight /></span> </button>
            <li style={indexCat === 0 ? active : no_active}
              onClick={() => activeBtnTabs(0, 0)}>Tout</li>
            {categorieByStore.length > 0 ? (
              categorieByStore.map((list, index) => {
                return <li
                  style={indexCat === index+1 ? active : no_active}
                  onClick={() => activeBtnTabs(index+1, list.idCat)}
                  key={index}> {list.nameCat} </li>;
              })
            ) : null
            }
          </div>

        </div>
        <div className="div-product">
          <CardProducts listProduct={productStore}
            nextPage={nextPage} prevPage={prevPage} category={categorie} />
        </div>
        <Pagination nextPage={nextPage}
          prevPage={prevPage}
          nbProd={nb}
          countProd={productStore ? productStore.length : null}
          setNextPage={setNextPage}
          setPrevPage={setPrevPage} />
      </section>
    </div>
  );
};

export default ShopProduct;
