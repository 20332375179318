import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { FiEdit3, FiTrash2 } from "react-icons/fi";

import { HeadTabs } from "../../../../components/headTabs/HeadTabs";
import Pagination from "../../../../components/pagination2/Pagination";
import { PopupLayout } from "../../../../components/popupLayout/PopupLayout";
import SearchBar from "../../../../components/searchBar2/SearchBar";
import GeneralContext from "../../../../context/GlobalContext";
import { API_URL, config } from "../../../../settings/constant";

import "./Sale.scss";

const Sale = () => {
  const [searchWord, setSearchWord] = useState("");
  const [startNbPage, setStartNbPage] = useState(3);
  const [nextPage, setNextPage] = useState(startNbPage);
  const [previouspage, setPreviousPage] = useState(0);
  const [resultSearch, setResultSearch] = useState([]);
  const [storeActive, setStoreActive] = useState([]);

  const { saleStore, store, getSaleStore,
    updateSaleStatus, deleteSaleStore } = useContext(GeneralContext);

  const [closes, isClose] = useState(false);

  const [pay, isPay] = useState();
  const [delivr, isDelivr] = useState();
  const [idSale, setIdSale] = useState();
  const [orderId, setOrderId] = useState();
  const [dateDelivr, setDateDelivre] = useState(null);

  const search = async () => {
    if (searchWord) {
      const tab = saleStore.filter(
        (item) => (item.user.firstname + " " + item.user.lastname).toLowerCase().includes(searchWord.toLowerCase()) ||
          item.products.name_prod.toLowerCase().includes(searchWord.toLowerCase()) ||
          (item.is_paid ? "Payé" : "Non payé").toLowerCase().includes(searchWord.toLowerCase()) ||
          (item.is_delivered ? "livré" : "En attente").toLowerCase().includes(searchWord.toLowerCase())
      );

      if (tab.length > 0) {
        console.log(tab);
        setResultSearch(tab);
      } else {
        setResultSearch([]);
      }
    }
  };

  // update sale
  const handleUpdateSale = async (e) => {
    e.preventDefault();
    updateSaleStatus(orderId, delivr, dateDelivr);
  };

  const returnStore = () => {
    if (store.length > 0) {
      for (var i = 0; i < store.length; i++) {
        if (store[i].is_active == true) {
          getSaleStore(store[i].store_id);
          setStoreActive(store[i]);
          return 0;
        }
      }
    }
  };

  const UpdateSale = (id, order_id, p, d) => {
    setOrderId(order_id);
    setIdSale(id);
    isPay(p);
    isDelivr(d);
    isClose(true);
  };


  useEffect(() => {
    returnStore();
    search();
  }, [store, searchWord]);

  return (
    <div className="sale-dashboard-buyer">
      <PopupLayout closes={closes}>
        <div className="div-form-edit">
          <button className="close-x" onClick={() => isClose(false)} >&times;</button>
          <h2>Ventes N° <span> {idSale ? idSale : 0} </span></h2>
          <p className='text-alert'></p>
          <form>
            <p>
              <label>Status livraison : </label>
              <select value={delivr} onChange={(e) => isDelivr(e.target.value)}>
                <option value={false}>En attente</option>
                <option value={true}>livré</option>
              </select>
            </p>
            {
              delivr ? (
                <p>
                  <label> Date de livraison : </label>
                  <input type="datetime-local"
                    value={dateDelivr}
                    onChange={e => setDateDelivre(e.target.value)} />
                </p>
              ) : null
            }
            <button onClick={handleUpdateSale}>Mettre à jour</button>
          </form>
        </div>
      </PopupLayout>
      <HeadTabs title={"Mes Ventes"} />
      <div className='sale parent'>
        <div className="child">
          <SearchBar placeholderText="Rechercher par nom produit, acheteur et status"
            searchWord={searchWord} allList={saleStore.length > 0 ? saleStore : null}
            setList={setResultSearch}
            setWord={setSearchWord} />
          <table className="table-data">
            <thead>
              <tr>
                <th> N° </th>
                <th> Nom Acheteur </th>
                <th> Nom Produits </th>
                <th> Photo product </th>
                <th> Quantité </th>
                <th> Prix Total (XOF) </th>
                <th> Status payement</th>
                <th> Status livraison </th>
                <th> Adresse de livraison </th>
                <th> Actions </th>
              </tr>
            </thead>
            <tbody>
              {
                resultSearch.length == 0 ? (
                  saleStore.length > 0 && (
                    saleStore.map((list, index) => {
                      if (list.is_delivered) {
                        if (previouspage <= index && (nextPage - 1) >= index) {
                          return <tr key={index}>
                            <td> {index + 1} </td>
                            <td> {`${list.user.firstname} ${list.user.lastname}`} </td>
                            <td> {list.Product.name_prod} </td>
                            <td> <img src={`${API_URL}${list.Product.image_prod}`} /> </td>
                            <td> {list.qty} </td>
                            <td> {list.amount} </td>
                            <td> {list.is_paid ? "Payé" : "Non Payé"} </td>
                            <td> {list.is_delivered ? "livré" : "En attente"} </td>
                            <td>
                              {list.Order.ShippingAddress.fullname} <br />
                              {list.Order.ShippingAddress.telephone}<br />
                              {list.Order.ShippingAddress.country},
                              &nbsp;{list.Order.ShippingAddress.department},
                              &nbsp;{list.Order.ShippingAddress.city}
                            </td>
                            <td>
                              <button onClick={() => UpdateSale(index + 1, list.id_oi, list.is_paid, list.is_delivered)}> <FiEdit3 /> </button>
                              {list.is_delivered ? <button onClick={() => deleteSaleStore(list.id_oi)}> <FiTrash2 /> </button> : null}
                            </td>
                          </tr>;
                        }
                      }
                    })
                  )
                ) : (
                  resultSearch.map((list, index) => {
                    if (!list.is_delivered) {
                      return <tr key={index}>
                        <td> {index + 1} </td>
                        <td> {`${list.user.firstname} ${list.user.lastname}`} </td>
                        <td> {list.Product.name_prod} </td>
                        <td> <img src={`${API_URL}${list.Product.image_prod}`} /> </td>
                        <td> {list.qty} </td>
                        <td> {list.amount} </td>
                        <td> {list.is_paid ? "Payé" : "Non Payé"} </td>
                        <td> {list.is_delivered ? "livré" : "En attente"} </td>
                        <td>
                          {list.Order.ShippingAddress.fullname} <br />
                          {list.Order.ShippingAddress.telephone}<br />
                          {list.Order.ShippingAddress.country},
                          &nbsp;{list.Order.ShippingAddress.department},
                          &nbsp;{list.Order.ShippingAddress.city}
                        </td>
                        <td>
                          <button onClick={() => UpdateSale(index + 1, list.id_oi, list.is_paid, list.is_delivered)}> <FiEdit3 /> </button>
                          {list.is_delivered ? <button onClick={() => deleteSaleStore(list.id_oi)}> <FiTrash2 /> </button> : null}
                        </td>
                      </tr>;
                    }
                  })
                )

              }
            </tbody>
          </table>
          {resultSearch.length == 0 ? (
            <Pagination nb={startNbPage}
              countTab={saleStore.length > 0 ? saleStore.length : 0}
              setNextt={setNextPage}
              setPrevious={setPreviousPage}
              nextt={nextPage}
              previous={previouspage} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Sale;
