import React, { useContext, useEffect } from "react";
import { FiHeart, FiLogOut, FiShoppingCart } from "react-icons/fi";

import "./BoxAccount.scss";

import AuthContext from "../../context/AuthContext";
import GeneralContext from "../../context/GlobalContext";
import { FaLayerGroup, FaUserCircle } from "react-icons/fa";
import { CART_SHOPPING, ORDER, WHISHLIST } from "../../settings/constant";


const BoxAccount = ({ openAuth }) => {
  const { user, logoutUser } = useContext(AuthContext);
  const { userData, store, update_status_store, getStore } = useContext(GeneralContext);

  /* open store */
  const openStore = () => {
    if (store.length > 0) {
      update_status_store(store[0].store_id, user.user_id);
      getStore();
      for (var i = 0; i < store.length; i++) {
        if (store[i].is_active == true) {
          window.location.href = `/Tableau-de-bord/${store[0].name_store}`;
          return 0;
        }
      }
    }
  };
  
  const renders = () => {
    if (user && userData) {
      return (
        <>
          <p className='user-name'>
            Bienvenue !!!
            <FaUserCircle />
            <b> {userData.firstname ? userData.firstname: ""  +" "+userData.lastname ? userData.lastname:""} </b>
          </p>
          <div className='menu-div'>
            { store.length > 0 ? (
              <button className='open-store' onClick={openStore}> Ma boutique </button>
            ):null}
            <a href={ORDER}> <FaLayerGroup /> Mes Commandes</a>
            <a href={WHISHLIST}> <FiHeart /> Mes Favoris</a>
            <a href={CART_SHOPPING}> <FiShoppingCart /> Mon Panier</a>
          </div>
          <button className='btn-log-out' onClick={logoutUser}> <FiLogOut /> Deconnexion</button>
        </>
      );
    } else {
      return (
        <>
          <p> <b>Bienvenue !!!</b> Vous avez déjà un compte ?</p>
          <div className="btn-connects">
            <a onClick={() => openAuth(1, true)}>Connectez-vous</a>
            <a onClick={() => openAuth(0, true)}>Inscrivez-vous</a>
          </div>
        </>
      );
    }
  };

  useEffect(() => {
  }, []);

  return (
    <div className="box-account">
      {renders()}
    </div>
  );
};

export default BoxAccount;
