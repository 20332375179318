import React, { useContext, useEffect, useState } from "react";
import { FaMousePointer } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

import "./index.scss";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CardProduct2 from "../../../components/cardProduct2/CardProduct2";
import s1 from "../../../assets/images/products/s1.png";
import s2 from "../../../assets/images/products/s2.png";
import s3 from "../../../assets/images/products/s3.png";
import s4 from "../../../assets/images/products/s4.png";
import market from "../../../assets/images/png/shop.png";
import g2 from "../../../assets/images/png/g2.png";
import { API_URL, APP_FEEXPAY, PRODUCT } from "../../../settings/constant";
import CardSectionProd from "../../../components/cardSectionProd/CardSectionProd";
import { CardProdShop2 } from "../../../components/cardProdShop2/CardProdShop2";
import AuthContext from "../../../context/AuthContext";
import { FiChevronRight } from "react-icons/fi";
import GeneralContext from "../../../context/GlobalContext";
import Auth from "../../../components/authForms/Auth";
import { Spinner2 } from "../../../components/spinner2/Spinner2";


const Home = (props) => {
  const { user } = useContext(AuthContext);
  const { allProducts, userData } = useContext(GeneralContext);

  const [openAuth, setOpenAuth] = useState(false);
  const [openForm, setOpenForm] = useState(0);

  const openForms = (form, auth) => {
    setOpenForm(form);
    setOpenAuth(auth);
  };


  const settings = {
    dots: false,
    infinite: true,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    slidesToShow: 1,
    autoplay: true,
    arrows: false,
  };

  const settings3 = {
    dots: false,
    infinite: true,
    autoplaySpeed: 3000,
    slidesToScroll: 1,
    slidesToShow: 5,
    autoplay: true,
    arrows: false,
  };


  /* return component */
  const rendersBox = () => {
    if (user) {
      return (
        <>
          <p> Bienvenue !!! </p>
          <h2> {userData.firstname ? userData.firstname: ""  +" "+userData.lastname ? userData.lastname:""} </h2>
          <div className="bar"></div>
          <p>Envie de devenir un Vendeur ?</p>
          <button
            className="btn-seller"> <a href={APP_FEEXPAY} target="_blank" rel="noreferrer">Inscrivez-vous</a> <FaMousePointer /> </button>
        </>
      );
    } else {
      return (
        <>
          <p>Vous avez déjà un compte ?</p>
          <button className="btn-connect" onClick={() => openForms(1, true)}> me connecter </button>
          ou
          <button className="btn-ins" onClick={() => openForms(0, true)}>nous rejoindre</button>
        </>
      );
    }
  };


  useEffect(() => {
  }, [userData]);


  return (
    <div className="home-page">
      {openAuth ? <Auth formAuth={openForm} openAuth={setOpenAuth} /> : ""}
      <section className="section-one section">
        <div className="div1">
          <div className="div1-child1">
            <h1>
              FeexMarket
            </h1>
            <span> Acheter et Vendre vos produits</span>
            <img src={market} />
          </div>
          <div className="div1-child2">
            <Slider slidesToShow={1} {...settings}>
              <div>
                <img src={s1} />
              </div>
              <div>
                <img src={s2} />
              </div>
              <div>
                <img src={s3} />
              </div>
              <div>
                <img src={s4} />
              </div>
            </Slider>
          </div>
        </div>
        <div className="div2">
          {rendersBox()}
        </div>
      </section>
      <section className="section-three section">
        {allProducts.length > 0 ? (
          <>
            <div className="section-head">
              <h2 className="title-section"> Nos Produits <span>+{allProducts.length && allProducts.length}</span> </h2>
              <li> <a href={PRODUCT}>Voir plus</a> <FiChevronRight />  </li>
            </div>
            <div className="div-product">
              {
                allProducts.length != 0 && (
                  allProducts.map((list, index) => {
                    return <Link key={index} to={{ pathname: `/produit/details/${list.name_prod.replaceAll(" ", "-")}` }} state={list}>
                      <CardProduct2 img={`${API_URL}${list.image_prod}`} names={list.name_prod}
                        reduce={list.reduces} price={list.price_prod} list={list} />
                    </Link>;
                  })
                )
              }
            </div>
          </>
        ) : <Spinner2 />
        }
      </section>
      <CardProdShop2 title={"Nos meilleurs produits"} listProduct={allProducts} />
      <CardProdShop2 title={"Nouvaux arrivages"} listProduct={allProducts} />

      {allProducts.length > 0 ? (
        <>
          <CardSectionProd text={"Nos offres"}
            productFavorite={allProducts} categorie={"Profitez de nos offres"}
            listProducts={allProducts} />
        </>
      ) : <Spinner2 />
      }

      <section className="section-six section">
        <div className="div-section-six">
          <h3> Créer votre boutique sur FeexMarket</h3>
          <button> <a href={APP_FEEXPAY}> Créer maintenant </a> </button>
        </div>
      </section>

      {/*       <section className="section-four section">
        <div className="section-head">
          <h2 className="title-section"> Nos boutiques <span>+120382</span> </h2>
        </div>
        <div className="div-shop">
          <Slider {...settings3} slidesToShow={4}>
            {
              shop.map(({ img, names, country, code }, index) => {
                return (
                  <Link to="" key={index}>
                    <div className="card-shop">
                      <div className="img">
                        <img src={img} />
                      </div>
                      <div className="foot">
                        <i className={`flag-icon flag-icon-${code}`}></i>
                        <span> {names}, </span>&nbsp;
                        <span> {country} </span>
                      </div>
                    </div>
                  </Link>
                );
              })
            }
            <Link to="">
              <div className="card-shop">
                <div className="img">
                  <img src={shop[shop.length - 1].img} />
                </div>
                <div className="foot">
                  <i className={`flag-icon flag-icon-${shop[shop.length - 1].code}`}></i>
                  <span> {shop[shop.length - 1].names}, </span>&nbsp;
                  <span> {shop[shop.length - 1].country} </span>
                </div>
              </div>
            </Link>
          </Slider>
        </div>
      </section>
 */}
      <section className="section-seven section">
        <div className="section-head">
          <h2 className="title-section"> Nos Valeurs </h2>
        </div>
        <div className="centre-int">
          <div>
            <img src={g2} />
            <h4>Respect de nos engagements</h4>
          </div>
          <div>
            <img src={g2} />
            <h4>Sécurité des transactions</h4>
          </div>
          <div>
            <img src={g2} />
            <h4>Rapidité de livraison</h4>
          </div>
          <div>
            <img src={g2} />
            <h4>Ecoute de la clientèle</h4>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
