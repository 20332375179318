import React from "react";
import axios from "axios";
import { useEffect, useState, createContext, useContext } from "react";
import { country } from "../components/currencyList/countries";
import { API_URL, API_URL_PAYMENT, API_URL_PAYMENT_STATUS, config, LOGIN } from "../settings/constant";
import AuthContext from "./AuthContext";
import { useDispatch, useSelector } from "react-redux";
import { clearCart, removeItem } from "../store/cart/cartSlice";
import useAxios from "../utils/useAxios";

const GeneralContext = createContext();

export default GeneralContext;

export const GlobalContext = ({ children }) => {

  const order_item = useSelector((state) => state.cart.cartItem);

  const { user } = useContext(AuthContext);
  const [userData, setUserData] = useState([]);

  const useaxios = useAxios();

  const [allProducts, setAllProducts] = useState([]);
  const [whishlist, setWhishlist] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [store, setStore] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [shippingAdress, setShippingAdress] = useState([]);

  const [productSearch, setProductSearch] = useState([]);
  const [productSearchStore, setProductSearchStore] = useState([]);
  const [productStore, setProductStore] = useState([]);
  const [productTrash, setProductTrash] = useState([]);
  const [productOne, setProductOne] = useState([]);
  const [profileStore, setProfileStore] = useState([]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState([]);
  const [categoryMarket, setCategoryMarket] = useState([]);
  const [orders, setOrders] = useState([]);
  const [categorieByStore, setCategorieByStore] = useState([]);
  const [saleTrash, setSaleTrash] = useState([]);
  const [saleStore, setSaleStore] = useState([]);
  const [cartItemStore, setCartItemStore] = useState([]);
  const [cartStoreId, setCartStoreId] = useState(0);
  const [statsStore, setStatsStore] = useState([]);

  const [subCat, setSubCat] = useState([]);
  const [itemSubCat, setItemSubCat] = useState([]);

  const [location, setLocation] = useState([]);

  const [storeProfile, setStoreProfile] = useState();


  // get location of user from the API
  const getDataLocation = async () => {
    try {
      const res = await axios.get("https://geolocation-db.com/json/");

      if (res) {
        setLocation(res.data);
        sessionStorage.setItem("code_country", res.data.country_code);
        sessionStorage.setItem("country", res.data.country_name);
        sessionStorage.setItem("city", res.data.city);

        let find = country.find(
          (item) => item.countryName === res.data.country_name,
        );

        if (find) {
          sessionStorage.setItem("currency", find.currencyCode);
        }
      }
    } catch (error) {
      return error;
    }
  };

  /* get user info */
  const getUserData = async () => {
    if (user) {
      try {
        const response = await useaxios.get(`${API_URL}account/user-details/${user.id}`, config);
        if (response.status === 200) {
          setUserData(response.data);
        }
      } catch (error) {
        return error;
      }
    }
  };

  /* create cart user */
  const createCartUser = async (id_prod, color, size, quantity, isPopup, amount) => {
    try {
      const response = await axios.post(`${API_URL}shoppingCart/create`, {
        id_prod: id_prod,
        user: user.id,
        color: color,
        size: size,
        quantity: quantity,
        amount: amount
      }, config);
      if (response.status === 201 || response.status === 200) {
        isPopup(true);
      }
    } catch (error) {
      return error;
    }
  };

  /* get shoppingcart item */
  const [shoppingCartItem, setShoppingCartItem] = useState([]);
  const getShoppingCartItem = async () => {
    if (user) {
      try {
        const response = await axios.get(`${API_URL}shoppingCart/user/${user.id}`, config);
        if (response.status === 200) {
          setShoppingCartItem(response.data);
        }
      } catch (error) {
        return error;
      }
    }
  };

  /* update shoppingcart item qty */
  const updateShoppingCartItemQty = async (id_prod, color, size, action) => {
    if (user) {
      try {
        const response = await axios.put(`${API_URL}shoppingCart/update-qty`, {
          id_prod: id_prod,
          user: user.id,
          color: color,
          size: size,
          action: action
        }, config);
        if (response.status === 200) {
          getShoppingCartItem();
        }
      } catch (error) {
        return error;
      }
    }
  };

  /* delete shoppingcart item */
  const deleteShoppingCartItem = async (id_prod, color, size) => {
    if (user) {
      try {
        const response = await axios.put(`${API_URL}shoppingCart/delete`, {
          id_prod: id_prod,
          user: user.id,
          color: color,
          size: size,
        }, config);
        if (response.status === 200) {
          getShoppingCartItem();
        }
      } catch (error) {
        return error;
      }
    }
  };

  /* send email contact */
  const sendMailToStore = async (to_email, from_email, firstname, message) => {
    try {
      const response = await axios.post(`${API_URL}send/mail-to-store`, {
        from_email: from_email,
        subject: message,
        to_email: to_email,
        firstname: firstname,
        message: message
      }, config);
      if (response.status == 200) {
        alert("ok");
      }
    } catch (error) {
      return error;
    }
  };

  /* send mail for update password */
  const sendEmail = async (email, setStep, isLose) => {
    try {
      const response = await axios.get(`${API_URL}account/send-mail/${email}`, config);
      if (response.status === 200) {
        setStep(2);
      } else {
        setStep(0);
        isLose(true);
      }
    } catch (error) {
      setStep(3);
      return error;
    }
  };

  /* reset password */
  const reset_pwd = async (email, password) => {
    try {
      const response = await axios.put(`${API_URL}account/change-password`, {
        password: password,
        email: email
      }, config);
      if (response.status === 200) {
        window.location.href = LOGIN;
      }
    } catch (error) {
      return error;
    }
  };

  /* get country code */
  const get_code_country = (countrys) => {
    const finds = country.find(
      (item) => item.countryName == countrys,
    );
    if (finds) {
      return finds.countryCode.toLowerCase();
    }
  };


  /* *************************************************** */
  /* get catégorie */
  const getCategoryData = async () => {
    try {
      const response = await axios.get(`${API_URL}category/all`, config);
      if (response.status === 200) {
        setCategory(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  /* get category product */
  const [categoryProduct, setCategoryProduct] = useState([]);
  const getCategoryProduct = async () => {
    try {
      const response = await axios.get(`${API_URL}category/product`, config);
      if (response.status === 200) {
        setCategoryProduct(response.data);
      }
    } catch (error) {
      return error;
    }
  };



  /* **********************product***************************** */
  /* product and category */

  /* add produist */
  const create_product = async (name_prod, brand_prod, prod_desc, price_prod,
    image_prod, sizes, colors, store_id, subcategory, categoryId,
    msg, clearAllFields, action, id_prod, btn_send, isPopup, setMsgSucces, isDone) => {

    const formData = new FormData();
    formData.append("name_prod", name_prod);
    formData.append("desc_prod", prod_desc);
    formData.append("brand", brand_prod);
    formData.append("price_prod", parseInt(price_prod));
    formData.append("image_prod", image_prod);
    formData.append("sizes", sizes);
    formData.append("colors", colors);
    formData.append("store_id", store_id);
    formData.append("idSubCat", subcategory === null ? 0 : parseInt(subcategory));
    formData.append("idCat", parseInt(categoryId));

    isPopup(true);

    try {
      if (action === "create") {
        const response = await axios.post(`${API_URL}product/add`, formData);
        if (response.status === 201) {
          isDone(true);
          setMsgSucces("Produit ajouté avec succès !");
          clearAllFields();
        }
      } else if (action === "update") {
        formData.append("id_prod", id_prod);
        if (!image_prod) {
          formData.delete("image_prod");
        }
        const response = await axios.put(`${API_URL}product/update`, formData);
        if (response.status === 200) {
          clearAllFields();
          setMsgSucces("Produit mise à jour avec succès !");
          isDone(true);

          btn_send.innerText = "Ajoute le produit";
          sessionStorage.removeItem("product");

          window.scrollY(0);
        } else {
          isDone(true);
          setMsgSucces("Echec de la mise à jour ! Vérifer votre connexion internet !");
        }
      }
    } catch (error) {
      setMsgSucces("Opération échouer. Vérifer votre connexion internet !");
      isDone(true);
      return error;
    }
  };

  /* pusblish product to store */
  const publishProduct = async (id_prod, store_id, setMsgPublish, isPopup, isDone) => {
    if (user) {
      isPopup(true);
      try {
        const response = await axios.put(`${API_URL}product/publish/${id_prod}`, {
          store_id: store_id
        }, config);
        if (response.data.msg === "pub") {
          isDone(true);
          setMsgPublish("Produit publié avec succès !");
        } else {
          isDone(true);
          setMsgPublish("Produit supprimer des publication avec succès !");
        }
      } catch (error) {
        isDone(true);
        setMsgPublish("Opération échouer ! Vérifier votre connexion.");
        return error;
      }
    }
  };

  /* get all product */
  const getAllProduct = async () => {
    try {
      const response = await axios.get(`${API_URL}product/all`, config);
      const data = response.data;
      if (response.status == 200) {
        setAllProducts(data);
      }
    } catch (error) {
      return error;
    }
  };

  /* get product by category or subcategorie */
  const getProductByCategorie = async (idSubcat) => {
    try {
      const response = await axios.get(`${API_URL}product/by-categorie/${idSubcat}`, config);
      if (response.status === 200) {
        setAllProducts(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  /* set reduce product */
  const updateReduce = async (prod_id, reduce, isDone, setMsg) => {
    try {
      const response = await axios.put(`${API_URL}product/update-reduce/${prod_id}`, {
        reduces: reduce,
      }, config);
      if (response.status === 200) {
        isDone(true);
        setMsg("Réduction appliquée avec succès !");
      } else {
        isDone(true);
        setMsg("Réduction non appliquée avec succès ! Réessayer");
      }
    } catch (error) {
      isDone(true);
      setMsg("Vérifier votre connexion !");
      return error;
    }
  };

  /* add cobeille product */
  const deleteProduct = async (prod_id, isDone, setMsgPublish, isPopup) => {
    isPopup(true);
    try {
      const response = await axios.put(`${API_URL}product/add-trash/${prod_id}`, config);
      if (response.status === 200) {
        isDone(true);
        setMsgPublish("Produit supprimer avec succès !");
      } else {
        isDone(true);
        setMsgPublish("Echec de la suppression !");
      }
    } catch (error) {
      isDone(true);
      setMsgPublish("Opération échouer ! Vérifier votre connexion.");
      return error;
    }
  };

  /* restore product of cobeille */
  const restoreProduct = async (prod_id, isDone, setMsgPublish, isPopup) => {
    isPopup(true);
    try {
      const response = await axios.put(`${API_URL}product/restore/${prod_id}`, config);
      if (response.status === 200) {
        isDone(true);
        setMsgPublish("Produit restauré avec succès !");
      } else {
        isDone(true);
        setMsgPublish("Echec de la restauration du produit !");
      }
    } catch (error) {
      isDone(true);
      setMsgPublish("Opération échouer ! Vérifier votre connexion.");
      return error;
    }
  };

  /* delete definitively product */
  const deleteDefinitiveProduct = async (prod_id, isDone, setMsgPublish, isPopup) => {
    isPopup(true);
    try {
      const response = await axios.delete(`${API_URL}product/delete/${prod_id}`, config);
      if (response.status === 200) {
        isDone(true);
        setMsgPublish("Produit supprimer avec succès !");
      } else {
        isDone(true);
        setMsgPublish("Echec de la suppression ! Veuillez Réessayer");
      }
    } catch (error) {
      isDone(true);
      setMsgPublish("Opération échouer ! Vérifier votre connexion");
      return error;
    }
  };

  /* get filter product */
  const getFilterProductMarket = async (category, subcat) => {
    try {
      const response = await axios.post(`${API_URL}product/get-filter-product-market`, {
        category: category,
        subcat: subcat,
      }, config);
      if (response.status === 200) {
        setAllProducts(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  /* search product */
  const getProductSearch = async (keyword) => {
    try {
      const response = await axios.get(`${API_URL}product/search/${keyword}`, config);
      if (response.status === 200) {
        setProductSearch(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  /* search product */
  const getProductSearchStore = async (store_id, keyword) => {
    try {
      const response = await axios.post(`${API_URL}product/store-search`, {
        keyword: keyword,
        store_id: store_id,
      }, config);
      if (response.status === 200) {
        setProductSearchStore(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  /*  get favoris user */
  const getWhishlist = async () => {
    if (user) {
      try {
        const response = await axios.get(`${API_URL}user/get-whishlist/${user.id}`);
        if (response.status === 200) {
          setWhishlist(response.data);
        }
      } catch (error) {
        return error;
      }
    }
  };

  /* add favori */
  const add_favoris = async (id_prod, classe, index, activebtn) => {
    let all = document.querySelectorAll(classe);
    if (user) {
      try {
        await axios.post(`${API_URL}product/add-whishlist`, {
          users: user.id,
          product: id_prod,
        }, config).then((response) => {
          if (response.status === 201) {
            getWhishlist();
            all[index].classList.add("active-whishlist-btn");
          } else if (response.status === 200) {
            getWhishlist();
            all[index].classList.remove("active-whishlist-btn");
          }
        });
      } catch (error) {
        return error;
      }
    } else {
      window.location.href = LOGIN;
    }
  };

  /* save reviews */
  const save_comment = async (comment, nbEtoile, id_prod, id_msg, isPopup, isDone, setMsgText) => {
    const msg = document.getElementById(id_msg);
    if (user.id) {
      if (comment) {
        if (nbEtoile) {
          isPopup(true);
          try {
            const response = await axios.post(`${API_URL}product/save-reviews`, {
              user: user.id,
              id_prod: id_prod,
              comment: comment,
              ratings: nbEtoile
            });
            if (response.status == 201) {
              isDone(true);
              setMsgText("Commentaire envoyer avec succès !");
            } else {
              isPopup(false);
              msg.innerText = "Vous avez déjà donner votre produit sur ce produit..";
            }
          } catch (error) {
            isDone(true);
            setMsgText("Opération échoué ! Vérifier votre connexion.");
            return error;
          }
        } else {
          msg.innerText = "Noter cette produit par le nombre d'étoile attribué";
        }
      } else {
        msg.innerText = "Entré votre avis et noter ce produit";
      }
    } else {
      msg.innerText = "Connectez-vous !!!";
    }
  };

  /* get sold count by product */
  const [countSoldProd, setCountSoldProd] = useState(0);
  const getCountSold = async (prod_id) => {
    try {
      const response = await axios.get(`${API_URL}product/get-sold-count/${prod_id}`, config);
      if (response.status == 200) {
        setCountSoldProd(response.data.count_sold);
      }
    } catch (error) {
      return error;
    }
  };

  /* *************************** action adress *************************/

  /* get data */
  const getShippingAdress = async () => {
    try {
      const response = await axios.get(`${API_URL}shippingAdress/list/${user.id}`);
      const data = response.data;
      if (response.status == 200) {
        setShippingAdress(data);
      }
    } catch (error) {
      return error;
    }
  };

  /* add adresse */
  const createShippingAdress = async (fullname, country, department,
    city, telephone, clear, msg_text) => {
    try {
      const response = await axios.post(`${API_URL}shippingAddress/create`, {
        fullname: fullname,
        country: country,
        department: department,
        city: city,
        telephone: telephone,
        id: user.id,
      }, config);
      if (response.status === 201) {
        msg_text.innerText = "Adresse ajouté avec succès !";
        clear();
        getShippingAdress();
        window.location.reload();
      }
    } catch (error) {
      return error;
    }
  };

  const updateShippingAdress = async (id, fullname, country, department,
    city, telephone, clear, msg_text) => {
    try {
      const response = await axios.put(`${API_URL}shippingAdress/update/${id}`, {
        fullname: fullname,
        country: country,
        department: department,
        city: city,
        telephone: telephone,
      }, config);
      if (response.status == 200) {
        msg_text.innerText = "Adresse mise à jour avec succès !";
        clear();
        getShippingAdress();
        window.location.reload();
      }
    } catch (error) {
      return error;
    }
  };

  /* delete adress */
  const delete_adress = async (index) => {
    try {
      const response = await axios.delete(`${API_URL}adresse/update/${index}`);
      if (response.status == 200) {
        getShippingAdress();
      }
    } catch (error) {
      return error;
    }
  };

  /* sale */
  /* add sale */
  const save_sale = async (shippingAdress, payment_method, amount, phone,
    shoppingCartItem, isPopup5, isDone, isPopup6, isPopup7,
    isPopup8) => {
    if (user) {
      isPopup5(true);
      const date = new Date();
      let localDate = date.toLocaleString();
      try {
        let response = await axios.post(`${API_URL}order/create`, {
          id: user.id,
          id_addr: shippingAdress,
          payment_method: payment_method,
          orderitem: shoppingCartItem,
          total_price: amount,
          date: localDate
        }, config);
        console.log(response);
        if (response.status === 201) {
          payment(phone, amount, payment_method, response.data.order_id,
            response.data.createdAt, shippingAdress,
            shoppingCartItem, isDone, isPopup7, isPopup5, isPopup8);
        } else {
          isPopup5(false);
          isPopup6(true);
        }
      } catch (error) {
        return error;
      }
    } else {
      window.location.href = "/login";
    }
  };

  /* send facture */
  const sendInvoice = async (order, date, shippingAdress, isDone, isPopup5, isPopup8) => {
    try {
      const response = await axios.post(`${API_URL}order/send-invoice`, {
        order: order,
        date: date,
        shippingAdress: shippingAdress,
      }, config);
      if (response.status === 200) {
        isDone(true);
        destroyShoppingCart(user.id);
      } else {
        isPopup5(false);
        isPopup8(true);
      }
    } catch (error) {
      isPopup8(true);
      isPopup5(false);
      return error;
    }
  };

  // destroy cart shopping
  const destroyShoppingCart = async (id) => {
    try {
      const response = await axios.delete(`${API_URL}shoppingCart/delete/${id}`, config);
    } catch (error) {
      return error;
    }
  };

  const DeleteOrder = async (order) => {
    try {
      await axios.delete(`${API_URL}order/delete/${order}`);
    } catch (error) {
      return error;
    }
  };

  /* send payment */
  const payment = async (phone, amount, payment_method, order_id,
    createdAt, shippingAdress, shoppingCartItem, isDone, isPopup7,
    isPopup5, isPopup8) => {

    let timer = 0;
    let storeIds = shoppingCartItem[0].Product.Store.store_id;
    /*   for (let i = 0; i < shoppingCartItem.length; i++) {
        storeIds += " " + shoppingCartItem[i].Product.Store.store_id;
      } */

    try {
      const response = await axios.post(API_URL_PAYMENT, {
        phoneNumber: phone,
        amount: amount,
        reseau: payment_method,
        shop: storeIds,
        firstname: userData.firstname + " " + userData.lastname,
        email: user.email
      });

      let status_one = response.data.status;
      let ref_post = response.data.reference;

      if (status_one === "SUCCESSFUL" || status_one === "SUCCESS") {
        sendInvoice(order_id, createdAt, shippingAdress, isDone, isPopup5, isPopup8);
      } else if (status_one === "FAILED") {//echec
        isPopup7(true);
        isPopup5(false);
        DeleteOrder(order_id);
      } else {
        let interval = setInterval(async () => {
          const response2 = await axios.get(`${API_URL_PAYMENT_STATUS}${ref_post}`);

          let statut_get = response2.data.status;
          if (statut_get === "PENDING" || statut_get === "IN PENDING STATE") {//echec
            timer++;
            if (timer === 37) {
              clearInterval(interval);
              isPopup7(true);
              isPopup5(false);
              DeleteOrder(order_id);
            }
          } else if (statut_get === "SUCCESSFUL" || statut_get === "SUCCESS") {
            sendInvoice(order_id, createdAt, shippingAdress, isDone, isPopup5, isPopup8);
            clearInterval(interval);
          } else {//echec
            isPopup5(false);
            isPopup7(true);
            DeleteOrder(order_id);
            clearInterval(interval);
          }
        }, 7000);
      }
    } catch (error) {//echec
      isPopup5(false);
      isPopup7(true);
      DeleteOrder(order_id);
      return error;
    }
  };


  /* ******************* store ********************* */
  /* get store for users */
  const getStore = async () => {
    if (user) {
      let id = user.id;
      try {
        const response = await axios.get(`${API_URL}user/stores/${id}`, config);
        const data = response.data;
        if (response.status === 200) {
          setStore(data.stores);
        }
      } catch (error) {
        return error;
      }
    }
  };

  // get categorie by store
  const getCategoriesByStore = async (store) => {
    try {
      const response = await axios.get(`${API_URL}categorie/by-store/${store}`, config);
      if (response.status === 200) {
        setCategorieByStore(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  /* update status store select */
  const update_status_store = async (storeId, setLink) => {
    if (user) {
      try {
        const response = await axios.put(`${API_URL}store/update-status`, {
          store_id: storeId,
          user_id: user.id
        }, config);
        if (response.status === 200) {
          let data = response.data;
          let origin = window.location.origin;
          for (var i = 0; i < data.length; i++) {
            if (data[i].is_active === true) {
              setLink(`${origin}/Tableau-de-bord/${data[0].name_store}`);
            }
          }
        }
      } catch (error) {
        return error;
      }
    }
  };

  /* get product to store */
  const getProductToStore = async (store_id) => {
    try {
      const response = await axios.get(`${API_URL}product/store/${store_id}`, config);
      let data = response.data;
      if (response.status === 200) {
        setProductStore(data.products);
      }
    } catch (error) {
      return error;
    }
  };

  // get product by categorie for store 
  const getProductByCategoriesToStore = async (storeId, idCat) => {
    try {
      const response = await axios.post(`${API_URL}product/store/by-categorie`, {
        idCat: idCat,
        store_id: storeId
      }, config);
      console.log(response);
      if (response.status === 200) {
        setProductStore(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  // get product in promotion by store
  const [productPromoByStore, setProductPromoByStore] = useState([]);
  const getProductPromoByStore = async (store_id) => {
    try {
      const response = await axios.get(`${API_URL}product/promo-store/${store_id}`, config);
      if (response.status === 200) {
        setProductPromoByStore(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  /* get product cobeille */
  const getProductTrash = async (storeId) => {
    try {
      const response = await axios.get(`${API_URL}product/trash-list/${storeId}`, config);
      if (response.status === 200) {
        setProductTrash(response.data.products);
      }
    } catch (error) {
      return error;
    }
  };

  /* get profile store */
  const getProfileStore = async (store_id) => {
    try {
      const response = await axios.get(`${API_URL}store/profile/${store_id}`, config);
      if (response.status === 200) {
        setProfileStore(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  /* get store site */
  const getStoreProfile = async (store_id) => {
    try {
      const response = await axios.get(`${API_URL}store/profile/${store_id}`, config);
      console.log(response.data);
      if (response.status === 200) {
        setStoreProfile(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  /* get order by user */
  const getOrderByUser = async () => {
    if (user) {
      try {
        const response = await axios.get(`${API_URL}order/get/${user.id}`, config);
        if (response.status === 200) {
          setOrders(response.data);
        }
      } catch (error) {
        return error;
      }
    }
  };

  /* get stats store */
  const getStatsStore = async () => {
    if (user) {
      try {
        const response = await axios.get(`${API_URL}store/details/${user.id}`, config);
        const data = response.data;
        if (response.status === 200) {
          console.log(data);
          setStatsStore(data);
        }
      } catch (error) {
        return error;
      }
    }
  };

  /* get sale store */
  const getSaleStore = async (store_id) => {
    if (user) {
      try {
        const response = await axios.get(`${API_URL}store/order-sale/${store_id}`, config);
        if (response.status === 200) {
          setSaleStore(response.data);
        }
      } catch (error) {
        return error;
      }
    }
  };

  /* get sale trash */
  const getSaleTrashStore = async (store_id) => {
    if (user) {
      try {
        const response = await axios.get(`${API_URL}store/get-sale-trash/${store_id}`, config);
        if (response.status === 200) {
          setSaleTrash(response.data);
        }
      } catch (error) {
        return error;
      }
    }
  };

  /*  save abonnement store */
  const saveSubscribe = async (store_id, subscribe, isPopup2) => {
    if (user) {
      try {
        const response = await axios.put(`${API_URL}store/subcription`, {
          store_id: store_id,
          subscribe: subscribe,
        }, config);
        if (response.status === 200) {
          isPopup2(true);
        }
      } catch (error) {
        return error;
      }
    }
  };

  // update status sale
  const updateSaleStatus = async (order_id, status_delivr, dateDelivr) => {
    try {
      const response = await axios.put(`${API_URL}order/sale-update/${order_id}`, {
        status_delivr: status_delivr,
        date_delivr: status_delivr ? dateDelivr : null
      }, config);
      if (response.status == 200) {
        window.location.reload();
      }
    } catch (error) {
      return error;
    }
  };

  // delete order sale store
  const deleteSaleStore = async (order_id) => {
    try {
      const response = await axios.delete(`${API_URL}order/sale-add-trash/${order_id}`, config);
      if (response.status == 200) {
        window.location.reload();
      }
    } catch (error) {
      return error;
    }
  };

  /*  add item to cart store */
  const dispatch = useDispatch();
  const add_cart_store_item = async (store_id, isPopup) => {
    if (user) {
      try {
        const response = await axios.post(`${API_URL}store/create-cart`, {
          cart_item: order_item,
          store_id: store_id,
        }, config);
        if (response.status === 201) {
          alert("Panier créer avec succès ! Cliquer sur 'Générer le lien' pour générer le lien d'accès au panier.");
          setCartStoreId(response.data.token);
          dispatch(clearCart());
          sessionStorage.setItem("cartStoreId", response.data.token);
        } else {
          alert("Le panier a été déjà créé");
        }
      } catch (error) {
        return error;
      }
    }
  };

  /* delete cart store */
  const delete_cart_store = async (store_id, isShowDiv) => {
    if (user) {
      try {
        const response = await axios.delete(`${API_URL}store/delete-cart/${store_id}`, config);
        if (response.status == 200) {
          alert("Panier supprimer avec succès");
          isShowDiv(false);
          sessionStorage.removeItem("cartStoreId");
        }
      } catch (error) {
        return error;
      }
    }
  };

  /* get cart store item */
  const getCartStoreItem = async (cart_id) => {
    try {
      const response = await axios.post(`${API_URL}store/get-item-cart/${cart_id}`, config);
      if (response.status === 200) {
        console.log(response);
        setCartItemStore(response.data);
      }
    } catch (error) {
      return error;
    }
  };

  // save followers
  const saveFollowers = async (store_id) => {
    if (user) {
      try {
        const response = await axios.post(`${API_URL}store/save-followers`, {
          id: user.id,
          store_id: store_id
        }, config);
      } catch (error) {
        return error;
      }
    } else {
      window.location.href = LOGIN;
    }
  };


  useEffect(() => {
    getUserData();
    getDataLocation();
    getStore();
    getAllProduct();
    getWhishlist();
    getShippingAdress();
    getCategoryData();
    getOrderByUser();
    getShoppingCartItem();
  }, []);

  const contextData = {
    sendMailToStore,
    /* location */
    location,
    payment,
    sendEmail,
    reset_pwd,

    /* user */
    userData,
    createCartUser,
    shoppingCartItem,
    updateShoppingCartItemQty,
    deleteShoppingCartItem,

    /* product */
    allProducts,
    productOne,
    reviews,
    store,
    shippingAdress,
    answers,
    whishlist,
    add_favoris,
    get_code_country,

    /* comment of product */
    save_comment,

    /* message */
    getStore,

    /* adress */
    getShippingAdress,
    createShippingAdress,
    updateShippingAdress,
    delete_adress,

    /* order */
    save_sale,
    updateSaleStatus,
    deleteSaleStore,

    /* store part */
    update_status_store,
    getProductSearch,
    getProductToStore,
    getProductByCategoriesToStore,
    getProfileStore,
    getProductTrash,
    updateReduce,
    deleteProduct,
    deleteDefinitiveProduct,
    restoreProduct,
    getStoreProfile,
    getCategoriesByStore,
    getProductByCategorie,
    getProductPromoByStore,
    getFilterProductMarket,
    getProductSearchStore,
    getCountSold,
    saveSubscribe,
    publishProduct,
    getCartStoreItem,
    delete_cart_store,
    cartStoreId,
    cartItemStore,
    productPromoByStore,
    categoryProduct,
    getCategoryProduct,
    countSoldProd,
    productSearchStore,
    orders,
    categoryMarket,
    categorieByStore,
    storeProfile,
    category,
    itemSubCat,
    subCat,
    categories,
    productStore,
    productTrash,
    profileStore,
    productSearch,
    add_cart_store_item,
    saveFollowers,

    /* dashboard */
    create_product,
    getStatsStore,
    getSaleStore,
    getSaleTrashStore,
    saleTrash,
    saleStore,
    statsStore,
  };

  return (
    <GeneralContext.Provider value={contextData}>
      {children}
    </GeneralContext.Provider>
  );
};
