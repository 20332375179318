import React, { useEffect, useState, useContext } from "react";
import { FiChevronRight, FiChevronsRight } from "react-icons/fi";
import "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";

import "flag-icon-css/css/flag-icons.min.css";
import "./Product.scss";

import BoxCategorie from "../../../components/boxCategorie/BoxCategorie";
import Pagination from "../../../components/pagination/Pagination";
import CardProducts from "../../../components/cardProducts/CardProducts";
import GeneralContext from "../../../context/GlobalContext";
import { Spinner2 } from "../../../components/spinner2/Spinner2";

const Product = (props) => {
  const location = useLocation();

  const [nb, setNb] = useState(8);
  const [nextPage, setNextPage] = useState(nb);
  const [prevPage, setPrevPage] = useState(0);

  const data = location.state;

  const [nameCat, setNameCat] = useState();
  const [prodName, setNameProd] = useState();

  const { allProducts, getProductByCategorie } = useContext(GeneralContext);
  const [category, setCategory] = useState(null);
  const [subcategorie, setSubCategorie] = useState(null);


  useEffect(() => {
    let search = decodeURIComponent(window.location.search.substring(1));
   
    let splitSearch = search.split("$");
    if (splitSearch.length > 1) {
      setCategory(splitSearch[0].split("=")[1]);
      setSubCategorie(splitSearch[1].split("=")[1].split("/")[0]);
      sessionStorage.setItem("idSubCat", splitSearch[1].split("=")[1].split("/")[1]);
 
      let idSubCat = splitSearch[1].split("=")[1].split("/")[1];
      getProductByCategorie(idSubCat);
    } else {
      setCategory(search.split("=")[1]);
      setSubCategorie(null);
    }
  }, [data, prodName, category, subcategorie]);


  return (
    <div className="product-page">
      <div className="head-page">
        <div>
          <span>
            Catégories
          </span>
          <FiChevronRight />
          <span>
            {category ? category : null}
          </span>
          {category ? <FiChevronsRight /> : null}
          <span>
            {subcategorie ? subcategorie : null}
          </span>
          {subcategorie ? <FiChevronsRight /> : null}
        </div>
      </div>
      {allProducts.length != 0 ?
        (
          <>
            <div className="box">
              <BoxCategorie setNameCat={setNameCat}
                setNameProd={setNameProd} setCategory={setCategory}
                setSubCategorie={setSubCategorie} />
              <div className="content-product">
                <div className="head">
                  <button className='all-product btn-filter' onClick={() => window.location.reload()}>Tout catégories</button>
                  <button className="btn-filter"> Meilleurs produits </button>
                  <button className="btn-filter"> Les plus vendus </button>
                </div>
                <div className="box-product">
                  <div className="head-prod">
                    <span></span>
                    <span></span>
                  </div>
                  {allProducts.length != 0 ? <div className="div-products"> <CardProducts
                    listProduct={allProducts} nextPage={nextPage}
                    prevPage={prevPage} category={category} /> </div> :
                    <Spinner2 />
                  }
                </div>

                <Pagination nextPage={nextPage}
                  prevPage={prevPage}
                  nbProd={nb}
                  countProd={allProducts ? allProducts.length : null}
                  setNextPage={setNextPage}
                  setPrevPage={setPrevPage} />

              </div>
            </div>
          </>
        ) : <Spinner2 />
      }
    </div>
  );
};

export default Product;
