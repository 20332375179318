import React, { useState, useEffect, useContext } from "react";
import "flag-icon-css/css/flag-icons.min.css";
import axios from "axios";
import { FiChevronDown } from "react-icons/fi";

import "../Header.scss";
import DropCategorie from "../../dropCategorie/DropCategorie";
import { country } from "../../currencyList/countries";
import { PROMO_PAGE } from "../../../settings/constant";
import GeneralContext from "../../../context/GlobalContext";
import SelectCountryField from "../../selectCountryField/SelectCountryField";

export const NavBarHome = () => {

  const { categories, location } = useContext(GeneralContext);

  const [codeCountry, setCodeCountry] = useState();
  const [countrys, setCountrys] = useState(location.country_name);
  const [current, setCurrent] = useState();
  const [city, setCity] = useState();

  const showCategorie = () => {
    const drop = document.querySelector(".head-div3");

    if (drop.style.width == "0vh") {
      drop.style.width = "100%";
    } else {
      drop.style.width = "0vh";
    }
  };

  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");

    const countri = res.data.country_name;
    const currency = country.find(
      (item) => item.countryName == countri,
    );

    setCurrent(currency.currencyCode);
    setCodeCountry(res.data.country_code.toLowerCase());
    setCountrys(res.data.country_name);
    setCity(res.data.city);
  };

  useEffect(() => {
    // passing getData method to the lifecycle method
    getData();
  }, [categories]);

  return (
    <>
      <div className="head-div3">
        <div className="common-div">
          <span onClick={showCategorie} className="close-x">&times;</span>
          <div className="div-left">
            <DropCategorie link={"/categories/produits/"} />
            <li>
              <a href={PROMO_PAGE} className="link"> Profiter de nos offres </a>
            </li>
          </div>
        </div>
      </div>
    </>
  );
};
