import React, { useContext, useState } from "react";
import GeneralContext from "../../context/GlobalContext";

import "./ChatBotWhatsapp.scss";


const ChatBotWhatsapp = ({ name_store, store_id, msg, chat, isChat, phone}) => {
  const [message, setMessage] = useState();
  const [done, isDone] = useState(false);

  const handleChange = (e) => {
    setMessage(e.target.value);
    isDone(false);
  };

  const send = async (e) => {
    e.preventDefault();
    const msg = document.getElementById("error-msg");

    if (!message) {
      window.location.href = "https://api.whatsapp.com/send/?phone=67905146&text=&type=phone_number&app_absent=0";
      window.location.target = "blank";
    } else {
      msg.innerText = "Entré le message...";
    }
  };


  return (
    <div className="chat-bot-whatsapp"
      style={chat ? { display: "block" } : { display: "none" }}>
      <span className="close"
        onClick={() => isChat(false)}> &times;</span>
      <h4>
                Discuter avec {name_store}
      </h4>
      <div className="field">
        <li>
          <a href={`https://api.whatsapp.com/send/?phone=${phone.replace("+","")}&text=&type=phone_number&app_absent=0`} target='_blank' rel="noreferrer">
                        Commencer la discussion
          </a>
        </li>
      </div>
    </div>
  );
};

export default ChatBotWhatsapp;
