import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";

import { CART_SHOPPING, CHECKOUT } from "../../settings/constant";
import Auth from "../authForms/Auth";

import "./SummaryOrder.scss";
import GeneralContext from "../../context/GlobalContext";

export const SummaryOrder = ({ setPrice, handleValid }) => {
  const [page, setPage] = useState();

  const { user } = useContext(AuthContext);
  const { shoppingCartItem } = useContext(GeneralContext);
  const cart = useSelector((state) => state.cart.cartItem);

  const [openAuth, setOpenAuth] = useState(false);
  const [openForm, setOpenForm] = useState(0);

  const openForms = (form, auth) => {
    setOpenForm(form);
    setOpenAuth(auth);
  };

  const getTotal = () => {
    let qtys = 0;
    let prices = 0;

    shoppingCartItem.forEach((item) => {
      qtys += item.quantity;
      prices += item.quantity * item.Product.price_prod;
    });
    setPrice(prices);
    return { qtys, prices };
  };

  useEffect(() => {
    const path = window.location.pathname.split("/")[1];

    if (path == CART_SHOPPING.split("/")[1]) {
      setPage(1);
    } else if (path == CHECKOUT.split("/")[1]) {
      setPage(2);
    } else {
      setPage(0);
    }
  });

  const renderBtn = () => {
    if (page == 1) {
      return <button onClick={() => {
        if (user) {
          window.location.href = CHECKOUT;
        } else {
          alert("no");
          openForms(1, true);
        }
      }}> Commander </button>;
    } else if (page == 2) {
      return <button onClick={handleValid}>Valider l'achat </button>;
    }
  };

  useEffect(() => {
  }, []);

  return (
    <div className="order-cart">
      {openAuth ? <Auth formAuth={openForm} openAuth={setOpenAuth} /> : ""}
      <h2> &nbsp;&nbsp;Sommaire</h2>
      <div className="infos-order">
        <p>
          <sapn>Prix Total des articles:</sapn> <input type="text" value={getTotal().prices} />
        </p>
        {/*         <p> Frais total livraison: <b>0 fcfa</b> </p>
 */}        <p> <span>Grand Total:</span> <input type="text" value={getTotal().prices} /></p>
        <p>
          {renderBtn()}
        </p>
      </div>
      <p className='end-p'> Votre paiement est sécurisé par FeexPay</p>
    </div>
  );
};
