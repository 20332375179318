import React, { useContext, useEffect, useState } from "react";

import "./CartShopping.scss";

import Cart from "../../../components/cart/Cart";
import { SummaryOrder } from "../../../components/summaryOrder/SummaryOrder";
import GeneralContext from "../../../context/GlobalContext";

const CartShopping = () => {

  const { shoppingCartItem, updateShoppingCartItemQty,
    deleteShoppingCartItem } = useContext(GeneralContext);

  const [price, setPrice] = useState();


  useEffect(() => {
  }, [shoppingCartItem]);

  return (
    <div className="cart-page">
      <div className="div-carts">
        <Cart para={"products présent"} shoppingCartItem={shoppingCartItem}
          updateShoppingCartItemQty={updateShoppingCartItemQty}
          deleteShoppingCartItem={deleteShoppingCartItem} />
      </div>
      {
        shoppingCartItem.length != 0 ? (
          <SummaryOrder setPrice={setPrice} />
        ) : ""
      }
    </div>
  );
};

export default CartShopping;
