import React, { useContext, useEffect, useState } from "react";
import CardProducts from "../../../components/cardProducts/CardProducts";
import { Spinner2 } from "../../../components/spinner2/Spinner2";
import GeneralContext from "../../../context/GlobalContext";

import "./ShopSearchPage.scss";


const ShopSearchPage = () => {
  const [nb, setNb] = useState(8);
  const [nextPage, setNextPage] = useState(nb);
  const [prevPage, setPrevPage] = useState(0);

  const [keyword, setKeyword] = useState("");

  const { productSearchStore, getProductSearchStore } = useContext(GeneralContext);


  const getProductSearchs = () => {
    let path = window.location.pathname.split("/");
    let id = path[2];
    let wd = window.location.search.substring(1);
    setKeyword(wd);
    getProductSearchStore(id, wd);
  };

  useEffect(() => {
    getProductSearchs();
  }, []);

  return (
    <div className="search-page">
      <h1>{
        productSearchStore.length != 0 ?
          `Résultat des recherches pour <<${keyword}>>` :
          `Aucun résultat pour <<${keyword}>>`
      }
      </h1>
      <div className="products">
        {productSearchStore.lenght != 0 ? <CardProducts
          listProduct={productSearchStore} nextPage={nextPage}
          prevPage={prevPage} /> :
          <Spinner2 />
        }
      </div>
    </div>
  );
};

export default ShopSearchPage;