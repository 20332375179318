import React, { useContext, useState } from "react";
import AuthContext from "../../../context/AuthContext";
import { validInputText } from "../../controlFields/ControlField";

import "../auth.scss";
import "../../../pages/site/startHomeStore/start.scss";
import { HOME, RESETFORM, RESETPWD } from "../../../settings/constant";

const Login = ({ setForm }) => {
  const { loginUser } = useContext(AuthContext);
  const [done, isDone] = useState(false);
  const [value, setValue] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    validInputText(value.email, "email-error", "email");
    validInputText(value.password, "pwd-error", "pwd");

    if (validInputText(value.email, "email-error", "email")) {
      const email = value.email;
      const password = value.password;
      loginUser(email, password, HOME, isDone);
    }
  };

  return (
    <div className="login-block">
      <form className="form-login form-login-register">
        <h2>Connectez-vous</h2>
        {done ? <p className="msg-text"> Identifiant erronée ! </p> : null}
        <p >
          <input type="email" placeholder="Entrer votre e-mail..."
            name="email"
            value={value.email}
            onChange={handleChange}
            onKeyUp={() => validInputText(value.email, "email-error", "email")} />
          <span style={{
            color: "var(--orange)",
            fontSize: "13px"
          }} id="email-error"></span>
        </p>
        <p>
          <input type="password" placeholder="Créer votre mot de passe..."
            name="password"
            id="password"
            value={value.password}
            onChange={handleChange} />
          <span style={{
            color: "var(--orange)",
            fontSize: "13px"
          }} id="pwd-error"></span>
          <a href={RESETFORM}>Mot de passe oublier ?</a>
        </p>
        <button type="submit" onClick={handleSubmit}>Connexion</button>
      </form>
    </div>
  );
};

export default Login;
