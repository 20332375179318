import React, { useEffect } from "react";
import { BsShop } from "react-icons/bs";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { FiTrash } from "react-icons/fi";
import { HiOutlineShoppingCart } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { API_URL, CART_SHOPPING } from "../../settings/constant";

import "./Cart.scss";

const Cart = ({ para, shoppingCartItem, updateShoppingCartItemQty, deleteShoppingCartItem }) => {

  const openCartShopping = () => {
    window.location.href = CART_SHOPPING;
  };

  useEffect(() => {
    /* get shopping cart item */
  }, [shoppingCartItem]);

  return (
    <div className="box-cart" id="box-cart">
      {
        shoppingCartItem.length === 0 ? (
          <p className="cart-empty">
            <HiOutlineShoppingCart />
            Votre est panier est vide
          </p>
        ) : (
          <>
            <h2>
              <span>Mon Panier ({shoppingCartItem.length}) </span>
            </h2>
            <div className="bloc-prod">
              {
                shoppingCartItem.map((list, index) => {
                  let prod_id = list.id_prod;
                  return <div className="items" key={index}>
                    <div className="shop-name">
                      <BsShop />
                      <span> {list.Product.Store.name_store}</span>
                    </div>
                    <div className="bls">
                      <img src={`${API_URL}${list.Product.image_prod}`} />
                      <div className="info">
                        <h4> {list.Product.name_prod} </h4>
                        <b className="price"> {list.Product.price_prod} <sup>CFA</sup> </b>
                        {
                          list.colors && (
                            <p className="color">
                              Couleur: <b>{list.colors}</b>
                            </p>
                          )
                        }
                        {
                          list.sizes && (
                            <p className="size">
                              Taille: <b> {list.sizes} </b>
                            </p>
                          )
                        }
                        <div className="quantity-x">
                          <span className="label-qty"> Quantity :</span> &nbsp;
                          <div>
                            <button onClick={() => updateShoppingCartItemQty(list.id_prod, list.colors, list.sizes, "decrement")}>
                              <AiOutlineMinus /> </button>
                            <span> <input type="text" value={list.quantity} /> </span>
                            <button onClick={() => updateShoppingCartItemQty(list.id_prod, list.colors, list.sizes, "increment")}> <AiOutlinePlus /> </button>
                          </div>
                        </div>
                        <button className="btn-trash" onClick={() => deleteShoppingCartItem(list.id_prod, list.colors, list.sizes)}> <FiTrash /> <span>Supprimer</span> </button>
                      </div>
                    </div>
                  </div>;
                })
              }
            </div>
            <button className="btn-open-cart"
              onClick={openCartShopping}> Ouvrir le panier </button>
          </>
        )
      }
    </div>
  );
};

export default Cart;
