import React from "react";
import FooterCartCheckout from "../../components/footer/footers/footerCartCheckout";
import HeaderAuth from "../../components/header/headers/headerAuth";

import "./LayoutHome.scss";

const LayoutAuth = ({children}) => {
  return (
    <>
      <HeaderAuth />
      <div className="body-page">
        {children}
      </div>
      <FooterCartCheckout />
    </>
  );
};

export default LayoutAuth;
