import React, { useContext, useState } from "react";
import { FiSearch } from "react-icons/fi";
import GeneralContext from "../../../context/GlobalContext";
import { SEARCH_PRODUCT_PAGE, SEARCH_PRODUCT_PAGE_STORE } from "../../../settings/constant";

import "../Header.scss";

export const SearchBarShop = () => {
  const [keyword, setKeyword] = useState("");

  const searchWord = e => {
    e.preventDefault();

    let path = window.location.pathname.split("/");
    let id = path[2];
    let name = path[1];

    if (keyword) {
      let words = keyword.replaceAll(" ", "+",0);
      window.location.href=`/${name}/${id}/produits-rechercher/produit/?${words}`;
    }
  };

  return (
    <>
      <form className="search-bar-shop form-search">
        <input type="text"
          placeholder="Rechercher vos produits ici..."
          value={keyword}
          onChange={e => setKeyword(e.target.value)} />
        <button onClick={searchWord}> <FiSearch /> </button>
      </form>
    </>
  );
};
