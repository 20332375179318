import React, { useContext, useEffect, useState } from "react";
import Select from "../../../components/select/Select";
import GeneralContext from "../../../context/GlobalContext";
import { API_URL } from "../../../settings/constant";

import "./Order.scss";

const Order = () => {

  const { orders } = useContext(GeneralContext);
  const [filterWord, setFilterWord] = useState("");

  const options = [
    { label: "En attente", value: "" },
    { label: "Delivré", value: "" }
  ];

  useEffect(() => {
    console.log(orders);
  }, []);

  return (
    <div className="order-dashboard-buyer">
      <div className="orders">
        <h3> Mes commandes ({orders.length > 0 ? orders.length : null}) </h3>
        <div className='sort-div'>
          <Select defaultText="Trier par" listOption={options} setFilterWord={setFilterWord} />
        </div>
        <div className="table">
          <table className="table-data">
            <thead>
              <tr>
                <th> Vendeur </th>
                <th> Photo produit</th>
                <th> Products </th>
                <th> Quantité </th>
                <th> Prix Total (XOF) </th>
                <th> Status paiement </th>
                <th> Status livraison </th>
              </tr>
            </thead>
            <tbody>
              {
                orders.length > 0 && (
                  orders.map((list, index) => {
                    if (filterWord) {
                      if ((list.is_delivered ? "Delivré" : "En attente") === filterWord) {
                        return <tr key={index}>
                          <td> {list.Product.Store.name_store} </td>
                          <td> <img src={`${API_URL}${list.Product.image_prod}`} /> </td>
                          <td> {list.Product.name_prod} </td>
                          <td> {list.qty} </td>
                          <td> {list.amount} </td>
                          <td>  {list.is_paid ? "Payé":"Non payé"}  </td>
                          <td> {list.is_delivered ? "Delivré" : "En attente"} </td>
                        </tr>;
                      }
                    } else {
                      return <tr key={index}>
                        <td> {list.Product.Store.name_store} </td>
                        <td> <img src={`${API_URL}${list.Product.image_prod}`} /> </td>
                        <td> {list.Product.name_prod} </td>
                        <td> {list.qty} </td>
                        <td> {list.amount} </td>
                        <td>  {list.is_paid ? "Payé":"Non payé"}  </td>
                        <td> {list.is_delivered ? "livré" : "En attente"} </td>
                      </tr>;
                    }
                  })
                )
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Order;
