/* All path to page */

export const APP_FEEXPAY = "https://feexpay.me";

export const LOGIN = "/connexion";
export const RESETPWD = "/reinitialiser-votre-mot-de-passe/reset?";
export const RESETFORM = "/reinitialisation";
export const HOME = "/";
export const PRODUCT = "/categories/produits";
export const SEARCH_PRODUCT_PAGE = "/produits/recherches/produit?";
export const CART_SHOPPING = "/mon-panier";
export const PRODUCT_DETAILS = "/produit/details/:title";
export const CHECKOUT = "/payement";
export const PROMO_PAGE = "/nos-offres";
export const MESSAGE = "/messages";
export const ORDER = "/commandes";
export const WHISHLIST = "/mes-favoris";
export const FACTURE = "/facture";

export const SHOP_HOME = "/:name/:id";
export const SHOP_PRODUCT = "/:name/:id/produits";
export const SHOP_PROMO = "/:name/:id/produits/nos-offres";
export const CONTACTS = "/:name/:id/contact";
export const SEARCH_PRODUCT_PAGE_STORE = "/:name/:id/produits-rechercher/produit/?";
export const CONDITION_GENERAL = "/conditions-generales-utilisation";
export const POLITIC_REMBOURSEMENT = "/politiques-de-remboursement";
export const POLITIC_CONFIDENTIALITe = "/politiques-de-confidentialite";

export const DASHBOARD_SELLER_HOME= "/Tableau-de-bord/:name_store";
export const DASHBOARD_SELLER_PROFIL= "/Tableau-de-bord/:name_store/profile";
export const DASHBOARD_SELLER_PRODUCTS= "/Tableau-de-bord/:name_store/produits";
export const DASHBOARD_SELLER_ADD_PRODUCTS= "/Tableau-de-bord/:name_store/nouveau-produit";
export const DASHBOARD_SELLER_COBEILLE_PRODUCT= "/Tableau-de-bord/:name_store/produits/cobeille";
export const DASHBOARD_SELLER_COBEILLE_VENTE= "/Tableau-de-bord/:name_store/ventes/cobeille";
export const DASHBOARD_SELLER_SALLE= "/Tableau-de-bord/:name_store/ventes";
export const DASHBOARD_SELLER_ORDER= "/Tableau-de-bord/:name_store/commandes";
export const DASHBOARD_SELLER_PAYEMENT= "/Tableau-de-bord/:name_store/parametre-payement";
export const DASHBOARD_SELLER_MESSAGE= "/Tableau-de-bord/:name_store/messages";
export const DASHBOARD_SELLER_SUBSCRIBE = "/Tableau-de-bord/:name_store/abonnement";

export const CART_LINK_PAGE = "/:name/:id/payement=panier?";

export const START = "/start-store/:token";

// export const API_URL = "https://192.168.0.122:4007/";
export const API_URL = "https://api.market.feexpay.me/";
//export const API_URL = "https://env-market.jcloud-ver-jpe.ik-server.com/";
// export const API_URL2 = "http://192.168.0.122:4007";
export const API_URL2 = "https://api.market.feexpay.me";

/* APi url payment */
export const API_URL_PAYMENT = "https://api.feexpay.me/api/transactions/requesttopay/market/";
/* API URL status pay */
export const API_URL_PAYMENT_STATUS = "https://api.feexpay.me/api/transactions/status/market/";

/* URLs sociaux */
export const URL_FACEBOOk = "https://www.facebook.com/feexpay";
export const URL_LINKEDIN = "https://www.linkedin.com/company/feexpay/";
export const URL_TWITTER = "https://twitter.com/FeexPay?t=-r22gbo9UugWXqiCrR3Q_A&s=09";
export const URL_YOUTUBE = "https://www.youtube.com/channel/UCoJoj2BomXz49BSbihOCkTg";
 

export const config = {
  headers: {
    "Content-Type": "application/json",
  },
};
