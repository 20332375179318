import React, { useContext, useEffect, useState } from "react";
import { validInputText } from "../../controlFields/ControlField";
import { countries } from "../../countrylist/countrylist";
import SelectCountryField from "../../selectCountryField/SelectCountryField";
import { ShowPasswdText } from "../../showPasswdText/ShowPasswdText";
import AuthContext from "../../../context/AuthContext";

import "../auth.scss";
import GeneralContext from "../../../context/GlobalContext";

const Register = () => {
  const { registerUser } = useContext(AuthContext);
  const { location } = useContext(GeneralContext);

  const [lose, isLose] = useState(false);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordC, setPasswordC] = useState("");

  const [countryName, setCountryName] = useState(
    location ? location.country_name : "choisissez votre pays actuel"
  );
  const [dialCode, setDialCode] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
    case "firstname":
      setFirstname(value);
      break;
    case "lastname":
      setLastname(value);
      break;
    case "telephone":
      setTelephone(value);
      break;
    case "email":
      setEmail(value);
      break;
    case "password":
      setPassword(value);
      break;
    case "passwordC":
      setPasswordC(value);
      break;
    default:
      break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    validInputText(firstname, "firstname-error", "text");
    validInputText(lastname, "lastname-error", "text") ;
    validInputText(telephone, "tel-error", "tel") ;
    validInputText(email, "email-error", "email") ;
    validInputText(password, "pwd-error", "pwd");

    if (
      validInputText(firstname, "firstname-error", "text") &&
      validInputText(lastname, "lastname-error", "text") &&
      validInputText(telephone, "tel-error", "tel") &&
      validInputText(email, "email-error", "email") &&
      validInputText(password, "pwd-error", "pwd")
    ) {
      if (passwordC === password) {
        const country = countryName;
        const phone = dialCode + telephone;
        let role = "buyer";

        registerUser(
          firstname,
          lastname,
          country,
          phone,
          email,
          password,
          "/",
          isLose,
          role
        );
      } else {
        const pwdC = document.getElementById("pwdC-error");
        pwdC.innerText = "Mot de passe incorrect";
      }
    }
  };

  useEffect(() => {
    let cd = countries.find((item) => item.name === countryName);
    if (cd) {
      setDialCode(cd.dial_code);
    }

    console.log("ici  " + countryName);
  }, [countryName, ]);

  return (
    <>
      {lose ? <p className="msg-text">Cet email est déjà utilisé </p>:null}
      <div className="div-field">
        <label> Nom complet</label>
        <div className="groups-field">
          <p>
            <input
              type="text"
              placeholder="Entrer votre nom..."
              name="firstname"
              value={firstname}
              onChange={handleChange}
              onKeyUp={() =>
                validInputText(firstname, "firstname-error", "text")
              }
            />
            <span className="span" id="firstname-error"></span>
          </p>
          <p>
            <input
              type="text"
              placeholder="Entrer votre prénom..."
              name="lastname"
              value={lastname}
              onChange={handleChange}
              onKeyUp={() =>
                validInputText(lastname, "lastname-error", "text")
              }
            />
            <span className="span" id="lastname-error"></span>
          </p>
        </div>
      </div>
      <div className="div-field">
        <label>Pays/Région</label>
        <SelectCountryField
          country_name={countryName}
          setCountry={setCountryName}
        />
      </div>
      <div className="div-field">
        <label>Téléphone</label>
        <span className="span" id="tel-error"></span>
        <div className="phones">
          <span> {dialCode} </span>
          <input
            type="tel"
            placeholder="Votre numéro service client whatsapp..."
            name="telephone"
            value={telephone}
            onChange={handleChange}
            onKeyUp={() => validInputText(telephone, "tel-error", "tel")}
          />
        </div>
      </div>
      <div className="div-field">
        <label>Email</label>
        <input
          type="email"
          placeholder="votre email..."
          name="email"
          value={email}
          onChange={handleChange}
          onKeyUp={() => validInputText(email, "email-error", "email")}
        />
        <span className="span" id="email-error"></span>
      </div>
      <div className="div-field">
        <label>Mot de passe</label>
        <div className="groups-field">
          <p>
            <input
              type="password"
              placeholder="Créer votre mot de passe..."
              name="password"
              id="password"
              value={password}
              onChange={handleChange}
              onKeyUp={() => validInputText(password, "pwd-error", "pwd")}
            />
            <ShowPasswdText id="password" />
            <span className="span" id="pwd-error"></span>
          </p>
          <p>
            <input
              type="password"
              placeholder="Confirmer votre mot de passe..."
              name="passwordC"
              value={passwordC}
              onChange={handleChange}
              onKeyUp={() => validInputText(firstname, "pwdC-error", "pwd")}
            />
            <span className="span" id="pwdC-error"></span>
          </p>
        </div>
      </div>
      <div className="btn-submit">
        <button onClick={handleSubmit} type="submit">
          Soumettre
        </button>
      </div>
      <p className="text-foot">
        En appuyant sur Soumettre, vous acceptez notre
        <a href="">&nbsp;Conditions d'utilisation</a> et notre
        <a href="">&nbsp;Politique de confidentialité.</a>
      </p>
    </>
  );
};

export default Register;
