import React from "react";
import Footer from "../Footer";

import "../Footer.scss";

const FooterCartCheckout = () => {
  return (
    <>
      <Footer children1={null}
        children2={null}
      />
    </>
  );
};

export default FooterCartCheckout;
