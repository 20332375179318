import React, { useState} from "react";

import { FaSearch, FaSistrix} from "react-icons/fa";
import "./SearchBar.scss";

const SearchBar = ({placeholderText, setWord}) => {
  const [keyword, setKeyword] = useState("");

  const handleChange = (e) => {
    setKeyword(e.target.value);
  };

  const key = () => {
    if (keyword.length > 0) {
      setWord(keyword);
    }
  };


  return (
    <>
      <div className="searchbar">
        <div className="bar">
          <FaSistrix />
          <input type="text" name="keyword"
            placeholder={placeholderText}
            value={keyword}
            onChange={handleChange}
            onKeyUp={key} />
        </div>
      </div>
    </>
  );
};

export default SearchBar;
