import {createSlice} from "@reduxjs/toolkit";

import cat1 from "../../assets/images/categories/cat1.png";
import cat2 from "../../assets/images/categories/cat2.png";
import cat3 from "../../assets/images/categories/cat3.png";
import cat4 from "../../assets/images/categories/cat4.png";
import cat5 from "../../assets/images/categories/cat5.png";
import {getProduct} from "./productAction";

const categories2 = [
  {
    nameCat: "Vêtements",
    subCategorie: [
      {
        subCat: "Vêtements pour Femme",
        items: [
          {item: "Robes de nuits"},
          {item: "Robes de soiré"},
          {item: "Robes de sortie"},
          {item: "Autres Robes "},
          {item: "Jeans"},
          {item: "Chemisier"},
          {item: "Robes de nuits"},
          {item: "T-shirts"},
          {item: "Pantalon"},
          {item: "Veste"},
          {item: "Shorts"},
          {item: "Jogging"},
          {item: "Sous-vêtements"},
          {item: "Robes de nuits"},
          {item: "Pull"},
        ],
      },
      {
        subCat: "Vêtements pour Homme",
        items: [
          {item: "Jeans"},
          {item: "Chemisier"},
          {item: "T-shirts"},
          {item: "Pantalon"},
          {item: "Veste"},
          {item: "Shorts"},
          {item: "Jogging"},
          {item: "Sous-vêtements"},
          {item: "Pull"},
          {item: "Pull"},
        ],
      },
      {subCat: "Vêtements pour Enfant"},
      {subCat: "Meanteau"},
      {subCat: "Vêtements ethniques"},
      {subCat: "Jeans"},
      {subCat: "Veste"},
      {subCat: "Chemise"},
    ],
  },
  {
    nameCat: "Beauté et soins",
  },
  {
    nameCat: "Informatiques et logicièls",
  },
  {
    nameCat: "Construction et immobilier",
  },
  {
    nameCat: "Beauté et soins",
  },
  {
    nameCat: "Informatiques et logicièls",
  },
  {
    nameCat: "Construction et immobilier",
  },

];
export const categories = [
  {
    img: cat1,
    nameCat: "Vêtements Femme",
    items: [
      {type: "Robe de sortie"},
      {type: "Robe de nuit"},
      {type: "Robe"},
    ],
  },
  {
    img: cat2,
    nameCat: "Vêtements Homme",
    items: [
      {type: "Chemise"},
      {type: "Pantalon"},
      {type: "Pantalons"},
    ],
  },
  {
    img: cat3,
    nameCat: "Informatique et Bureautique",
  },
  {
    img: cat4,
    nameCat: "Téléphone et Télécommunication",
  },
  {
    img: cat5,
    nameCat: "Electronique grand public",
  },
  {
    img: cat1,
    nameCat: "Sacs & Chaussures",
  },
  {
    img: cat2,
    nameCat: "Sports & Loisirs",
  },
  {
    img: cat3,
    nameCat: "Accessoires Cuisine",
  },
  {
    img: cat1,
    nameCat: "Maison & Jardin",
  },
  {
    img: cat4,
    nameCat: "Santé, Beauté & Cheveux",
  },
  {
    img: cat1,
    nameCat: "Automobiles & Moto",
  },
  {
    img: cat3,
    nameCat: "Jouets, Enfant & Bébé",
  },
];

const initialState = {
  loading: false,
  info: null,
  error: null,
  productInfo: [],
  categorie: categories2,
};
const productSlice = createSlice({
  name: "product",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    /* product */
    [getProduct.pending]: (state) => {
      state.loading= true;
      state.error= null;
    },
    [getProduct.fulfilled]: (state, {payload}) => {
      state.loading = false;
      state.productInfo = payload;
      state.error = null;
    },
    [getProduct.rejected]: (state, {payload}) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const productReducer = productSlice.reducer;
