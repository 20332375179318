import React, { useEffect } from "react";
import { FiChevronRight } from "react-icons/fi";
import "./CardProdShop2.scss";

import { API_URL, API_URL2 } from "../../settings/constant";
import { Link } from "react-router-dom";
import { Spinner2 } from "../spinner2/Spinner2";

export const CardProdShop2 = ({ title, listProduct }) => {

  return (
    <section className="section-shop section">
      {listProduct.length > 0 ? (
        <>
          <h2 className="title-section">
            {title}
          </h2>
          <div className="groups-product-card">
            <div className="cards">
              {listProduct.length != 0 && (
                listProduct.map((list, index) => {
                  if (index < 6) {
                    return (
                      <div className="card-prod" key={index}>
                        <div className="div-imgs">
                          <Link key={index} to={{ pathname: `/produit/details/${list.Category.nameCat.replaceAll(" ","-")}` }} state={list}>
                            <img src={`${API_URL}${list.image_prod}`} />
                          </Link>
                        </div>
                        <div className="infos">
                          <h4> {list.name_prod} </h4>
                          {/* <b> XOF {list.price} </b> */}
                        </div>
                      </div>
                    );
                  }
                })
              )}
            </div>
          </div>
        </>
      ) : <Spinner2 />
      }
    </section>
  );
};
