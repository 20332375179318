import React, { useContext, useEffect, useRef, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { FiEdit3, FiInfo, FiTrash2 } from "react-icons/fi";
import { HeadTabs } from "../../../../components/headTabs/HeadTabs";
import Pagination from "../../../../components/pagination2/Pagination";
import SearchBar from "../../../../components/searchBar2/SearchBar";
import { API_URL, API_URL2, } from "../../../../settings/constant";
import { useReactToPrint } from "react-to-print";
import { WhatsappShareButton, WhatsappIcon } from "react-share";


import "./products.scss";
import GeneralContext from "../../../../context/GlobalContext";
import { PopupLayout } from "../../../../components/popupLayout/PopupLayout";
import { BoxAlertSucess } from "../../../../components/boxAlertSucess/BoxAlertSucess";
import { addItem, decrementQty, incrementQty, removeItem } from "../../../../store/cart/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import CopyToClipboard from "react-copy-to-clipboard";

const ListProduct = () => {
  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart.cartItem);

  const [popup, isPopup] = useState(false);
  const [popup2, isPopup2] = useState(false);
  const [popup3, isPopup3] = useState(false);
  const [popup4, isPopup4] = useState(false);
  const [popup5, isPopup5] = useState(false);
  const [showDiv, isShowDiv] = useState(false);

  const [searchWord, setSearchWord] = useState("");
  const [startNbPage, setStartNbPage] = useState(4);
  const [nextPage, setNextPage] = useState(startNbPage);
  const [previouspage, setPreviousPage] = useState(0);
  const [resultSearch, setResultSearch] = useState([]);
  const [storeActive, setStoreActive] = useState([]);
  const [storeName, setStoreName] = useState(null);
  const [storeId, setStoreId] = useState(null);
  const [linkCart, setLinkCart] = useState(null);

  const [reducePercent, setReducePercent] = useState();
  const [nameProd, setNameProd] = useState("");
  const [idProd, setIdProd] = useState("");

  const [done, isDone] = useState(false);
  const [deletes, isDeletes] = useState(false);

  const [msgPublish, setMsgPublish] = useState(null);

  const [cartLink, setCartLink] = useState([]);

  const { productStore, getProductToStore,
    store, updateReduce, deleteProduct, publishProduct,
    add_cart_store_item } = useContext(GeneralContext);


  /* return store active */
  const returnStore = () => {
    if (store.length > 0) {
      let find = store.find(
        (item) => item.is_active === true
      );

      if (find) {
        setStoreActive(find);
        setStoreId(find.store_id);
        setStoreName(find.name_store.replaceAll(" ", ""));
        getProductToStore(find.store_id);
      }
    }
  };

  /* search product */
  const search = async () => {
    if (searchWord) {
      const tab = productStore.filter(
        (item) => item.name_prod.toLowerCase().includes(searchWord.toLowerCase()) ||
          item.category.nameCat.toLowerCase().includes(searchWord),
      );

      if (tab.length != 0) {
        console.log(tab);
        setResultSearch(tab);
      } else {
        setResultSearch([]);
      }
    }
  };

  /* update product */
  const update_product = (product) => {
    sessionStorage.setItem("product", JSON.stringify(product));
    window.location.href = `/Tableau-de-bord/${storeName}/nouveau-produit`;
  };

  /* handle update product of reduce percent */
  const handleReduceUpdate = () => {
    updateReduce(idProd, reducePercent, isDone, setMsgPublish);
  };

  /* delete product */
  const handleDelete = (id_prod) => {
    deleteProduct(id_prod, isDone, setMsgPublish, isPopup2);
  };

  /* set reduction of promotion */
  const setReduce = (name_prod, prod_id) => {
    isPopup(true);
    setNameProd(name_prod);
    setIdProd(prod_id);
  };

  /* publich product */
  const handlePublishProduct = (id_prod, store_id) => {
    publishProduct(id_prod, store_id, setMsgPublish, isPopup2, isDone);
  };

  const addProductToCartLink = (id, product) => {
    let ids = document.getElementById(id);
    if (ids.checked) {
      handleAddToCart(product.id_prod, product.image_prod, product.name_prod, product.desc_prod,
        product.price_prod, product.reduces, 1);
    } else {
      let prod = product.id_prod;
      dispatch(removeItem({ prod }));
    }
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  /* add cart link generate */
  const handleAddToCart = (
    id_prod, image_prod, name_prod, desc_prod, price_prod, reduces, quantity, size, color, store_id, name_store
  ) => {
    dispatch(addItem({
      id_prod, image_prod, name_prod, desc_prod, price_prod, reduces, quantity, size, color, store_id, name_store
    }));
  };

  // action pour ouvrir le popup de generation de lien de paiement
  const GenerateLinkBtn = () => {
    if (cart.length > 0) {
      isPopup4(true);
    } else {
      alert("Sélectionner un ou plusieurs produits puis cliquer de nouveau sur le bouton");
    }
  };

  /* generate link cart product store */
  const generateLinkCartStore = () => {
    let id = sessionStorage.getItem("cartStoreId");

    if (id && id != 0) {
      let origin = window.location.origin;
      isShowDiv(true);
      setLinkCart(`${origin}/${storeName}/${storeId}/payement=panier?${id}`);
    } else {
      isShowDiv(false);
      setLinkCart(null);
      alert("Créer d'abord le panier");
    }
  };

  /* get link web site store */
  const getLinkStore = () => {
    let link = "";
    if (store.length > 0) {
      for (var i = 0; i < store.length; i++) {
        if (store[i].is_active === true) {
          link = `/${store[i].name_store.replaceAll(" ", "")}/${store[i].store_id}/produits`;
        }
      }
    }
    return link;
  };

  /* render table column data */
  const renderColumnData = (tableData) => {
    return (
      tableData.map((list, index) => {
        if (previouspage <= index && (nextPage - 1) >= index) {
          return <tr key={index}>
            <td> <input type="checkbox" name="" id={`p${list.id_prod}`} onClick={() => addProductToCartLink(`p${list.id_prod}`, list)} /> </td>
            <td> Prod{index + 1} </td>
            <td> <img src={`${API_URL}${list.image_prod}`} /> </td>
            <td> {list.name_prod} </td>
            <td> {list.Category !== null ? list.Category.nameCat : null} </td>
            <td> {Math.round(list.price_prod)} </td>
            <td> {list.reduces} </td>
            <td>
              <button> <a href={getLinkStore()} target='_blank' rel="noreferrer">Voir</a> </button>
              <button onClick={() => setReduce(list.name_prod, list.id_prod)}> <FiEdit3 /> Promouvoir </button>
              {list.is_publish ?
                <button onClick={() => handlePublishProduct(list.id_prod, list.Store.store_id)}> <FiEdit3 /> Ne pas Publier </button> :
                <button onClick={() => handlePublishProduct(list.id_prod, list.Store.store_id)}> <FiEdit3 /> Publier </button>
              }
              <button onClick={() => update_product(list)}> <FiEdit3 /> Modifier </button>
              <button onClick={() => handleDelete(list.id_prod)}> <FiTrash2 /> Supprimer </button>
            </td>
          </tr>;
        }
      })
    );
  };


  useEffect(() => {
    returnStore();
    search();
  }, [store, searchWord, cartLink]);


  return (
    <div className="product-dashboard-buyer">
      <HeadTabs title={"Produits"} />
      <PopupLayout closes={popup5}>
        <div className="box-cart-msg">
          <span className="close">&times;</span>
          <p> Panier créer avec succès ! Générer le lien pour accédé au panier. </p>
          <button>ok</button>
        </div>
      </PopupLayout>
      <PopupLayout closes={popup4}>
        <div className='cart-link'>
          <span className='close'
            onClick={() => isPopup4(false)}>&times;</span>
          <div className='group-btns-cart'>
            <button className='btn-generate-link'
              onClick={() => add_cart_store_item(storeActive ? storeActive.store_id : null, isPopup5)}>
              Créer le panier
            </button>
            <button onClick={generateLinkCartStore}> Générer le lien pour le panier</button>
          </div>
          <div className='link-cart' style={showDiv ? { display: "block" } : { display: "none" }}>
            <h2 className='link-payment-cart' id='link'> {linkCart} </h2>
            <WhatsappShareButton
              url={linkCart}>
              <button> Envoyer le lien par whatsapp </button>
            </WhatsappShareButton>
            <CopyToClipboard text={linkCart ? linkCart : null}
              onCopy={() => alert("Lient copié avec succès.")}>
              <button className='copy'>copier le lien</button>
            </CopyToClipboard>
          </div>

          <>
            <table className="table-data2" ref={componentRef}>
              <thead>
                <tr>
                  <th> Nom produit </th>
                  <th> Photo Image </th>
                  <th> Prix Unitaire </th>
                  <th> Quantité </th>
                  <th> Reduction </th>
                  <th> Action </th>
                </tr>
              </thead>
              {
                cart.length != 0 ? (
                  <tbody>
                    {
                      cart && (
                        cart.map(({ id_prod, name_prod, image_prod, reduces, price_prod, quantity }, index) => {
                          return <tr key={index}>
                            <td> {name_prod} </td>
                            <td> <img src={`${API_URL}${image_prod}`} /> </td>
                            <td> {price_prod} </td>
                            <td>
                              <div className="quantity-x">
                                <button onClick={() => dispatch(decrementQty({ id_prod }))}>
                                  <AiOutlineMinus /> </button>
                                <input type="text" value={quantity} />
                                <button onClick={() => dispatch(incrementQty({ id_prod }))}>
                                  <AiOutlinePlus />
                                </button>
                              </div>
                            </td>
                            <td> {reduces} </td>
                            <td>
                              <button onClick={() => dispatch(removeItem({ id_prod }))}> <FiTrash2 /> Supprimer </button>
                            </td>
                          </tr>;
                        })
                      )
                    }
                  </tbody>
                ) : null
              }
            </table>
          </>
        </div>
      </PopupLayout>
      <PopupLayout closes={popup}>
        <div className='block-form-promo'>
          <span className='close-x' onClick={() => {
            isDone(false);
            isPopup(false);
            setReducePercent("");
            window.location.reload();
          }}>&times;</span>
          {
            !done ? (
              <>
                <p className='text'>
                  Pomouvoir un produit revient à mettre le
                  produit en promotion afin d'attirer plus de visiteurs
                </p>
                <div className='box'>
                  <p>
                    Nom Produit: <b> {nameProd} </b>
                  </p>
                  <p>
                    Réduction:
                    <input type="text" name='reduce'
                      placeholder='Pourcentage de réduction'
                      value={reducePercent}
                      onChange={e => setReducePercent(e.target.value)}
                    /> %
                  </p>
                  <button onClick={handleReduceUpdate}> Appliquer </button>
                </div>
              </>
            ) : (
              <>
                <BoxAlertSucess popup={popup}
                  text="Réduction Appliquée avec succès !"
                  handleBtn={isPopup} isDone={isDone} done={done}
                  link={storeActive ? `/Tableau-de-bord/${storeName}/produits` : "#"} />
              </>
            )
          }
        </div>
      </PopupLayout>
      <BoxAlertSucess popup={popup2}
        text={msgPublish}
        handleBtn={isPopup2} isDone={isDone} done={done}
        link={"#"} />
      {/* <BoxAlertSucess popup={popup2}
        text={msgPublish}
        handleBtn={isPopup2}
        isDone={isDone}
        link={"#"} />
      <BoxAlertSucess popup={popup5}
        text={"Panier créer avec succès. Envoyer le lien à vos client."}
        handleBtn={isPopup5}
        isDone={isPopup5}
        link={"#"} /> */}
      <div className="product-box parent">
        <div className="btn-add-product">
          {productStore.length == 0 ? <p> Aucun produits disponible. </p> : null}
          <button
            onClick={() => window.location.href = `/Tableau-de-bord/${storeName}/nouveau-produit`}> <FaPlus /> Nouveau produit </button>
        </div>
        <div className="list-product child">
          <button className='btn-generate'
            onClick={GenerateLinkBtn} >Générer un lien de Paiement</button>

          <>
            <SearchBar placeholderText="Rechercher par nom Product et categorie"
              searchWord={searchWord} allList={productStore}
              setList={setResultSearch}
              setWord={setSearchWord}
              handlePrint={handlePrint} />
            <table className="table-data" ref={componentRef}>
              <thead>
                <tr>
                  <th></th>
                  <th> ID </th>
                  <th> Image </th>
                  <th> Nom du Produit </th>
                  <th> Catégorie </th>
                  <th> Prix unité (FCFA) </th>
                  <th> Réduction </th>
                  <th> Actions </th>
                </tr>
              </thead>
              <tbody>
                {
                  productStore.length > 0 ? (
                    resultSearch.length == 0 ? (
                      productStore.length !== 0 && (
                        renderColumnData(productStore)
                      )
                    ) : (
                      renderColumnData(resultSearch)
                    )
                  ) : null
                }
              </tbody>
            </table>
            {resultSearch.length == 0 ? (
              <Pagination nb={startNbPage}
                countTab={productStore.length}
                setNextt={setNextPage}
                setPrevious={setPreviousPage}
                nextt={nextPage}
                previous={previouspage} />
            ) : null}
          </>

        </div>
      </div>
    </div>
  );
};

export default ListProduct;
