import React, { useContext, useEffect } from "react";
import { API_URL, config } from "../../settings/constant";
import axios from "axios";
import "./Toggle.scss";
import GeneralContext from "../../context/GlobalContext";

const Toggle = ({ label, value, setValue, active, field, funct }) => {

  const { store } = useContext(GeneralContext);

  const setVal = async () => {
    const input = document.getElementById(value);

    if (store.length > 0) {
      for (var i = 0; i < store.length; i++) {
        if (store[i].is_active == true) {
          try {
            if (input.checked == true) {
              setValue(value);
              const response = await axios.put(`${API_URL}store/update-mp/${store[i].store_id}`, {
                mp: field,
                state: "true"
              }, config);

              if (response.status == 200) {
                funct();
              } else {
                alert("Vérifier votre connexion");
              }
              console.log(response);
            } else {
              setValue(null);
              const response = await axios.put(`${API_URL}store/update-mp/${store[i].store_id}`, {
                mp: field,
                state: "false"
              }, config);
              if (response.status == 200) {
                funct();
              } else {
                alert("Vérifier votre connexion");
              }
            }
          } catch (error) {
            return error;
          }
          return 0;
        }
      }

    }
  };


  useEffect(() => {
    const input = document.getElementById(value);
    if (active) {
      input.checked = true;
    } else {
      input.checked = false;
    }

    console.log(active);
  }, [store, active]);

  return (
    <div className="box-checkboxs" >
      <label> {label} </label>
      <div className="box-child" onClick={setVal}>
        <input type="checkbox" name={value} id={value} />
        <div className="slider round" ></div>
      </div>
    </div>
  );
};

export default Toggle;
