import React, { useContext, useEffect, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { VscListSelection } from "react-icons/vsc";
import { Link } from "react-router-dom";


import GeneralContext from "../../context/GlobalContext";
import "./DropCategorie.scss";

const DropCategorie = () => {

  const { getCategoryProduct, categoryProduct  } = useContext(GeneralContext);

  useEffect(() => {
    /* get category products */
    getCategoryProduct();
  }, []);

  
  return (
    <div className="li-drop-cat">
      <VscListSelection />
      Catégories <FiChevronDown />
      <div className="box-categorie">
        {
          categoryProduct.length > 0 &&
          (categoryProduct.map((list, index) => {
            return <li key={index}>
              <Link to={{ pathname: "/categories/produits", search:`categorie=${list.nameCat.replaceAll(" ","-")}` }} state={list}>
                {list.nameCat}
              </Link>
            </li>;
          }))
        }
      </div>
    </div>
  );
};

export default DropCategorie;
