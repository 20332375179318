import React, { useContext, useEffect, useState } from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";

import "./HeadTabs.scss";

import GeneralContext from "../../context/GlobalContext";
import { PopupLayout } from "../popupLayout/PopupLayout";


export const HeadTabs = ({ title }) => {
  const { userData, store } = useContext(GeneralContext);

  const [popup, isPopup] = useState(false);
  const [storeActive, setStoreActive] = useState("");

  /* get store active */
  const getStoreActive = () => {
    if (store) {
      let find = store.find(
        (item) => item.is_active == true,
      );
      if (find) {
        setStoreActive(find);
      }
    }
  };

  const returnLinkWebSite = () => {
    if (storeActive) {
      let origin = window.location.origin;
      let name = storeActive.name_store.replaceAll(" ", "");
      return `${origin}/${name}/${storeActive.store_id}/produits`;
    }
  };


  useEffect(() => {
    getStoreActive();
  }, [store]);

  return (
    <h2 className="title-tabs">
      <PopupLayout closes={popup}>
        <div className='card-share'>
          <span className='close' onClick={() => isPopup(false)}>&times;</span>
          <p>
            Partagez le lien de votre boutique e-commerce à vos amis
            sur whatsapp, Facebook...
          </p>
          <div className='socio-share'>
            <FacebookShareButton
              url={returnLinkWebSite()}>
              <FacebookIcon size={35} round />
            </FacebookShareButton>
            <WhatsappShareButton
              url={returnLinkWebSite()}>
              <WhatsappIcon size={35} round />
            </WhatsappShareButton>
            <CopyToClipboard text={returnLinkWebSite()}
              onCopy={() => alert("Text copié")}>
              <button className='copy-link'>Copier le lien</button>
            </CopyToClipboard>
          </div>
        </div>
      </PopupLayout>
      <p>
        <b> {title} </b>
      </p>
      <div className='groups-btn'>
        <button className='btn-share-link'
          onClick={() => isPopup(true)}>Partager le lien de ma boutique</button>
      </div>
    </h2>
  );
};
