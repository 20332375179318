import React, { useEffect, useState } from "react";
import { FaFacebookF, FaLinkedinIn, FaTwitter, FaYoutube } from "react-icons/fa";
import { FiUser } from "react-icons/fi";
import { HOME, SHOP_HOME, URL_FACEBOOk, URL_LINKEDIN, URL_TWITTER, URL_YOUTUBE } from "../../../settings/constant";
import Auth from "../../authForms/Auth";
import BoxAccount from "../../boxAccount/BoxAccount";

import "flag-icon-css/css/flag-icons.css";
import "../Header.scss";
import SelectCountryCurrency from "../../selectCountryCurrency/SelectCountryCurrency";

export const TopBar = () => {
  const [part, isPart] = useState(false);
  const [openAuth, setOpenAuth] = useState(false);
  const [openForm, setOpenForm] = useState(0);

  const openForms = (form, auth) => {
    setOpenForm(form);
    setOpenAuth(auth);
  };

  useEffect(() => {
    const path = window.location.pathname.split("/")[1];
    if (path == SHOP_HOME.split("/")[1]) {
      isPart(true);
    }
  });

  const renders = () => {
    if (!part) {
      return (
        <>
          <li><a href={HOME}>Accueil</a></li>
          <li><a href="">Comment créer mon boutique ?</a></li>
        </>
      );
    }
  };
  return (
    <>
      <div className="head-div1">
        {openAuth ? <Auth formAuth={openForm} openAuth={setOpenAuth} /> : ""}
        <div className="common-div">
          <ul>
            {renders()}
          </ul>
          <ul>
            {/* <SelectCountryCurrency /> */}
            <div className='separator'></div>
            <li className="user-account">
              <a> <FiUser /> Mon&nbsp;compte</a>
              <BoxAccount openAuth={openForms} />
            </li>
            <div className='separator'></div>
            <li className='hide-phone'>
              <a href={URL_FACEBOOk} target="_blank" rel="noreferrer"><FaFacebookF /></a>
            </li>
            <div className='separator'></div>
            <li className='hide-phone'>
              <a href={URL_LINKEDIN} target="_blank" rel="noreferrer"> <FaLinkedinIn /> </a>
            </li>
            <div className='separator'></div>
            <li className='hide-phone'>
              <a href={URL_TWITTER} target="_blank" rel="noreferrer"> <FaTwitter /> </a>
            </li>
            <div className='separator'></div>
            <li className='hide-phone'>
              <a href={URL_YOUTUBE} target="_blank" rel="noreferrer"> <FaYoutube />  </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
