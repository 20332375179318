import React, { useContext, useEffect, useState } from "react";
import { FaLayerGroup, FaRegEnvelope } from "react-icons/fa";
import { FiHeart, FiShoppingCart, FiUser } from "react-icons/fi";
import { useSelector } from "react-redux";

import "../Header.scss";

import AuthContext from "../../../context/AuthContext";
import GeneralContext from "../../../context/GlobalContext";
import { CART_SHOPPING, MESSAGE, ORDER, SHOP_HOME, WHISHLIST } from "../../../settings/constant";
import Auth from "../../authForms/Auth";
import BoxAccount from "../../boxAccount/BoxAccount";
import Cart from "../../cart/Cart";


export const RightBtns = () => {
  const [part, isPart] = useState(false);
  const [openAuth, setOpenAuth] = useState(false);
  const [openForm, setOpenForm] = useState(0);

  const openForms = (form, auth) => {
    setOpenForm(form);
    setOpenAuth(auth);
  };

  const [link, setLink] = useState(null);

  const { whishlist, store, update_status_store, 
    orders, shoppingCartItem } = useContext(GeneralContext);

  /* open store */
  const openStore = () => {
    if (store.length > 0) {
      update_status_store(store[0].store_id, setLink);
    }
  };

  const renders = () => {
    if (!part) {
      return (
        <>
          {store.length != 0 ? (
            <li className="shop"><a href={link} target='_blank' rel="noreferrer"> Ma boutique</a></li>
          ) : null}
        </>
      );
    }
  };

  useEffect(() => {
    const path = window.location.pathname.split("/")[1];
    if (path == SHOP_HOME.split("/")[1]) {
      isPart(true);
    }

    /* fonction for open store  */
    openStore();
  }, [store, orders, shoppingCartItem, link]);

  return (
    <>
      {openAuth ? <Auth formAuth={openForm} openAuth={setOpenAuth} /> : ""}
      <div className="div-rights">
        {renders()}
        <div className="btn-user">
          <FiUser className="svg-user" />
          <span className="name"> Mon Compte </span>
          <BoxAccount openAuth={openForms} />
        </div>
        <a href={WHISHLIST} className='a-btn'>
          <span className="nb-product"> {whishlist.length > 0 ? whishlist.length : 0} </span>
          <FiHeart />
          <span className="name"> Favorite</span>
        </a>
        <a href={ORDER} className='a-btn'>
          <span className="nb-product"> {orders.length > 0 ? orders.length : 0} </span>
          <FaLayerGroup />
          <span className="name"> Commandes </span>
        </a>
        <div className="cart-divs">
          <button className='go-cart' onClick={() => window.location.href = CART_SHOPPING}>
            <span className="nb-product"> {shoppingCartItem.length > 0 ? shoppingCartItem.length : 0} </span>
            <FiShoppingCart />
            <span className="name">Mon&nbsp;Panier</span>
          </button>
          <div className="card-cart">
            <Cart para={"Géré votre panier"} shoppingCartItem={shoppingCartItem} />
          </div>
        </div>
      </div>
    </>
  );
};
