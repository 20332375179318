import React, { useContext, useEffect, useState } from "react";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";
import { Link } from "react-router-dom";

import GeneralContext from "../../context/GlobalContext";

import "./BoxCategorie.scss";

const BoxCategorie = () => {

  const { categoryProduct } = useContext(GeneralContext);

  // action show subcategories
  const showSublink = (index, nameCat, array) => {
    sessionStorage.removeItem("nameSubCat");
    sessionStorage.removeItem("nameProd");
    sessionStorage.setItem("nameCat", nameCat);
    sessionStorage.setItem("indexCat", index);

    const all = document.querySelectorAll(".sublink-div");
    const span = document.querySelectorAll(".span");

    if (array.length > 0) {
      if (all[index].className.split(" ").length == 2) {
        all[index].classList.remove("show");
        span[index].classList.remove("active");
      } else {
        span[index].classList.add("active");
        all[index].classList.add("show");
      }
    }
  };

  return (
    <div className="categorie-box" id="box">
      <h3> &nbsp;&nbsp;Catégories <FiChevronDown /> </h3>
      <div className="list-categorie">
        {
          categoryProduct.length > 0 && (
            categoryProduct.map((list, index) => {
              return <div key={index} className="parent-cat">
                <Link style={{ color: "var(--dark)" }}
                  to={{ pathname: "/categories/produits", search: `categorie=${list.nameCat.replaceAll(" ", "-")}` }}
                  state={list}>
                  <span
                    className="span"
                    onClick={() => showSublink(index, list.nameCat, list.SubCategories)}
                  > {list.nameCat} <i className="svg">
                      {list.SubCategories.length > 0 ? <FiChevronDown /> : <FiChevronRight />}
                    </i> </span>
                </Link>
                <div className="sublink-div">
                  {list.SubCategories.length > 0 && (
                    list.SubCategories.map((list2, index2) => {
                      return <Link className="sublinks-sub-div"
                        to={{ 
                          pathname: "/categories/produits", 
                          search: `categorie=${list.nameCat.replaceAll(" ", "-")}$sous-categorie=${list2.nameSubCat.replaceAll(" ", "-")}/${list2.idSubCat}` }}
                        state={list}
                        key={index2}>
                        {list2.nameSubCat}
                      </Link>;
                    })
                  )}
                </div>
              </div>;
            }))
        }
      </div>
    </div>
  );
};

export default BoxCategorie;
