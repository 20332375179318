import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Shop.scss";

import { useSelector } from "react-redux";
import { CardPromo } from "../../../components/cardPromo/CardPromo";
import CardProducts from "../../../components/cardProducts/CardProducts";
import { CardProdShop2 } from "../../../components/cardProdShop2/CardProdShop2";
import { CardProdShop3 } from "../../../components/cardProdShop3/CardProdShop3";
import GeneralContext from "../../../context/GlobalContext";


const Shop = () => {

  const { getProductToStore, productStore, store,
    getCategoryStore, categoryStore } = useContext(GeneralContext);
  const [nb, setNb] = useState(20);
  const [nextPage, setNextPage] = useState(nb);
  const [prevPage, setPrevPage] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    autoplaySpeed: 5000,
    slidesToScroll: 1,
    slidesToShow: 6,
    autoplay: false,
    arrows: true,
  };

  const settings2 = {
    dots: false,
    infinite: true,
    autoplaySpeed: 5000,
    slidesToScroll: 1,
    slidesToShow: 1,
    autoplay: true,
    arrows: false,
  };

  const setInfosStore = () => {
    let path = window.location.pathname.split("/");
    let id = path[path.length - 1];

    if (id) {
      getProductToStore(id);
      getCategoryStore(id);
    }
  };

  useEffect(() => {
    setInfosStore();
  }, []);


  return (
    <div className="shop-page">
      <section className='baners-slide'>
        <Slider {...settings2} >
          <div className='img1'></div>
          <div className='img2'></div>
          <div className='img3'></div>
        </Slider>
      </section>
      <CardPromo listProduct={productStore ? productStore : null} />
      <CardProdShop3 title={"Vêtement Femme"} listProduct={productStore ? productStore : null} />
      <CardProdShop3 title={"Vêtement Homme"} listProduct={productStore ? productStore : null} />
      <CardPromo listProduct={productStore ? productStore : null} />
      <CardProdShop2 listProduct={productStore ? productStore : null} />
      <section className="section4-shop section">
        <h2 className="title-section">
          <div></div>
          Spécialement pour vous
          <div></div>
        </h2>
        <div className="groups-prod-s4">
          {/*   <CardProducts listProduct={productStore} /> */}
          <CardProducts listProduct={productStore}
            nextPage={nextPage} prevPage={prevPage} />
        </div>
      </section>
    </div>
  );
};

export default Shop;
