import {createSlice} from "@reduxjs/toolkit";


const cart = createSlice({
  name: "cart",
  initialState: {
    cartItem: [],
    isSuccess: false,
  },
  reducers: {
    addItem: (state, action) => {
      const item = state.cartItem.find(
        (item) => item.id_prod === action.payload.id_prod,
      );

      if (item) {
        item.quantity++;
      } else {
        state.cartItem.push({...action.payload});
      }

      state.isSuccess=true;
    },
    incrementQty: (state, action) => {
      const item = state.cartItem.find(
        (item) => item.id_prod === action.payload.id_prod,
      );

      item.quantity++;
    },
    decrementQty: (state, action) => {
      const item = state.cartItem.find(
        (item) => item.id_prod === action.payload.id_prod,
      );

      if (item.quantity === 1) {
        item.quantity = 1;
      } else {
        item.quantity--;
      }
    },
    removeItem: (state, action) => {
      const removeItem = state.cartItem.filter((item) =>
        item.id_prod !== action.payload.id_prod);

      state.cartItem = removeItem;
    },
    clearCart: (state, action) => {
      state.cartItem=[];
    }
  },
});

export const {
  addItem,
  incrementQty,
  decrementQty,
  removeItem,
  clearCart
} = cart.actions;

export const cartReducer = cart.reducer;
