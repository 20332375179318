import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CardProduct2 from "../../../components/cardProduct2/CardProduct2";
import Pagination from "../../../components/pagination/Pagination";
import GeneralContext from "../../../context/GlobalContext";
import { API_URL } from "../../../settings/constant";

import "./PromotionPage.scss";

const PromotionPage = () => {
  const [offre, setOffre] = useState([]);

  const { allProducts } = useContext(GeneralContext);

  useEffect(() => {
    console.log(allProducts);
    const filter = allProducts.filter(
      (item) => item.reduces != 0,
    );

    if (filter.length != 0) {
      setOffre(filter);
    }
   
  }, [allProducts]);

  return (
    <div className="promo-page">
      <h2 className="title-section">
        Profiter de nos offres
      </h2>
      <div className="groups-prod-promo">
        {
          offre.length != 0 ? (
            offre.map((list, index) => {
              return <Link key={index} to={{ pathname: `/produit/details/${list.name_prod.replaceAll(" ", "-")}` }} state={list}>
                <CardProduct2 img={`${API_URL}${list.image_prod}`} names={list.name_prod}
                  reduce={list.reduces} price={list.price_prod} />
              </Link>;
            })
          ) : (
            <h3> Aucun offre disponible</h3>
          )
        }
      </div>
      <Pagination />
    </div>
  );
};

export default PromotionPage;
