import { useState, useEffect } from "react";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { API_URL } from "../settings/constant";

const useAxios = () => {
  const [token, setToken] = useState("");

  useEffect(() => {
    const storedToken = localStorage.getItem("accessToken");
    if (storedToken) {
      const decodedToken = jwtDecode(storedToken);
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        // Le token est expiré, supprimer le token
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        setToken("");
      } else {
        // Le token est valide, le définir dans l'état
        setToken(storedToken);
        axios.defaults.headers.common["Authorization"] = `Bearer ${storedToken}`;
      }
    }
  }, []);

  const axiosInstance = axios.create();

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const refreshToken = localStorage.getItem("refreshToken");
        try {
          const response = await axios.post(`${API_URL}account/refresh-token`,{
            refreshToken: refreshToken
          });
          console.log(response);
          const newToken = response.data.accesToken;
          const newRefreshToken = response.data.refreshToken;
          localStorage.setItem("accessToken", newToken);
          localStorage.setItem("refreshToken", newRefreshToken);
          setToken(newToken);
          axios.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
          originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
          return axios(originalRequest);
        } catch (error) {
          console.error("Failed to refresh token");
          // Rediriger vers la page de connexion ou afficher un message d'erreur
        }
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default useAxios;
