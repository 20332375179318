import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { HeadTabs } from "../../../../components/headTabs/HeadTabs";
import Toggle from "../../../../components/toggle/Toggle";
import GeneralContext from "../../../../context/GlobalContext";
import { API_URL, config } from "../../../../settings/constant";

import "./PayementSettings.scss";

const PayementSettings = () => {

  const {store} = useContext(GeneralContext);

  const [momo, setMomo] = useState("");
  const [moov, setMoov] = useState("");
  const [visa, setVisa] = useState("");
  const [masterCard, setMasterCard] = useState("");
  const [storeActive, setStoreActive] = useState();



  const returnStore = () => {
    if (store.length > 0) {
      for(var i=0; i<store.length; i++) {
        if(store[i].is_active == true) {
          setStoreActive(store[i]);
          return 0;
        }
      }
    }
  };

  useEffect(() => {
    returnStore();
  }, [store, storeActive]);

  return (
    <div className="payement-settings">
      <HeadTabs title={"Paiement"} />
      <div className="payement-block parent">
        <p>
          Choisissez les méthodes de paiements que vous accepté.
        </p>

        <div className="block-child">
          <h4> Moyen de paiement par réseaux GSM</h4>
          <div className="moyen">
            <Toggle label={"Mobile Money"} value="mmoney" setValue={setMomo} active={storeActive ? storeActive.mp1 : 0} field="mp1"/>
            <Toggle label={"Moov Money"} value="mvmoney" setValue={setMoov} active={storeActive ? storeActive.mp2 : 0} field="mp2" />
          </div>
        </div>
        <div className="block-child">
          <h4> Moyen de paiement par carte bancaire</h4>
          <div className="moyen">
            <Toggle label={"Visa"} value="visa" setValue={setVisa} active={storeActive ? storeActive.mp3 : 0} field="mp3" />
            <Toggle label={"Master Card"} value="mastercard" setValue={setMasterCard} active={storeActive ? storeActive.mp4 : 0} field="mp4" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayementSettings;
